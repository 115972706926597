import React, { ReactNode } from 'react'
import merge from 'deepmerge'
import { errorLink } from 'lib/apolloClient/links/error'
import { httpLoggerLink, wsLoggerLink } from 'lib/apolloClient/links/logger'
import { cleanTypeNameLink } from 'lib/apolloClient/links/utilities'
import { isEqual } from 'lodash'

import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { useAuth0 } from '@auth0/auth0-react'
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'
export let apolloClient

export default function ApolloClientProvider({ pageProps, children }: { pageProps: any; children: ReactNode }) {
  const { getAccessTokenSilently, logout } = useAuth0() // <- Retrieve the getAccessTokenSilently function

  const client = React.useMemo(() => {
    // The `ctx` (NextPageContext) will only be present on the server.
    // use it to extract auth headers (ctx.req) or similar.
    const uri = process.env.NEXT_PUBLIC_GRAPHQL_API

    const httpLink = new HttpLink({
      uri, // Server URL (must be absolute)
      credentials: 'include'
    })

    const wsLink =
      typeof window !== 'undefined'
        ? new WebSocketLink({
            uri: uri.replace('http', 'ws'),
            options: {
              reconnect: true,
              lazy: true,
              connectionParams: () => {
                return {
                  isWebSocket: true
                }
              }
            }
          })
        : null

    const retryLink = new RetryLink({
      delay: {
        initial: 300,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 5,
        retryIf: (error, _operation) => {
          console.error('retryLink', error)
          return (
            (!!error.message.match(/Failed to fetch/) || !!error.message.match(/Bad Gateway/)) &&
            !error.message.match(/PersistedQueryNotFound/)
          )
        }
      }
    })

    const authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      try {
        const token = await getAccessTokenSilently()

        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
          }
        }
      } catch (error) {
        console.error('error while getting token from auth0', error.message)
        logout()
      }
    })

    const apolloHttpLink = from([
      cleanTypeNameLink,
      httpLoggerLink,
      // createPersistedQueryLink({
      //   sha256,
      //   useGETForHashedQueries: true
      // }) /** can use now because of caching */,
      errorLink,
      // retryLink,
      authLink,
      httpLink
    ])

    const apolloWsLink = typeof window !== 'undefined' ? from([cleanTypeNameLink, wsLoggerLink, wsLink]) : null

    const splitLink =
      typeof window !== 'undefined'
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query)
              return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            },
            apolloWsLink,
            apolloHttpLink
          )
        : apolloHttpLink

    const _apolloClient = new ApolloClient({
      ssrMode: typeof window === 'undefined',
      name: 'web',
      version: '1.0',
      link: splitLink,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              leanOrdersWithStatusMeta: {
                //https://www.apollographql.com/docs/react/pagination/core-api/#merging-paginated-results
                keyArgs: [['filter', 'orderStatus']],
                merge(existing, incoming, { args: { paginationOffset = 0 } }) {
                  // Slicing is necessary because the existing data is
                  // immutable, and frozen in development.
                  const merged = existing ? existing.slice(0) : []
                  for (let i = 0; i < incoming.length; ++i) {
                    merged[paginationOffset + i] = incoming[i]
                  }
                  return merged
                }
              }
            }
          }
        }
      })
    })

    const initialState = pageProps[APOLLO_STATE_PROP_NAME]

    if (initialState) {
      const existingCache = _apolloClient.extract()

      // merge initial state and cached data together
      const data = merge(initialState, existingCache, {
        arrayMerge: (destinationArray, sourceArray) => [
          ...sourceArray,
          ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))
        ]
      })

      _apolloClient.cache.restore(data)
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient
    // Otherwise singleton
    if (!apolloClient) apolloClient = _apolloClient

    return _apolloClient
  }, [getAccessTokenSilently, pageProps])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
