import { createHash } from 'crypto'

export function stringToColorCode(stringInput = '') {
  let stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  return `hsl(${stringUniqueHash % 360}, 90%, 30%)`
}

export function sortThaiDictionary(array: string[]) {
  return array.sort((a, b) => a.localeCompare(b, 'th'))
}

export function ellipsisText(length: number, text?: string) {
  if (!text) {
    return text
  }

  if (text.length > length) {
    return text.substring(0, length) + '...'
  } else {
    return text
  }
}

export const replaceTabsWithSpaces = (value: string) => value.replace(/\t/g, ' ')

export function generateColorFromString(string: string) {
  // Hash the string using MD5 to get a unique identifier
  const hash = createHash('md5').update(string).digest('hex')

  // Extract the first 6 characters of the hash as the RGB values
  const red = parseInt(hash.slice(0, 2), 16)
  const green = parseInt(hash.slice(2, 4), 16)
  const blue = parseInt(hash.slice(4, 6), 16)

  // Combine the RGB values into a hex color code
  return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
    .toString(16)
    .padStart(2, '0')}`
}
