import { ReactNode } from 'react'
import { Popover } from 'antd'
import { StyledTooltip } from 'components/Tooltip'
import styled from 'styled-components'
import { PropsTheme } from 'theme'
import { MarketplaceSource, TiktokStatusLabel } from 'utils/constants'
import { ellipsisText } from 'utils/string'

export const HeaderLgPlus = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.lgPlus};
  font-weight: bold;
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const HeaderLg = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.lg};
  font-weight: bold;
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const HeaderMd = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes?.md};
  font-weight: bold;
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const HeaderSm = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.sm};
  font-weight: bold;
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const HeaderSmPlus = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.smMinus};
  font-weight: bold;
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const TextDynamic = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: any) => props.fontSize};
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const TextSmMultipleLines = styled.div.attrs((props: any) => ({
  $padding: props?.$padding || '0px',
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes?.sm};
  color: ${(props: any) => props.color};
  padding: ${(props: any) => props.$padding};
  text-align: ${(props: any) => props?.align || 'none'};
  white-space: pre-wrap; /* This preserves whitespace and line breaks */
`

export const TextLg = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.lg};
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const TextXL = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.xl};
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const TextMd = styled.div.attrs((props: any) => ({
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.md};
  color: ${(props: any) => props.color};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const TextSmMinus = styled.div.attrs((props: any) => ({
  $padding: props?.$padding || '0px'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes.smMinus};
  color: ${(props: any) => props.color};
  padding: ${(props: any) => props.$padding};
`

export const TextSm = styled.div.attrs((props: any) => ({
  $padding: props?.$padding || '0px',
  align: props?.align || 'none'
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes?.sm};
  color: ${(props: any) => props.color};
  padding: ${(props: any) => props.$padding};
  text-align: ${(props: any) => props?.align || 'none'};
`

export const TextWithBox = styled.div.attrs((props: any) => ({
  $padding: props?.$padding || '0px',
  width: props?.width || '35px',
  height: props?.height || '25px',
  align: props?.align || 'none',
  color: props?.color || 'gray',
  isActive: props?.isActive || false
}))`
  font-size: ${(props: PropsTheme) => props.theme.fontSizes?.sm};
  border-radius: 5px;
  width: ${(props: any) => props.width};
  height: ${(props: any) => props.height};
  background-color: ${(props: any) => props.color};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  opacity: ${(props: any) => (props.isActive && 1) || 0.5};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
`

export const BorderCell = styled.div.attrs((props: any) => ({
  border: props?.border || 'none',
  boderRadius: props?.boderRadius || 'none',
  padding: props?.padding || '0px'
}))`
  padding: 6px;
  &:hover,
  &:focus {
    border: ${(props: any) => props.border};
    border-radius: ${(props: any) => props.boderRadius};
    padding: ${(props: any) => props.padding};
  }
  cursor: pointer;
`

interface IStatusColor {
  statusColor: string
  color?: string
}

export const StatusTextWithColor = styled(TextSm).attrs((props: any) => ({
  statusColor: props?.statusColor,
  color: props?.color,
  left: props?.left || 0,
  top: props?.top || '50%',
  right: props?.right || 0,
  fontSize: props?.fontSize || props.theme.fontSizes?.sm
}))`
  font-size: ${(props) => props.fontSize}px;
  color: ${(props) => (props?.color ? props?.color : props.theme.fontColor?.dark)};
  padding-left: 1rem;
  position: relative;
  :before {
    content: '';
    width: 8px;
    height: 8px;
    background: ${(props) => props.statusColor};
    position: absolute;
    border-radius: 50%;
    top: ${(props) => props.top};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    margin-left: 4px;
    transform: translate(-50%, -50%);
  }
`

export const TextLink = styled(TextSm)`
  & a {
    color: ${(props: PropsTheme) => props.theme.colors.main};
  }
`

export const TextGray = styled(TextSm)`
  color: ${(props: PropsTheme) => props.theme.colors.medium};
`

interface ITextEllipsis extends PropsTheme {
  lineClamp?: number
}

export const TextEllipsis = styled(TextLink).attrs((props: ITextEllipsis) => ({
  lineClamp: props?.lineClamp || 1
}))`
  min-width: 136px;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const TextEllipsisWithHover: React.FC<any> = (props: any) => {
  const { children } = props || {}
  return (
    <StyledTooltip
      overlayClassName="main-tooltip"
      color="#ffffff"
      placement="bottom"
      title={children?.props?.children || children}
    >
      <TextEllipsis lineClamp={props.lineClamp}>{children}</TextEllipsis>
    </StyledTooltip>
  )
}

interface TextProps {
  children: React.ReactNode
}
export const TextOnlyHover: React.FC<TextProps> = (props: any) => {
  const { children } = props || {}
  return (
    <StyledTooltip
      overlayClassName="main-tooltip"
      color="#ffffff"
      placement="bottom"
      title={children?.props?.children[0] || children}
    >
      <TextEllipsis>{children?.props?.children[1] || children}</TextEllipsis>
    </StyledTooltip>
  )
}

export const TextHoverEditable: React.FC<TextProps> = (props: any) => {
  const { children } = props || {}
  return (
    <BorderCell border="1px solid #AEAEAE" boderRadius="3px" padding="5px">
      <div {...props}>{children}</div>
    </BorderCell>
  )
}

export const TextOnlyHoverLists: React.FC<TextProps> = (props: any) => {
  const { children } = props || {}
  return (
    <StyledTooltip
      overlayClassName="main-tooltip"
      color="#ffffff"
      overlayStyle={{ height: '100px' }}
      overlayInnerStyle={{
        height: '140px',
        width: '120px',
        position: 'absolute',
        left: '150px',
        bottom: '250px'
      }}
      placement="left"
      title={children?.props?.children[0] || children[0] || children}
    >
      <TextEllipsis>{children?.props?.children[1] || children[1] || children}</TextEllipsis>
    </StyledTooltip>
  )
}

interface TextEllipsisPlain {
  text: string
  maxLength: number
}
export const TextEllipsisPlain: React.FC<TextEllipsisPlain> = (props: TextEllipsisPlain) => {
  const { text, maxLength } = props || {}
  return (
    <StyledTooltip
      overlayClassName="main-tooltip"
      color="#ffffff"
      placement="bottom"
      title={text.length > maxLength ? text : ''}
    >
      <span>{ellipsisText(maxLength, text)}</span>
    </StyledTooltip>
  )
}
interface TextPreviewPopoverProps {
  content?: ReactNode
  text: string
  title?: ReactNode
}
export const TextPreviewPopover: React.FC<TextPreviewPopoverProps> = (props: TextPreviewPopoverProps) => {
  const { title, text, content } = props || {}
  return (
    <Popover color="#ffffff" placement="bottom" title={title} content={content}>
      <div>{title}</div>
    </Popover>
  )
}

interface ITextOverflow {
  textAlign?: string
  whiteSpace?: string
  width?: string
  overflow?: string
  textOverflow?: string
  margin?: string
}

export const TextOverflow = styled(TextSm).attrs((props: ITextOverflow) => ({
  textAlign: props?.textAlign || 'center',
  whiteSpace: props?.whiteSpace || 'nowrap',
  width: props?.width || '160px',
  overflow: props?.overflow || 'hidden',
  textOverflow: props?.textOverflow || 'ellipsis',
  margin: props?.margin || '0px'
}))`
  text-align: ${(props: any) => props.textAlign};
  white-space: ${(props: any) => props.whiteSpace};
  width: ${(props: any) => props.width};
  overflow: ${(props: any) => props.overflow};
  text-overflow: ${(props: any) => props.textOverflow};
  margin: ${(props: any) => props.margin};
`

interface MarketPlaceSourceLabelProps {
  source: string
  sourceStatus: string
}

export const MarketPlaceSourceLabel: React.FC<MarketPlaceSourceLabelProps> = ({ source, sourceStatus }) => {
  if (!sourceStatus) return '-'

  return source === MarketplaceSource.TIKTOK ? TiktokStatusLabel[sourceStatus] : sourceStatus
}
