import { LightTheme } from 'theme'

import { Activity } from '../utils/constants'

const lightTheme = {
  colors: {
    main: '#2A62FE',
    secondary: '#00AAFF',
    hover: '#EFF9FF',
    dark: '#666666',
    medium: '#AEAEAE',
    darkGrey: '#DDDDDD',
    mediumGrey: '#EAEAEA',
    whiteGrey: '#F8F8F8',
    whiteMixGrey: '#F4F4F4',
    whiteOff: '#FCFDFE',
    whiteSnow: '#FCFCFC',
    white: '#FFFFFF',
    success: '#08CE87',
    lightWarning: '#FFA32D',
    warning: '#FF9E1F',
    info: '#00AAFF',
    danger: '#FF595A',
    cheerful: '#8D7AFF',
    cyan: '#09BED8',
    safetyOrange: '#FF6F00'
  },
  productColorStatus: {
    active: '#08CE87',
    inactive: '#FF595A',
    draft: '#666666'
  },
  orderColorStatus: {
    raw: '#AEAEAE',
    draft: '#8D7AFF',
    wait_for_production: '#C69986',
    wait_for_approval: '#FF9E1F',
    made_to_order: '#C69986',
    assembly: '#C69986',
    qc_passed: '#97F8D5',
    open: '#AEAEAE',
    ready_to_ship: '#FF9E1F',
    prepick: '#FF9E1F',
    prepared: '#FF9E1F',
    picked: '#8D7AFF',
    printed: '#8D7AFF',
    packed: '#8D7AFF',
    boarded: '#09BED8',
    transported: '#09BED8',
    delivered: '#08CE87',
    cancelled: '#FF595A',
    wait_return: '#FF75D0',
    returned: '#666666',
    doubted: '#FF595A',
    lost: '#FF595A',
    unknown_source_status: '#AEAEAE',
    resolved_by_marketplace: '#666666',
    fleet_in_progress: '#8D7AFF',
    fleeted: '#8D7AFF',
    fleet_ready: '#8D7AFF',
    rejected_approval: '#FF595A'
  },
  urgency: {
    high_urgent: '#ff5254',
    med_urgent: '#ffa02a',
    low_urgent: '#f0f0f0'
  },

  paymentColorStatus: {
    unpaid: '#AEAEAE',
    cod: '#09BED8',
    partial: '#FF9E1F',
    partial_cod: '#C69986',
    paid: '#08CE87',
    transferred: '#08CE87',
    cancelled: '#FF595A',
    wait_refund: '#FF75D0',
    refunded: '#666666',
    free: '#8D7AFF'
  },
  paymentVerifyColorStatus: {
    verified: '#08CE87',
    not_verified: '#AEAEAE',
    partial_verified: '#FF9E1F',
    issue: '#FF595A'
  },
  paymentVerifyStatusOptionBgColor: {
    verified: 'rgba(8, 206, 135, 1)',
    not_verified: 'rgba(253, 147, 41, .15)',
    issue: 'rgba(252, 81, 82, 1)'
  },
  paymentVerifyStatusOptionTextColor: {
    verified: 'rgba(0, 0, 0, .8)',
    not_verified: 'rgba(253, 147, 41, 1)',
    issue: '#FFFFFF'
  },
  slipColorStatus: {
    verified: '#08CE87',
    not_verified: '#AEAEAE',
    issue: '#FF595A'
  },
  bankTxColorStatus: {
    matched: '#08CE87',
    partial_matched: '#FF9E1F',
    not_matched: '#AEAEAE',
    issue: '#FF595A'
  },
  saleColorStatus: {
    bought: '#08CE87',
    bought_other_channel: '#09BED8',
    draft: '#FF9E1F',
    wait_to_decide: '#FF75D0',
    ask_question: '#8D7AFF',
    line_menu: '#AEAEAE',
    blocked: '#FF595A'
  },
  purchaseRequisitionColorStatus: {
    open: '#AEAEAE',
    request_super_approval: '#FFBD66',
    approved: '#08CE87',
    rejected: '#FF595A',
    returned: '#FFBD66',
    request_purchase_confirm: '#FFBD66',
    wait_for_po_confirm: '#FFBD66',
    request_po_confirm: '#FFBD66'
  },
  purchaseRequisitionPaymentColorStatus: {
    partial: '#FF9E1F',
    paid: '#08CE87'
  },

  purchaseColorStatus: {
    // open: '#AEAEAE',

    // request_approval: '#FFBD66',
    // approved: '#08CE87',
    // rejected: '#FF595A',

    // processed_vendor: '#FF9E1F',
    // in_transit_remote: '#C69986',
    // in_transit_local: '#09BED8',
    // received: '#08CE87',
    // finished: '#08CE87',
    // refund_sending: '#C69986',
    // refund_receiving: '#C69986',

    // cancelled: '#FF595A',
    // doubted: '#FF595A',
    // wait_return: '#FF75D0',
    // returned: '#666666'

    open: '#AEAEAE',
    request_approval: '#FFBD66',
    request_purchase_confirm: '#7FB3D5',
    request_super_approval: '#FFBD66',
    approved: '#08CE87',
    paid: '#08CE87',
    purchased: '#FFBD66',
    received_correct: '#08CE87',
    received_incorrect: '#FF75D0',
    rejected: '#FF595A',
    returned: '#FFBD66',

    in_transit_to_warehouse: '#09BED8',
    warehouse_received: '#C2D69B',
    wait_for_documents: '#FF9999',
    wait_purchase_add_receive_date: '#F9E490'
  },
  purchasePaymentColorStatus: {
    unpaid: '#AEAEAE',
    partial: '#FF9E1F',
    credit: '#C69986',
    paid: '#08CE87',
    cancelled: '#FF595A',
    wait_refund: '#FF75D0',
    refunded: '#666666'
  },
  invAdjustmentOrderStatus: {
    wait_approval: '#AEAEAE',
    approved: '#FF9E1F',
    confirmed: '#08CE87',
    draft: '#8D7AFF',
    open: '#AEAEAE',
    in_progress: '#FF9E1F',
    done: '#08CE87',
    cancelled: '#FF595A'
  },
  progressBarColor: {
    open: '#AEAEAE',
    inProgress: '#FFBD66',
    done: '#00F59C',
    cancelled: '#FF595A',
    inDoubt: '#FF75D0'
  },
  ProductTrackingActionStatus: {
    TODO: '#AEAEAE',
    IN_PROGRESS: '#FF9E1F',
    DONE: '#08CE87'
  },
  productTrackingStatus: {
    active: '#08CE87',
    in_active: '#666666',
    cancelled: '#FF595A'
  },
  exportColor: {
    detail: '#00AAFF',
    shipper: '#08CE87',
    receiver: '#8D7AFF',
    other: '#FF75D0'
  },

  buttonColor: {
    primary: 'rgba(42,98,254,1)',
    primaryHover: 'rgba(42,98,254,0.8)',
    primaryDisabled: 'rgba(42,98,254,0.5)',

    primaryFontColor: 'rgba(255,255,255,1)',
    primaryHoverFontColor: 'rgba(255,255,255,0.8)',
    primaryDisabledFontColor: 'rgba(255,255,255,0.5)',

    secondary: 'rgba(0,170,255,1)',
    secondaryHover: 'rgba(0,170,255,0.7)',
    secondaryDisabled: 'rgba(0,170,255,0.6)',

    secondaryFontColor: 'rgba(0,170,255,1)',
    secondaryHoverFontColor: 'rgba(0,170,255,0.7)',
    secondaryDisabledFontColor: 'rgba(0,170,255,0.6)'
  },

  iconColor: {
    light: 'rgba(0, 0, 0, 0.25)'
  },
  fontColor: {
    dark: 'rgba(0, 0, 0, 0.8)',
    mediumPlus: 'rgba(0, 0, 0, 0.7)',
    medium: 'rgba(0, 0, 0, 0.6)',
    light: 'rgba(0, 0, 0, 0.4)',
    gray: 'rgba(78, 78, 78, 1)',
    lightGray: 'rgba(74, 74, 74, 1)',
    main: '#2A62FE',
    lightMain: '#2A62FE80',
    secondary: '#00AAFF',
    info: '#37A7E5',
    danger: '#F45051',
    warning: '#FF8A00',
    success: '#00ADA2'
  },
  shadowColor: {
    light: 'rgba(0, 0, 0, 0.25)',
    veryLight: 'rgba(0, 0, 0, 0.06)',
    lightest: 'rgba(0, 0, 0, 0.08)'
  },
  fontSizes: {
    smMinus: '0.75rem', //12px
    sm: '0.8125rem', //13px
    md: '0.875rem', //14px
    lg: '0.938rem', //15px
    lgPlus: '1rem', // 16px
    xl: '1.25rem' // 20px
  },
  fontFamily: 'ibm-plex-thai, sans-serif',
  fontSizesPx: {
    smMinus: '12px',
    sm: '13px',
    md: '14px',
    lg: '15px',
    lgPlus: '16px',
    xl: '20px'
  },
  notificationsColorStatus: {
    open: '#FF595A',
    in_progress: '#09BED8',
    resolved: '#08CE87'
  },
  activityColorStatus: {
    [Activity.ASSIGN_PRODUCTION_JOB]: '#EAEAEA',
    [Activity.CLOSE_PRODUCTION_JOB]: '#00ADA2',
    [Activity.DEFECT_PRODUCT]: '#FF595A',
    [Activity.IMPORT_TO_INVENTORY_JOB]: '#8D7AFF'
  },
  deliveryFleetColorStatus: {
    draft: '#55B6E5',
    queued: '#2A62FE',
    shipping: '#FF9F20',
    finished: '#009761'
  },
  accountingOrderColumnHeaderColors: {
    platform: '#FF75D0',
    fm: '#62bde7',
    calculation: '#FFA32D'
  },
  ticketAction: {
    submit_ticket: '#2A62FE',
    appeal: '#FFA32D',
    approved: '#08CE87',
    wait_for_approve: '#7FB3D5',
    wait_for_inform: '#FF8C69',
    wait_for_customer_decide: '#FF7F50',
    negotiate: '#FFA07A',
    picked_to_send_new: '#2A62FE',
    picked_to_claim: '#2A62FE',
    picked_to_repair: '#2A62FE',
    picked_to_service: '#2A62FE',
    create_incomplete_deliver_order: '#2A62FE',
    create_retrieve_order: '#2A62FE',
    create_claim_order: '#2A62FE',
    received_back: '#2A62FE',
    boarded_new: '#2A62FE',
    delivered: '#2A62FE',
    accept: '#FF595A',
    done: '#2A62FE',
    disapproved: '#FF595A',
    killswitch: '#FF595A'
  },
  ticketResponsibility: {
    submit_ticket: '#FFD79A',
    appeal: '#FFD79A',
    approved: '#80FFCC',
    wait_for_approve: '#80FFCC',
    wait_for_inform: '#FFD79A',
    wait_for_customer_decide: '#FFD79A',
    negotiate: '#FFD79A',
    picked_to_claim: '#FFD79A',
    picked_to_repair: '#FFD79A',
    picked_to_service: '#FFD79A',
    create_incomplete_deliver_order: '#FFD79A'
  }
} as LightTheme

export { lightTheme }
