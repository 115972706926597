import { Activity } from 'utils/constants'

const darkTheme = {
  colors: {
    main: '#4987FF',
    secondary: '#4CC6FF',
    hover: '#EFF9FF',
    darkGrey: '#4D4D4D',
    mediumGrey: '#6D6D6D',
    lightGrey: '#ADADAD',
    veryLightGrey: '#BDBDBD',
    danger: '#F94949',
    lightWarning: '#FFA32D',
    warning: '#FCAB31',
    success: '#08D0AC',
    whiteOff: '#FCFDFF',
    white: '#FFFFFF',
    whiteSnow: '#FCFCFC'
  },
  fontFamily: 'ibm-plex-thai, sans-serif',
  fontSizes: {
    sm: '0.75em',
    md: '0.875em',
    lg: '0.938em'
  },
  notificationsColorStatus: {
    open: '#FF595A',
    in_progress: '#09BED8',
    resolved: '#08CE87'
  },
  activityColorStatus: {
    [Activity.ASSIGN_PRODUCTION_JOB]: '#EAEAEA',
    [Activity.CLOSE_PRODUCTION_JOB]: '#00ADA2',
    [Activity.DEFECT_PRODUCT]: '#FF595A',
    [Activity.IMPORT_TO_INVENTORY_JOB]: '#8D7AFF'
  },
  deliveryFleetColorStatus: {
    draft: '#55B6E5',
    queued: '#2A62FE',
    shipping: '#FF9F20',
    finished: '#009761'
  }
}

export { darkTheme }
