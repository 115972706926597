import { message } from 'antd'
import { STATUS_MODAL_ALERT } from 'utils/constants'

import { onError } from '@apollo/client/link/error'

import { formatMessage } from './logger'

const LOGIN_NOTIFICATION_KEY = 'login-notification-key'
export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && graphQLErrors?.some((err) => err?.message?.includes('Cannot return null for non-nullable'))) {
    message[STATUS_MODAL_ALERT.ERROR]({ content: 'ไม่พบข้อมูลในระบบ', key: LOGIN_NOTIFICATION_KEY, duration: 5 })
  }

  if (graphQLErrors && graphQLErrors?.some((err) => err?.message?.includes('Forbidden resource'))) {
    message[STATUS_MODAL_ALERT.ERROR]({
      content: 'คุณไม่มีสิทธิ์เข้าถึง Feature นี้',
      key: LOGIN_NOTIFICATION_KEY,
      duration: 2
    })
  }

  if (graphQLErrors) {
    // log error like normal
    const group = formatMessage('error', operation)

    console.group(...group)

    console.log('INIT', operation)
    console.log('RESULT', graphQLErrors)

    console.groupEnd()
  } else if (networkError) {
    // TODO handle retry
    console.log(`[Network error]: ${networkError}`)
  }
})
