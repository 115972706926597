import { Tooltip } from 'antd'
import styled from 'styled-components'

export const WrapperTooltip = styled.div.attrs((props: { $minWidth: number }) => ({
  $minWidth: props?.$minWidth
}))`
  position: relative;

  .ant-tooltip {
    min-width: ${(props) => (props?.$minWidth ? `${props?.$minWidth}px` : 'unset')};

    .ant-tooltip-inner {
      color: #000000;
      background-color: #ffffff;
      font-size: 10px;
      border-radius: 8px;
      min-height: unset;
      padding: 6px 10px;
    }
  }

  .tooltip-with-borderd {
    .ant-tooltip-inner {
      color: #000000;
      font-size: 10px;
      border-radius: 8px;
      border: 1px solid #2a62fe;
      min-height: unset;
      padding: 6px 10px;
    }
  }

  .tooltip-with-borderd.add-min-width {
    min-width: ${(props) => (props?.$minWidth ? `${props?.$minWidth}px` : 'unset')};
  }
`

// this is for legacy code will be deleted.
export const StyledTooltip = styled(Tooltip)`
  /* cursor: pointer; */
`
