import React, { FC, ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import { ConfigProvider } from 'antd'
import { GlobalStyle } from 'assets/GlobalStyles'
import { TextSm } from 'components/Typography'
import NProgress from 'nprogress'
import ApolloClientProvider from 'providers/ApolloClientProvider'
import { ThemeProvider } from 'styled-components'
import { lightTheme } from 'theme'
import { PathMenu, publicPaths } from 'utils/constants'

import { Auth0Provider } from '@auth0/auth0-react'
import { Analytics } from '@vercel/analytics/react'

import '../assets/main.scss'
import 'react-image-gallery/styles/scss/image-gallery.scss'

NProgress.configure({ showSpinner: false })

const trustLogger = (...text) => {
  // console.log(...text)
}
Router.events.on('routeChangeStart', (url, { shallow }) => {
  trustLogger('routeChangeStart', url, 'shallow', shallow)
  NProgress.start()
})
Router.events.on('routeChangeComplete', (url, { shallow }) => {
  trustLogger('routeChangeComplete', url, 'shallow', shallow)
  NProgress.done()
})
Router.events.on('routeChangeError', (error, url) => {
  trustLogger('routeChangeError', error, 'url', url)
  NProgress.done()
})

Router.events.on('beforeHistoryChange', (url, { shallow }) => {
  trustLogger('beforeHistoryChange', url, 'shallow', shallow)
})
Router.events.on('hashChangeStart', (url, { shallow }) => {
  trustLogger('hashChangeStart', url, 'shallow', shallow)
})
Router.events.on('hashChangeComplete', (url, { shallow }) => {
  trustLogger('hashChangeComplete', url, 'shallow', shallow)
})
// interface MyAppProps extends AppContext {
//   pageProps?: any
// }

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? ((page) => page)
  const { pathname } = useRouter()

  // check is public path
  const isPublicPath = publicPaths.has(pathname as PathMenu)

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />

      <Script strategy="lazyOnload" id="google-tag">
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
              });
          `}
      </Script>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Security-Policy" content="default-src * data: 'unsafe-eval' 'unsafe-inline' blob:" />
      </Head>
      {
        // isPublicPath
        isPublicPath ? (
          <ApolloClientProvider pageProps={pageProps}>
            <ThemeProvider theme={lightTheme}>
              <ConfigProvider renderEmpty={renderEmpty} theme={themeConfig}>
                <GlobalStyle />
                {/* <StagingBar /> */}
                {getLayout(<Component {...pageProps} />)}
              </ConfigProvider>
            </ThemeProvider>
          </ApolloClientProvider>
        ) : (
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
            authorizationParams={{
              audience: process.env.NEXT_PUBLIC_AUTH_AUDIENCE,
              redirect_uri: process.env.NEXT_PUBLIC_APP_URL,
              scope: 'openid offline_access profile email read:current_user update:current_user_metadata'
            }}
            cacheLocation={'localstorage'}
            useRefreshTokens={true}
          >
            <ApolloClientProvider pageProps={pageProps}>
              <ThemeProvider theme={lightTheme}>
                <ConfigProvider renderEmpty={renderEmpty} theme={themeConfig}>
                  <GlobalStyle />
                  {/* <StagingBar /> */}
                  {getLayout(<Component {...pageProps} />)}
                </ConfigProvider>
              </ThemeProvider>
            </ApolloClientProvider>
          </Auth0Provider>
        )
      }
      <Analytics />
    </>
  )
}

const themeConfig = {
  token: {
    colorPrimary: 'rgba(86, 182, 230, 1)',
    fontFamily: 'ibm-plex-thai, sans-serif',
    fontSize: 11,
    colorText: 'rgba(38, 42, 46, 0.87)'
  },
  components: {
    Steps: {
      colorPrimary: '#2A62FE',
      dotSize: 16,
      dotCurrentSize: 16,
      navContentMaxWidth: 16
    },
    Select: {
      optionSelectedBg: 'rgba(42, 98, 254, 0.05)',
      colorPrimary: '#2A62FE'
    },
    Input: {
      colorPrimary: '#2A62FE'
    },
    Radio: {
      colorPrimary: '#2A62FE'
    }
  }
}

const renderEmpty = () => <TextSm style={{ textAlign: 'center', padding: '90px' }}>ไม่พบข้อมูล</TextSm>

export default MyApp
