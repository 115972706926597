import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {
    --desktop-container-width: 1280px;
    /* primary */
    --sky-blue: rgba(86, 182, 230, 1);
    --blue-jay: rgba(104, 203, 242, 1);
    --heron-blue: rgba(123, 218, 255, 1);

    /* accent */
    --living-coral: rgba(255, 111, 97, 1);
    --rubberband-pink: rgba(255, 124, 118, 1);
    --sand-pink: rgba(255, 142, 136, 1);

    /* secondary */
    --gray-background: rgba(246, 246, 246, 1);
    --blue-button: rgba(248, 253, 255, 1);
    --red-button: rgba(255, 237, 236, 1);

    /* other */
    --white: #ffffff;
    --black00: rgba(0, 0, 0, 1);
    --black10: rgba(74, 74, 74, 0.1);
    --black87: rgba(38, 42, 46, 0.87);
  }

  @font-face {
    font-family: 'ibm-plex-thai';
    src: url('https://use.typekit.net/af/1bad7a/00000000000000003b9b3253/27/l?primer=01e54ac443e5962d277497d5db0e95b217578553b6e87aefd4b4a8392944ef0e&fvd=n4&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/1bad7a/00000000000000003b9b3253/27/d?primer=01e54ac443e5962d277497d5db0e95b217578553b6e87aefd4b4a8392944ef0e&fvd=n4&v=3')
        format('woff'),
      url('https://use.typekit.net/af/1bad7a/00000000000000003b9b3253/27/a?primer=01e54ac443e5962d277497d5db0e95b217578553b6e87aefd4b4a8392944ef0e&fvd=n4&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'ibm-plex-thai';
    src: url('https://use.typekit.net/af/4262c2/00000000000000003b9b3256/27/l?primer=01e54ac443e5962d277497d5db0e95b217578553b6e87aefd4b4a8392944ef0e&fvd=n7&v=3')
        format('woff2'),
      url('https://use.typekit.net/af/4262c2/00000000000000003b9b3256/27/d?primer=01e54ac443e5962d277497d5db0e95b217578553b6e87aefd4b4a8392944ef0e&fvd=n7&v=3')
        format('woff'),
      url('https://use.typekit.net/af/4262c2/00000000000000003b9b3256/27/a?primer=01e54ac443e5962d277497d5db0e95b217578553b6e87aefd4b4a8392944ef0e&fvd=n7&v=3')
        format('opentype');
    font-display: swap;
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: 'Noto Sans Thai';
    src:
      url('https://cdn.lazywasabi.net/fonts/NotoSansThai/NotoSansThai-Regular.woff2')
      format('woff2'),
      url('https://cdn.lazywasabi.net/fonts/NotoSansThai/NotoSansThai-Regular.woff')
      format('woff');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Noto Sans Thai';
    src:
      url('https://cdn.lazywasabi.net/fonts/NotoSansThai/NotoSansThai-Bold.woff2')
      format('woff2'),
      url('https://cdn.lazywasabi.net/fonts/NotoSansThai/NotoSansThai-Bold.woff')
      format('woff');
    font-style: normal;
    font-weight: bold;
    font-display: swap;
  }

  .tk-ibm-plex-thai {
    font-family: 'ibm-plex-thai', sans-serif;
  }

  html {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
  }

  body {
    font-family: Noto Sans Thai, ibm-plex-thai, sans-serif !important;
    margin: 0;
    letter-spacing: 0.2px;
  } 

  #layout {
    background-color: white;
  }

  .ant-form-item-with-help{
    margin-bottom: 0px!important;
  }

  .ant-dropdown-menu {
    border-radius: 8px;
    border: 0.5px solid transparent;
    padding: 0.8rem;

    .ant-dropdown-menu-item {
      font-size: 0.8125rem;
      line-height: 2;
      padding: 0;
      margin: 0;
    }
  }
`
