export enum PackingType {
  SINGLE = 'single',
  RUSH_SINGLE = 'rushSingle',
  MULTIPLE = 'multiple',
  RUSH_MULTIPLE = 'rushMultiple',
  RUSH = 'rush',
  NON_RUSH = 'nonRush',
  RUSH_MULTIPLE_SCAN = 'rushMultipleScan',
  MULTIPLE_SCAN = 'multipleScan',
  ALL = 'all'
}

export enum PackingOneNPrintingType {
  NORMAL = 'normal',
  SCAN = 'scan'
}

export enum DaysDiffType {
  OVER_5_DAYS = 5,
  OVER_4_DAYS = 4,
  OVER_3_DAYS = 3,
  OVER_2_DAYS = 2
}

export enum ShopeeOrderStatus {
  UNPAID = 'UNPAID',
  READY_TO_SHIP = 'READY_TO_SHIP',
  PROCESSED = 'PROCESSED', // has been init
  RETRY_SHIP = 'RETRY_SHIP',
  SHIPPED = 'SHIPPED',
  TO_CONFIRM_RECEIVE = 'TO_CONFIRM_RECEIVE',
  IN_CANCEL = 'IN_CANCEL',
  CANCELLED = 'CANCELLED',
  TO_RETURN = 'TO_RETURN',
  COMPLETED = 'COMPLETED'
}

export enum LazadaOrderStatus {
  UNPAID = 'unpaid',
  PENDING = 'pending',
  CANCELED = 'canceled',
  READY_TO_SHIP = 'ready_to_ship',
  DELIVERED = 'delivered',
  RETURNED = 'returned',
  SHIPPED = 'shipped',
  FAILED = 'failed',
  TO_DOMESTIC_RETURN = 'INFO_ST_DOMESTIC_RETURN_WITH_LAST_MILE_3PL',
  LOST = 'LOST_BY_3PL'
}

/**
 * Order Status
 */
export enum TiktokOrderStatus {
  /**
   * UNPAID: The order has been placed but payment has not been finished.
   */
  UNPAID = 100,
  /**
   * ON_HOLD (currently ON_HOLD status is only available in the UK market): Payment has been finished, but order allow buyer to cancel without seller approval. Not allow seller fulfill order under ON_HOLD status.
   */
  ON_HOLD = 105,
  /**
   * AWAITING_SHIPMENT: Order is ready for shipment, but no items in the order have been shipped.
   */
  AWAITING_SHIPMENT = 111,
  /**
   * AWAITING_COLLECTION: Seller arranged shipment, but package is still waiting to handover the parcel to carrier.
   */
  AWAITING_COLLECTION = 112,
  /**
   * PARTIALLY_SHIPPING: One or more (but not all) items in the order have been shipped.
   */
  PARTIALLY_SHIPPING = 114,
  /**
   * IN_TRANSIT: Package has been collected by the carrier.
   */
  IN_TRANSIT = 121,
  /**
   * DELIVERED: Package delivered to buyer.
   */
  DELIVERED = 122,
  /**
   * COMPLETED: Order has been completed. Orders are not allowed to initiate return or refund anymore.
   */
  COMPLETED = 130,
  /**
   * CANCELLED: The order was cancelled.
   */
  CANCELLED = 140
}

export enum TiktokOrderStatusString {
  /**
   * UNPAID: The order has been placed but payment has not been finished.
   */
  UNPAID = '100',
  /**
   * ON_HOLD (currently ON_HOLD status is only available in the UK market): Payment has been finished, but order allow buyer to cancel without seller approval. Not allow seller fulfill order under ON_HOLD status.
   */
  ON_HOLD = '105',
  /**
   * AWAITING_SHIPMENT: Order is ready for shipment, but no items in the order have been shipped.
   */
  AWAITING_SHIPMENT = '111',
  /**
   * AWAITING_COLLECTION: Seller arranged shipment, but package is still waiting to handover the parcel to carrier.
   */
  AWAITING_COLLECTION = '112',
  /**
   * PARTIALLY_SHIPPING: One or more (but not all) items in the order have been shipped.
   */
  PARTIALLY_SHIPPING = '114',
  /**
   * IN_TRANSIT: Package has been collected by the carrier.
   */
  IN_TRANSIT = '121',
  /**
   * DELIVERED: Package delivered to buyer.
   */
  DELIVERED = '122',
  /**
   * COMPLETED: Order has been completed. Orders are not allowed to initiate return or refund anymore.
   */
  COMPLETED = '130',
  /**
   * CANCELLED: The order was cancelled.
   */
  CANCELLED = '140'
}

export const TiktokStatusLabel = {
  '100': 'unpaid',
  '105': 'on_hold',
  '111': 'awaiting_shipment',
  '112': 'awaiting_collection',
  '114': 'partially_shipping',
  '121': 'in_transit',
  '122': 'delivered',
  '130': 'completed',
  '140': 'cancelled'
}

export enum MarketplaceSource {
  SHOPEE = 'shopee',
  LAZADA = 'lazada',
  TIKTOK = 'tiktok',
  MANUAL = 'manual',
  WEBSITE = 'website',
  PREORDER = 'preorder',
  DEPARTMENT_STORE = 'department-store',
  LNWSHOP = 'lnwshop',
  CLAIM = 'claim'
}

export const MarketplaceSourceType = {
  [MarketplaceSource.SHOPEE]: 'shopee',
  [MarketplaceSource.LAZADA]: 'lazada',
  [MarketplaceSource.TIKTOK]: 'tiktok',
  [MarketplaceSource.MANUAL]: 'manual',
  [MarketplaceSource.WEBSITE]: 'website',
  [MarketplaceSource.PREORDER]: 'preorder',
  [MarketplaceSource.DEPARTMENT_STORE]: 'department-store',
  [MarketplaceSource.LNWSHOP]: 'lnwshop'
}

export enum MarketplaceSourceLabel {
  SHOPEE = 'Shopee',
  LAZADA = 'Lazada',
  MANUAL = 'Manual'
}

export enum TRCloudSource {
  TRCLOUD = 'trcloud'
}

export enum TRCloudSourceLabel {
  TRCLOUD = 'TRCloud'
}

export enum TypeImage {
  COVER = 'cover',
  NORMAL = 'normal'
}

export enum ScanToUpdateStatusResult {
  SUCCESS = 'success',
  SUSPEND = 'suspend',
  UNPACK = 'unpack',
  NOT_FOUND = 'notfound',
  ERROR = 'error'
}

export enum OrderSourceKey {
  DEPARTMENT_STORE = 'department-store'
}

export enum DeliveryChannelKey {
  OWN_FLEET = 'own-fleet'
}

//In the future should retrive from db
export enum NameMenu {
  HOME = 'หน้าหลัก',
  NOTIFICATION_SHOW = 'การแจ้งเตือน',
  WORKSPACE_DASHBOARD = 'ภาพรวม',
  PICKING = 'จัดส่งสินค้า',
  PICKING_ORDER = 'เลือกรายการจัดส่ง',
  PICKING_LISTS = 'ใบหยิบสินค้า',
  PICKING_LIST_ADJUSTMENT = 'ปรับใบหยิบสินค้า',
  PICKING_LIST_DETAIL = ' รายละเอียดใบหยิบสินค้า',

  SCAN_PICKING_LIST = 'สแกนใบหยิบสินค้า',

  PACKING_ORDERS_PREPICK = 'สินค้าคลังวางแผนหยิบ',
  PACKING_ORDERS_PREPARED = 'สินค้าคลังหยิบเสร็จ',
  PACKING_ORDERS = 'สินค้าที่แพคส่งได้',
  PACKING_NP_ORDERS = 'บรรจุ np คำสั่งซื้อ',

  SCAN_PRINTING = 'QRCode สแกนพิมพ์',
  SCAN_PRINTING_ONE_N = 'QR สแกนพิมพ์ 1-N',
  SCAN_PACKED = 'สแกน PACKED',
  PACKING_ORDER_HISTORY = 'สรุปการจัดส่ง',
  CHANGE_ORDER_STATE = 'เปลี่ยนสถานะคำสั่งซื้อ',
  PRODUCT = 'สินค้า',
  PRODUCT_RECOMMENDATION = 'สินค้าแนะนำ',
  PRODUCT_LIST = 'สินค้าทั้งหมด',
  PRODUCT_NORMAL = 'สินค้าธรรมดา',
  PRODUCT_RAW = 'ส่วนประกอบทั้งหมด',
  PRODUCT_SET = 'สินค้าจัดเซตทั้งหมด',
  PRODUCT_DETAIL = 'รายละเอียดสินค้า',
  PRODUCT_CREATE = 'เพิ่มสินค้าใหม่',
  PRODUCT_EDIT = 'แก้ไขสินค้า',
  PRODUCT_SHOW = 'รายละเอียดสินค้า',
  PRODUCT_SHOW_SYNC = 'อัปเดตจำนวนสินค้า',
  PRODUCT_FAKE_REFILL_STOCK = 'สต๊อกหลอก',
  PRODUCT_TIMELINE = 'การเคลื่อนไหวสินค้า',

  BOOTH_STOCK_LIST = 'เช็ค stock คงเหลือ',

  GENERAL_VEHICLE_USE = 'ขอใช้รถ',
  GENERAL_VEHICLE_USE_SHOW = 'ขอใช้รถ',
  GENERAL_VEHICLE_USE_CREATE = 'สร้างรายการขอใช้รถ',
  GENERAL_VEHICLE_USE_UPDATE = 'สร้างรายการขอใช้รถ',
  GENERAL_VEHICLE_USE_PRINT = 'ขอใช้รถ',

  STOCK = 'ควบคุมสต๊อก',
  PRODUCT_TRACKING_CREATE = 'สร้าง QR Code',
  PRODUCT_TRACKING_LIST = 'QR Code ในระบบ',
  PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY = 'สแกนเปลี่ยนสถานะ',
  PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY = 'สแกนรอตรวจสอบสินค้า',
  INSPECTION_STOCK_LIST = 'ตรวจนับ(เลิกใช้)',
  INSPECTION_STOCK_SHOW = 'ใบตรวจนับสินค้า',
  INSPECTION_STOCK_CREATE = 'สร้างใบตรวจนับสินค้า',
  REDUCE_STOCK_LIST = 'เบิกสินค้า',
  REDUCE_STOCK_SHOW = 'ใบเบิกสินค้า',
  REDUCE_STOCK_CREATE = 'สร้างใบเบิกสินค้า',
  ADD_STOCK_LIST = 'รับสินค้า',
  ADD_STOCK_SHOW = 'ใบรับสินค้า',
  ADD_STOCK_CREATE = 'สร้างใบรับสินค้า',

  AUDIT_CYCLE_CHECK_LIST = 'ตรวจนับสินค้า',
  AUDIT_CYCLE_CHECK_CREATE = 'สร้างเอกสารตรวจนับสินค้า',
  AUDIT_CYCLE_CHECK_DETAIL = 'รายละเอียดเอกสารนับสินค้า',

  STOCK_OTHERS = 'อื่นๆ',
  PRODUCTION_ORDER = 'ผลิตสินค้า',
  PRODUCTION_ORDER_LIST = 'ผลิตสินค้า',
  PRODUCTION_ORDER_SHOW = 'ใบผลิตสินค้า',
  PRODUCTION_ORDER_CREATE = 'สร้างใบผลิตสินค้า',
  PRODUCTION_ORDER_UPDATE = 'แก้ไขใบผลิตสินค้า',

  FINISHED_GOODS_DEPOSIT_CREATE = 'สร้างใบรับสินค้า',
  FINISHED_GOODS_DEPOSIT_LIST = 'รับสินค้าสำเร็จรูป',
  FINISHED_GOODS_DEPOSIT_DETAIL = 'ใบรับสินค้า',
  FINISHED_GOODS_DEPOSIT_PRINT = 'พิมพ์ใบรับสินค้า',

  RAW_MATERIAL_WITHDRAW_CREATE = 'สร้างเอกสารเบิกวัตถุดิบ',
  RAW_MATERIAL_WITHDRAW_LIST = 'เบิกวัตถุดิบ',
  RAW_MATERIAL_WITHDRAW_DETAIL = 'จัดการสินค้า',
  RAW_MATERIAL_WITHDRAW_PRINT = 'พิมพ์เอกสารเบิกวัตถุดิบ',

  RAW_MATERIAL_DEPOSIT_CREATE = 'เอกสารรับวัตถุดิบ',
  RAW_MATERIAL_DEPOSIT_LIST = 'รับวัตถุดิบ',
  RAW_MATERIAL_DEPOSIT_DETAIL = 'จัดการสินค้า',
  RAW_MATERIAL_DEPOSIT_PRINT = 'พิมพ์เอกสารรับวัตถุดิบ',

  TRANSFER_IN_INVENTORY_ORDER_LIST = 'โอนสินค้าเข้าคลัง',
  TRANSFER_IN_INVENTORY_ORDER_SHOW = 'ใบโอนสินค้าเข้าคลัง',

  TRANSFER_OUT_INVENTORY_ORDER_LIST = 'TFW List',
  TRANSFER_OUT_INVENTORY_ORDER_CREATE = 'TFW Create',
  TRANSFER_OUT_INVENTORY_ORDER_SHOW = 'TFW Show',
  TRANSFER_OUT_INVENTORY_ORDER_UPDATE = 'TFW Update',

  TRANSFER_INTERNAL_ORDER_LIST = 'TFG List',
  TRANSFER_INTERNAL_ORDER_CREATE = 'TFG Create',
  TRANSFER_INTERNAL_ORDER_SHOW = 'TFG Show',
  TRANSFER_INTERNAL_ORDER_UPDATE = 'TFG Update',

  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST = 'RWMF List',
  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_SHOW = 'RWMF Detail',

  QR_CODE_TRACKING = 'QR Code Tracking',

  QR_CODE_LIST = 'ดาวน์โหลด QR Code',
  TRANSFER_ORDER_LIST = 'ย้ายสินค้า',
  TRANSFER_ORDER_SHOW = 'ใบย้ายสินค้า',
  TRANSFER_ORDER_CREATE = 'สร้างใบย้ายสินค้า',
  PRODUCT_ISSUE_REPORT_LIST = 'สินค้ามีปัญหา',
  NP_ORDER_LIST = 'ผลิตตามสั่ง',
  NP_ORDER_DETAIL = 'เอกสารสินค้ารอผลิต',
  NP_ORDER_CREATE = 'สร้างเอกสาร NP',
  PRODUCT_ISSUE_REPORT_SHOW = 'ใบแจ้งปัญหา',
  PRODUCT_ISSUE_REPORT_CREATE = 'สร้างใบแจ้งปัญหา',
  PRODUCT_CATEGORY = 'หมวดหมู่',
  PRODUCT_CATEGORY_EDIT = 'แก้ไขหมวดหมู่ย่อย',
  PRODUCT_CATEGORY_SHOW = 'การแสดงหมวดหมู่',
  ORDER = 'คำสั่งซื้อ',
  ORDER_CHOOSE_LABEL = 'เปลี่ยนใบปะหน้า',
  ORDER_INSPECT = 'ตรวจเช็คคำสั่งซื้อ',
  ORDER_LIST = 'คำสั่งซื้อทั้งหมด',
  ORDER_EXCEL_EXPORT = 'ส่งออกคำสั่งซื้อ',
  ORDER_LIST_ISSUE = 'ที่พบปัญหา',
  ORDER_LIST_RETURN = 'รอคืนสินค้า',
  ORDER_LIST_WAIT_RETURN = 'คำสั่งซื้อที่ถูกรอคืน',
  ORDER_RETURN_CREATE = 'สร้างใบรอคืนสินค้า',
  ORDER_RETURN_DETAIL = 'รายละเอียดคำสั่งรอคืนสินค้า',
  ORDER_RETURN_PRINT = 'พิมพ์ข้อมูลคำสั่งรอคืนสินค้า',
  ORDER_CREATE = 'สร้างคำสั่งซื้อ',
  ORDER_UPDATE_WHOLE = 'แก้ไขคำสั่งซื้อ',
  ORDER_DETAIL = 'รายละเอียดคำสั่งซื้อ',
  ORDER_GENERATE_PUBLIC_MAP_URL = 'สร้าง URL แผนที่สาธารณะ',
  ORDER_PRINT = 'พิมพ์ข้อมูลคำสั่งซื้อ',
  ORDER_PRINT_SHIPPING_LABEL = 'พิมพ์ใบปะหน้า',
  ORDER_PRINT_ALL_RECEIPT = '',
  ORDER_PRINT_OVERDUE_BILL = 'พิมพ์ใบแจ้งยอดค้างชำระ',
  ORDER_WAIT_FOR_PRODUCTION = 'คำสั่งซื้อรอผลิต',
  ORDER_WAIT_FOR_APPROVAL = 'รอการอนุมัติ',
  ORDER_LIST_PACKING_HISTORY = 'สถานะคำสั่งซื้อ',
  ORDER_CS_LIST = 'คำสั่งซื้อ CS',
  ORDER_CLAIM_LIST = 'คำสั่งซื้อเคลม',
  TICKET_CREATE = 'สร้างคำร้อง',
  TICKET_UPDATE = 'แก้ไขคำร้อง',
  TICKET_LIST = 'ศูนย์คำร้อง',
  PRODUCT_SUMMARY_LOG = 'การอัปเดตจำนวนสินค้า',
  SIGN_OUT = 'ออกจากระบบ',
  RESERVE_PRODUCT_LIST = 'จองสินค้า',
  RESERVE_PRODUCT_SHOW = 'การจองสินค้า',
  RESERVE_PRODUCT_CREATE = 'สร้างการจองสินค้า',
  REPORT = 'รายงาน',
  OVERVIEW_REPORT = 'ภาพรวม',
  INCOME_REPORT = 'ยอดขาย',
  PRODUCT_REPORT = 'สินค้า',
  STAFF_JOB_REPORT_LIST = 'ประวัติงานช่าง',
  STAFF_JOB_REPORT_SHOW = 'รายละเอียดประวัติการทำงาน',
  PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT = 'สรุปใบผลิต',
  STAFF_CONTROL_ROOM = 'งานช่าง',
  WORKSPACE_SETTING = 'การตั้งค่า',
  WORKSPACE_SHOW = 'รายละเอียดบริษัท',
  WORKSPACE_UPDATE = 'แก้ไขรายละเอียดบริษัท',
  MEMBER_LIST = 'ผู้ใช้งาน',
  ROLE_LIST = 'สิทธิ์การใช้งาน',
  ROLE_SHOW = 'สิทธิ์การใช้งาน',
  ROLE_CREATE = 'เพิ่มสิทธิ์การใช้งาน',
  ROLE_UPDATE = 'แก้ไขสิทธิ์การใช้งาน',
  ORDER_SOURCE_LIST = 'ช่องทางขาย',
  WAREHOUSE_LIST = 'คลังสินค้า',
  DELIVERY_CHANNEL_LIST = 'ช่องทางจัดส่ง',
  VEHICLE_LIST = 'รายการยานพาหนะ',
  TRCLOUD_SOURCE = 'ช่องทางการลงบัญชี',
  TRCLOUD_LOG = 'TRCloud Log',
  TRCLOUD_LOG_SHOW = 'รายละเอียด TRCloud Log',
  TRCLOUD_TEMP_ARRAY_INSPECT = 'ตรวจเช็คคำสั่งซื้อที่จะสร้างใบกำกับ',
  BANK_ACCOUNT = 'ช่องทางบัญชีธนาคาร',
  BOOTH_LOCATION_LIST = 'สถานที่ตั้งบูธ',
  SCAN = 'สแกน QR code',
  SCAN_DEPOSIT_PRODUCT = 'รับของเข้า',
  SCAN_CHANGE_STATE_ORDER = 'เปลี่ยนสถานะคำสั่งซื้อ',
  SCAN_ADJUST_PICKING_LIST = 'ปรับใบสั่งซื้อ',
  BOARDED_ORDER_LIST = 'หลักฐานการจัดส่ง',
  BOARDED_ORDER_SHOW = 'หลักฐานการจัดส่ง',
  KERRY_TRACKING_LIST = 'เลขพัสดุ Kerry Express',
  THAIPOST_TRACKING_LIST = 'เลขพัสดุไปรษณีย์ไทย',
  SALE = 'การขาย',
  SALESLEAD_LIST = 'การขายทั้งหมด',
  SALESLEAD_CREATE = 'สร้างรายการขาย',
  SALESLEAD_SHOW = 'รายละเอียดการขาย',
  SALESLEAD_EDIT = 'แก้ไขรายการขาย',

  SALES_COMMISSION_SHOW = 'รายได้พนักงานขาย',
  SALES_COMMISSION_SHOW_WITH_ID = 'พนักงานขาย',
  SALES_COMMISSION_LIST = 'พนักงานขายทั้งหมด',
  SALES_COMMISSION_PAID = 'การจ่ายค่าคอมมิชชันพนักงานขาย',

  SMARTPOST_TRACKING_LIST = 'เลขพัสดุ Smartpost',
  DOWNLOAD_QR_CODE = 'ดาวน์โหลด QR Code',
  DOWNLOAD_QR_CODE_LABEL = 'ใบแจกงาน QRCode',
  PREORDER = 'คำสั่งซื้อส่งห้าง',
  CHANGE_DELIVERY_CHANNEL = 'เปลี่ยนช่องทางจัดส่ง',
  PREORDER_CREATE = 'สร้างคำสั่งซื้อส่งห้าง',

  DELIVERY_FLEET_LIST = 'จัดการขบวนรถส่ง',
  DELIVERY_FLEET_PRINT = 'พิมพ์เอกสารคิวรถส่งพัสดุ',
  DELIVERY_FLEET_PRINT_SOF = 'พิมพ์เอกสาร SOF',

  PURCHASE_REQUISITION = 'การขอซื้อ',
  PURCHASE_REQUISITION_LIST = 'การขอซื้อทั้งหมด (PR)',
  PURCHASE_REQUISITION_CREATE = 'สร้างรายการขอซื้อ',
  PURCHASE_REQUISITION_SHOW = 'รายละเอียดการขอซื้อ',
  PURCHASE_REQUISITION_EDIT = 'แก้ไขรายการขอซื้อ',
  PURCHASE_REQUISITION_APPROVAL = 'อนุมัติการขอซื้อ',
  PURCHASE_REQUISITION_SUMMARY = 'สรุปการขอซื้อ',

  PURCHASE_ORDER = 'การจัดซื้อ',
  PURCHASE_ORDER_LIST = 'การจัดซื้อทั้งหมด (PO)',
  PURCHASE_ORDER_CREATE = 'สร้างรายการจัดซื้อ',
  PURCHASE_ORDER_SHOW = 'รายละเอียดการจัดซื้อ',
  PURCHASE_ORDER_EDIT = 'แก้ไขรายการจัดซื้อ',
  PURCHASE_ORDER_APPROVAL = 'อนุมัติการจัดซื้อ',
  PURCHASE_ORDER_SUMMARY = 'สรุปการจัดซื้อ',

  PURCHASE_ORDER_ACCOUNT = 'การจัดซื้อ ( จ่ายเงิน )',
  PURCHASE_ORDER_ACCOUNT_LIST = 'รายการจัดซื้อ ( จ่ายเงิน )',
  PURCHASE_ORDER_ACCOUNT_SHOW = 'รายละเอียดการจัดซื้อ ( จ่ายเงิน )',

  SUPPLIER_LIST = 'คู่ค้า',
  SUPPLIER_CREATE = 'เพิ่มคู่ค้าใหม่',
  SUPPLIER_SHOW = 'ข้อมูลคู่ค้า',
  SUPPLIER_EDIT = 'แก้ไขข้อมูลคู่ค้า',

  SETTING_OTHERS = 'อื่นๆ',
  PRIVACY_POLICY = 'นโยบายความเป็นส่วนตัว',
  CUSTOMER_LIST = 'ลูกค้า',
  CUSTOMER_SHOW = 'ข้อมูลลูกค้า',
  STAFF_LIST = 'พนักงาน',
  STAFF_GENERATE_QR_CODE = 'สร้างรหัส QR ของพนักงาน',
  STAFF_ROLE_LIST = 'ตำแหน่งพนักงาน',
  STAFF_ROLE_PERMISSIONS_CONFIG = 'สิทธิ์พนักงาน',
  SHOPEE_CONFIG = '[บัญชี]ตั้งค่า shopee',
  SHOPEE_PAYMENT_METHODS = 'อัตราธุรกรรมชำระเงิน',
  SHOPEE_PROMOTIONS = 'อัตราโปรโมชั่น',
  SHOPEE_CATEGORY_FEE = 'ค่าธุรกรรมหมวดหมู่สินค้า',
  LAZADA_CONFIG = '[บัญชี]ตั้งค่า lazada',
  LAZADA_CATEGORY_FEE = 'ค่าธุรกรรมหมวดหมู่สินค้า',
  LAZADA_TRANSACTION_FEE = 'อัตราธุรกรรม',
  ACCOUNTING = 'การบัญชี',
  ORDER_WITH_PAYMENT = 'สั่งซื้อพร้อมชำระเงิน',
  STOCK_PRODUCT = 'เข้าสู่ Stock Product',
  TECHNICAL_DATA_SHEET = 'เอกสารทางเทคนิค',
  TECHNICAL_DATA_SHEET_SHOW = 'รายละเอียด',
  TECHNICAL_DATA_SHEET_EDIT = 'แก้ไขเอกสารทางเทคนิค',
  TECHNICAL_DATA_SHEET_CREATE = 'สร้างเอกสารทางเทคนิค',

  PRODUCT_TRACKING = 'ตามรอยสินค้า',
  PRODUCT_TRACKING_DETAIL = 'รายละเอียดการตามรอยสินค้า',
  ADD_PRODUCT_TRACKING_ACTION = 'เพิ่มการดำเนินการตามรอยสินค้า',
  PRODUCT_TRACKING_LABEL = 'ป้ายตามรอยสินค้า',

  GENERATE_PRODUCT_GENERIC_QR_CODE = 'โหมด generic sku',

  PRODUCTION_PLAN = 'แผนการผลิตสินค้า',
  PRODUCTION_PLAN_SHOW = 'รายละเอียดแผนการผลิตสินค้า',
  PRODUCTION_PLAN_UPDATE = 'แก้ไขแผนการผลิตสินค้า',
  PRODUCTION_PLAN_CREATE = 'สร้างแผนการผลิตสินค้า',
  PRODUCTION_PLAN_LOG_EXPORT = 'Export ประวัติแผนการผลิตสินค้า',

  AUDITING = 'ตรวจสอบ',
  STATEMENT_MATCHING_BANK_TRANSACTION = 'รายการเดินบัญชี',
  STATEMENT_MATCHING_MATCH = 'การรับเงิน',

  COD_MATCHING = 'การรับเงิน COD',

  STOCK_MOVEMENT_FACTORY = 'คลังคงเหลือ - Factory',
  STOCK_MOVEMENT_BOOTH = 'คลังคงเหลือ - Booth',
  STOCK_MOVEMENT_DETAIL = 'detail',

  PRODUCTION_LINE = 'แผนผลิตตามสั่ง',
  PRODUCTION_LINE_CALENDAR = 'ภาพรวม',

  SALARY_REPORT = 'เงินเดือนพนักงานผลิต',
  SALARY_REPORT_PRINT = 'รายงานเงินเดือนพนักงานผลิต',
  SALARY_REPORT_SHOW = 'ข้อมูลพนักงาน',

  ORDER_FLEET_DASHBOARD = 'ภาพรวมคำสั่งซื้อ',

  BOOTH_EVENT_LIST = 'งานบูธทั้งหมด',
  BOOTH_EVENT_CREATE = 'สร้างงานบูธ',
  BOOTH_EVENT_SHOW = 'รายละเอียดงานบูธ',
  BOOTH_EVENT_UPDATE = 'แก้ไขงานบูธ',

  UNIVERSAL_TF = 'โอน/รับสินค้าเข้าคลัง',

  FAKE_SHIPPED = 'จัดส่งหลอก'
}

export enum PathMenu {
  DELIVERY_CHANEL_LIST = '',
  NOTIFICATION_SHOW = '/notifications',
  PICKING = '/purchasing-list',
  WORKSPACE_DASHBOARD = '/',
  PICKING_ORDER = '/picking-order',

  PICKING_LISTS = '/picking-list',
  PICKING_LIST_DETAIL = '/picking-list/[_id]/print',
  PICKING_LIST_PRINT_ALL = '/picking-list/[_id]/print-all/[docType]/[size]',
  PICKING_LIST_ADJUSTMENT = '/picking-list/[_id]/adjustment',

  SCAN_PICKING_LIST = '/scan-picking-list',

  PACKING_ORDERS_PREPICK = '/packing-order-prepick',
  PACKING_ORDERS_PREPARED = '/packing-order-prepared',
  PACKING_ORDERS = '/packing-order',
  PACKING_NP_ORDERS = '/order/np-order/packing',
  SCAN_PRINTING = '/scan-printing',
  SCAN_PRINTING_ONE_N = '/scan-printing-one-n',
  SCAN_PACKED = '/scan-packed',
  PACKING_ORDER_HISTORY = '/packing-order-history',
  CHANGE_ORDER_STATE = '/change-order-state',

  PRODUCT = '/product',
  PRODUCT_RECOMMENDATION = '/product-recommendation',
  PRODUCT_LIST = '/product',
  PRODUCT_DETAIL = '/product/[_id]/show',
  PRODUCT_CREATE = '/product/create',
  PRODUCT_EDIT = '/product/[_id]',
  PRODUCT_SHOW = '/product/[_id]/show',
  PRODUCT_SHOW_SYNC = '/product/[_id]/show/sync',
  PRODUCT_FAKE_REFILL_STOCK = '/product/fake-refill-stock',
  PRODUCT_TIMELINE = '/product/timeline',

  PRODUCT_CATEGORY = '/product-category',
  PRODUCT_CATEGORY_EDIT = '/product-category/[_id]',
  PRODUCT_CATEGORY_SHOW = '/product-category/[_id]/show',

  WAREHOUSE_LIST = '/product/warehouse',
  GENERATE_WAREHOUSE_QR = '/product/warehouse/[_id]/generate-qr-code',

  BOOTH_STOCK_LIST = '/booth-stock',

  STOCK = '/stock',
  PRODUCT_TRACKING_CREATE = '/stock/product-tracking/create',
  PRODUCT_TRACKING_LIST = '/stock/product-tracking',
  PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY = '/stock/product-tracking/scan-change-activity',
  PRODUCT_TRACKING_SCAN_UPDATE_ACTIVITY = '/stock/product-tracking/scan-update-activity',
  PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY = '/stock/product-tracking/scan-wait-for-qc-activity',
  INSPECTION_STOCK_LIST = '/stock/inspection',
  INSPECTION_STOCK_SHOW = '/stock/inspection/[_id]/show',
  INSPECTION_STOCK_CREATE = '/stock/inspection/create',
  REDUCE_STOCK_LIST = '/stock/withdraw',
  REDUCE_STOCK_SHOW = '/stock/withdraw/[_id]/show',
  REDUCE_STOCK_CREATE = '/stock/withdraw/create',
  ADD_STOCK_LIST = '/stock/deposit',
  ADD_STOCK_SHOW = '/stock/deposit/[_id]/show',
  ADD_STOCK_CREATE = '/stock/deposit/create',

  AUDIT_CYCLE_CHECK_LIST = '/stock/audit-cycle-check',
  AUDIT_CYCLE_CHECK_CREATE = '/stock/audit-cycle-check/create',
  AUDIT_CYCLE_CHECK_DETAIL = '/stock/audit-cycle-check/[_id]',

  STOCK_OTHERS = '/stock/others',
  PRODUCTION_ORDER_LIST = '/stock/production-order',
  PRODUCTION_ORDER_SHOW = '/stock/production-order/[_id]/show',
  PRODUCTION_ORDER_CREATE = '/stock/production-order/create',
  PRODUCTION_ORDER_UPDATE = '/stock/production-order/[_id]',

  FINISHED_GOODS_DEPOSIT_CREATE = '/stock/finished-goods/deposit/create',
  FINISHED_GOODS_DEPOSIT_LIST = '/stock/finished-goods/deposit',
  FINISHED_GOODS_DEPOSIT_DETAIL = '/stock/finished-goods/deposit/[_id]/show',
  FINISHED_GOODS_DEPOSIT_PRINT = '/stock/finished-goods/deposit/[_id]/print',

  RAW_MATERIAL_WITHDRAW_CREATE = '/stock/raw-material/withdraw/create',
  RAW_MATERIAL_WITHDRAW_LIST = '/stock/raw-material/withdraw',
  RAW_MATERIAL_WITHDRAW_DETAIL = '/stock/raw-material/withdraw/[_id]/show',
  RAW_MATERIAL_WITHDRAW_PRINT = '/stock/raw-material/withdraw/[_id]/print',

  RAW_MATERIAL_DEPOSIT_CREATE = '/stock/raw-material/deposit/create',
  RAW_MATERIAL_DEPOSIT_LIST = '/stock/raw-material/deposit',
  RAW_MATERIAL_DEPOSIT_DETAIL = '/stock/raw-material/deposit/[_id]/show',
  RAW_MATERIAL_DEPOSIT_PRINT = '/stock/raw-material/deposit/[_id]/print',

  TRANSFER_IN_INVENTORY_ORDER_LIST = '/stock/transfer-in-inventory-order',
  TRANSFER_IN_INVENTORY_ORDER_SHOW = '/stock/transfer-in-inventory-order/[_id]/show',

  TRANSFER_OUT_INVENTORY_ORDER_LIST = '/stock/transfer-out-inventory-order',
  TRANSFER_OUT_INVENTORY_ORDER_CREATE = '/stock/transfer-out-inventory-order/create',
  TRANSFER_OUT_INVENTORY_ORDER_UPDATE = '/stock/transfer-out-inventory-order/[_id]/update',
  TRANSFER_OUT_INVENTORY_ORDER_SHOW = '/stock/transfer-out-inventory-order/[_id]/show',

  TRANSFER_INTERNAL_ORDER_LIST = '/stock/transfer-internal-order',
  TRANSFER_INTERNAL_ORDER_CREATE = '/stock/transfer-internal-order/create',
  TRANSFER_INTERNAL_ORDER_UPDATE = '/stock/transfer-internal-order/[_id]/update',
  TRANSFER_INTERNAL_ORDER_SHOW = '/stock/transfer-internal-order/[_id]/show',

  QR_CODE_TRACKING = '/stock/qrcode-tracking',

  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST = '/stock/production-order-raw-material-withdraw',
  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_SHOW = '/stock/production-order-raw-material-withdraw/[_id]/show',
  PRODUCTION_ORDER_RAW_MATERIAL_RETURN_LIST = '/stock/production-order-raw-material-return',
  PRODUCTION_ORDER_RAW_MATERIAL_RETURN_SHOW = '/stock/production-order-raw-material-return/[_id]/show',

  TRANSFER_ORDER_LIST = '/stock/others/transfer-order',
  TRANSFER_ORDER_SHOW = '/stock/others/transfer-order/[_id]/show',
  TRANSFER_ORDER_CREATE = '/stock/others/transfer-order/create',
  PRODUCT_ISSUE_REPORT_LIST = '/stock/others/product-issue-report',
  PRODUCT_ISSUE_REPORT_SHOW = '/stock/others/product-issue-report/[_id]/show',
  PRODUCT_ISSUE_REPORT_CREATE = '/stock/others/product-issue-report/create',

  GENERAL_VEHICLE_USE_LIST = '/general-vehicle-use',
  GENERAL_VEHICLE_USE_SHOW = '/general-vehicle-use/[id]/show',
  GENERAL_VEHICLE_USE_CREATE = '/general-vehicle-use/create',
  GENERAL_VEHICLE_USE_PRINT = '/general-vehicle-use/[id]/print',
  GENERAL_VEHICLE_USE_UPDATE = '/general-vehicle-use/[id]',

  SHOPEE_CATEGORY_FEE = '/shopee-category-fee',
  ORDER = '/order',
  ORDER_INSPECT = '/order-inspect',
  ORDER_LIST = '/order',
  ORDER_CS_LIST = '/order/cs',
  ORDER_LIST_ISSUE = '/order/issue',
  ORDER_LIST_RETURN = '/order-return',
  ORDER_LIST_WAIT_RETURN = '/order-wait-return',
  ORDER_RETURN_CREATE = '/order-return/create',
  ORDER_RETURN_DETAIL = '/order-return/[_id]/show',
  ORDER_RETURN_PRINT_EVIDENCE = '/order-return/[_id]/print/evidence',
  ORDER_CREATE = '/order/create',
  ORDER_DETAIL = '/order/[_id]/show',
  ORDER_GENERATE_PUBLIC_MAP_URL = '/order/[_id]/generate-public-map-url',
  ORDER_SELECT_LOCATION = '/order-select-location',
  ORDER_UPDATE_WHOLE = '/order/[_id]',
  ORDER_PRINT = '/order/[_id]/print/[docType]/[size]',
  ORDER_PRINT_PDF = '/order/[_id]/print-pdf/[docType]/[size]',
  ORDER_PRINT_ALL = '/order/print-all/[docType]/[size]',
  ORDER_WAIT_FOR_PRODUCTION = '/order/wait-for-production',
  ORDER_WAIT_FOR_APPROVAL = '/order-approval',
  ORDER_EXCEL_EXPORT = '/order/export-excel',
  ORDER_LIST_PACKING_HISTORY = '/packing-history',
  ORDER_CLAIM_LIST = '/order/order-claim',
  TICKET_CREATE = '/ticket/create',
  TICKET_UPDATE = '/ticket/[_id]',
  TICKET_LIST = '/ticket',
  PRODUCT_SUMMARY_LOG = '/product/summary-log',
  RESERVE_PRODUCT_LIST = '/reserve-product',
  RESERVE_PRODUCT_SHOW = '/reserve-product/[_id]/show',
  RESERVE_PRODUCT_CREATE = '/reserve-product/create',
  REPORT = '/report',
  OVERVIEW_REPORT = '/report/overview',
  INCOME_REPORT = '/report/inclome',
  PRODUCT_REPORT = '/report/product',
  STAFF_JOB_REPORT_LIST = '/report/staff-job',
  STAFF_JOB_REPORT_SHOW = '/report/staff-job/[_id]/show',
  PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT = '/report/production-order-by-job-details',
  STAFF_CONTROL_ROOM = '/report/staff-control-room',
  WORKSPACE_SETTING = '/workspace',
  WORKSPACE_SHOW = '/workspace/show',
  WORKSPACE_UPDATE = '/workspace/update',
  MEMBER_LIST = '/workspace/member',
  ROLE_LIST = '/workspace/role',
  ROLE_SHOW = '/workspace/role/[_id]/show',
  ROLE_CREATE = '/workspace/role/create',
  ROLE_UPDATE = '/workspace/role/[_id]',
  ORDER_SOURCE_LIST = '/workspace/order-source',
  LOGIN = '/authen/login',
  REGISTER = '/authen/register',
  FORGET_PASSWORD = '/authen/forget-password',
  CHANGE_PASSWORD = '/authen/change-password',
  LOGIN_WORKSPACE = '/workspace/login',
  CREATE_WORKSPACE = '/workspace/create',
  DELIVERY_CHANNEL_LIST = '/workspace/delivery-channel',
  VEHICLE_LIST = '/workspace/vehicle',
  TRCLOUD_SOURCE = '/workspace/trcloud-source',
  TRCLOUD_LOG = '/workspace/trcloud-log',
  TRCLOUD_LOG_SHOW = '/workspace/trcloud-log/[_id]/show',
  TRCLOUD_TEMP_ARRAY_INSPECT = '/trcloud-temp-array-inspect',
  BANK_ACCOUNT = '/workspace/bank-accounts',
  BOOTH_LOCATION_LIST = '/workspace/booth-location',
  SCAN = '/scan',
  SCAN_DEPOSIT_PRODUCT = '/scan/deposit-product',
  SCAN_CHANGE_STATE_ORDER = '/scan/change-state-order',
  SCAN_ADJUST_PICKING_LIST = '/scan/adjust-picking-list',
  BOARDED_EVIDENCE_LIST = '/boarded-evidence',
  BOARDED_EVIDENCE_SHOW = '/boarded-evidence/[_id]/show',
  KERRY_TRACKING_LIST = '/order/kerry-tracking',
  THAIPOST_TRACKING_LIST = '/order/thaipost-tracking',
  SALESLEAD = '/saleslead',
  SALESLEAD_LIST = '/saleslead',
  SALESLEAD_CREATE = '/saleslead/create',
  SALESLEAD_SHOW = '/saleslead/[_id]/show',
  SALESLEAD_EDIT = '/saleslead/[_id]',

  SALES_COMMISSION_SHOW = '/sales-commission/show',
  SALES_COMMISSION_SHOW_WITH_ID = '/sales-commission/[_id]/show',
  SALES_COMMISSION_LIST = '/sales-commission/list',
  SALES_COMMISSION_PAID = '/sales-commission-paid',

  SMARTPOST_TRACKING_LIST = '/order/smartpost-tracking',
  DOWNLOAD_QR_CODE = '/stock/production-order/[_id]/download',
  DOWNLOAD_QR_CODE_LABEL = '/stock/production-order/[_id]/download/label',
  PREORDER = '/order/preorder',
  ORDER_CHOOSE_LABEL = '/order/choose-label',
  CHANGE_DELIVERY_CHANNEL = '/order/change-delivery-channel',
  PREORDER_CREATE = '/order/create-preorder',

  DELIVERY_FLEET_LIST = '/delivery-fleet',
  DELIVERY_FLEET_PRINT = '/delivery-fleet/[_id]/print',
  DELIVERY_FLEET_RETRIEVAL_QR_LIST = '/delivery-fleet/[_id]/retrieval-qr-list',
  DELIVERY_FLEET_RETRIEVAL_LABEL = '/delivery-fleet/[_id]/retrieval-label',
  // DELIVERY_FLEET_RETRIEVAL_PRINT = '/delivery-fleet/[_id]/print',
  DELIVERY_FLEET_PRINT_SOF = '/delivery-fleet/[_id]/sof-note',
  DELIVERY_FLEET_MOBILE = '/delivery-fleet/m',

  FLEET_LIST_MOBILE = '/delivery-fleet/m',
  FLEET_ORDER_DETAIL_MOBILE = '/delivery-fleet/m/[fleetId]/[orderId]',

  PURCHASE_REQUISITION = '/purchase-requisition',
  PURCHASE_REQUISITION_LIST = '/purchase-requisition',
  PURCHASE_REQUISITION_CREATE = '/purchase-requisition/create',
  PURCHASE_REQUISITION_SHOW = '/purchase-requisition/[_id]/show',
  PURCHASE_REQUISITION_EDIT = '/purchase-requisition/[_id]',
  PURCHASE_REQUISITION_APPROVAL = '/purchase-requisition/approval',
  PURCHASE_REQUISITION_SUMMARY = '/purchase-requisition/summary',
  PURCHASE_REQUISITION_PRINT = '/purchase-requisition/[_id]/print',

  PURCHASE_ORDER = '/purchase-order',
  PURCHASE_ORDER_LIST = '/purchase-order',
  PURCHASE_ORDER_CREATE = '/purchase-order/create',
  PURCHASE_ORDER_SHOW = '/purchase-order/[_id]/show',
  PURCHASE_ORDER_DOWNLOAD = '/purchase-order/[_id]/download',
  PURCHASE_ORDER_EDIT = '/purchase-order/[_id]',
  PURCHASE_ORDER_APPROVAL = '/purchase-order/approval',
  PURCHASE_ORDER_SUMMARY = '/purchase-order/summary',

  PURCHASE_ORDER_ACCOUNT = '/purchase-order-account',
  PURCHASE_ORDER_ACCOUNT_LIST = '/purchase-order-account',
  PURCHASE_ORDER_ACCOUNT_SHOW = '/purchase-order-account/[_id]/show',

  SUPPLIER_LIST = '/supplier',
  SUPPLIER_CREATE = '/supplier/create',
  SUPPLIER_SHOW = '/supplier/[_id]/show',
  SUPPLIER_EDIT = '/supplier/[_id]',

  SETTING_OTHERS = '/workspace/others',
  PRIVACY_POLICY = '/workspace/others/privacy-policy',
  CUSTOMER_LIST = '/customer',
  CUSTOMER_SHOW = '/customer/[_id]/show',
  STAFF_LIST = '/staff',
  STAFF_GENERATE_QR_CODE = '/staff/[_id]/generate-qr-code',
  STAFF_ROLE_LIST = '/staff-role',
  STAFF_ROLE_PERMISSIONS_CONFIG = '/staff-role/permission-config',
  SHOPEE_CONFIG = '/shopee-config',
  SHOPEE_PROMOTIONS = '/shopee-config/promotions',
  SHOPEE_PAYMENT_METHODS = '/shopee-config/payment-methods',
  LAZADA_CONFIG = '/lazada-config',
  LAZADA_CATEGORY_FEE = '/lazada-config/category-fee',
  LAZADA_TRANSACTION_FEE = '/lazada-config/transaction-fee',
  ACCOUNTING = '/accounting',
  ORDER_WITH_PAYMENT = '/accounting/order-payment',
  STOCK_PRODUCT = '/stock-product/product',
  TECHNICAL_DATA_SHEET = '/technical-data-sheet',
  TECHNICAL_DATA_SHEET_SHOW = '/technical-data-sheet/[_id]/show',
  TECHNICAL_DATA_SHEET_EDIT = '/technical-data-sheet/[_id]/edit',
  TECHNICAL_DATA_SHEET_CREATE = '/technical-data-sheet/create',

  PRODUCT_TRACKING = '/product-tracking',
  PRODUCT_TRACKING_DETAIL = '/product-tracking/[id]',
  ADD_PRODUCT_TRACKING_ACTION = '/product-tracking/[id]/create',
  PRODUCT_TRACKING_LABEL = '/product-tracking/label',

  GENERATE_PRODUCT_GENERIC_QR_CODE = '/generate-product-generic-qr-code',

  NP_ORDER = '/order/np-order',
  NP_ORDER_CREATE = '/order/np-order/create',
  NP_ORDER_DETAIL = '/order/np-order/[_id]/detail',
  NP_ORDER_QR_DOWNLOAD = '/order/np-order/[_id]/download',
  NP_ORDER_PACKING = '/order/np-order/packing',

  PRODUCTION_PLAN = '/production-plan',
  PRODUCTION_PLAN_SHOW = '/production-plan/[_id]/show',
  PRODUCTION_PLAN_UPDATE = '/production-plan/[_id]/update',
  PRODUCTION_PLAN_CREATE = '/production-plan/create',

  AUDITING = '/',
  STATEMENT_MATCHING_BANK_TRANSACTION = '/audit/statement-matching/bank-transaction',
  STATEMENT_MATCHING_MATCH = '/audit/statement-matching/match',

  COD_MATCHING = '/audit/cod-matching',

  STOCK_MOVEMENT_FACTORY = '/stock-movement/factory',
  STOCK_MOVEMENT_BOOTH = '/stock-movement/booth',
  STOCK_MOVEMENT_DETAIL = '/stock-movement/[_id]/[usage]',

  PRODUCTION_LINE = '/production-line',
  PRODUCTION_LINE_CALENDAR = '/production-line/calendar',

  SALARY_REPORT = '/report/wages-report',
  SALARY_REPORT_PRINT = '/report/wages-report/print',
  SALARY_REPORT_SHOW = '/report/wages-report/[_id]/show',

  ORDER_FLEET_DASHBOARD = '/report/order-fleet-dashboard',

  ORDER_STATUS_UPDATE_V1 = '/order-status-update/v1/[_id]',
  ORDER_STATUS_UPDATE_V2 = '/order-status-update/v2/[_id]',
  ORDER_QR_CODE_PAYMENT_PREVIEW = '/order-qr-code/[param]',

  BOOTH_EVENT_LIST = '/report/booth-event',
  BOOTH_EVENT_CREATE = '/report/booth-event/create',
  BOOTH_EVENT_SHOW = '/report/booth-event/[_id]/show',
  BOOTH_EVENT_UPDATE = '/report/booth-event/[_id]',

  UNIVERSAL_TF = '/stock/transfer-create-receive',

  FAKE_SHIPPED = '/fake-shipped'
}

export const BreadcrumbData = {
  [PathMenu.NOTIFICATION_SHOW]: [{ label: NameMenu.NOTIFICATION_SHOW, href: PathMenu.NOTIFICATION_SHOW }],
  [PathMenu.PICKING]: [{ label: NameMenu.PICKING, href: PathMenu.PICKING }],
  [PathMenu.WORKSPACE_DASHBOARD]: [{ label: NameMenu.WORKSPACE_DASHBOARD, href: PathMenu.WORKSPACE_DASHBOARD }],
  [PathMenu.PICKING_ORDER]: [{ label: NameMenu.PICKING_ORDER, href: PathMenu.PICKING_ORDER }],

  [PathMenu.PREORDER]: [{ label: NameMenu.PREORDER, href: PathMenu.PREORDER }],
  [PathMenu.PREORDER_CREATE]: [
    { label: NameMenu.PREORDER, href: PathMenu.PREORDER },
    { label: NameMenu.PREORDER_CREATE, href: PathMenu.PREORDER_CREATE }
  ],
  [PathMenu.ORDER_CHOOSE_LABEL]: [{ label: NameMenu.ORDER_CHOOSE_LABEL, href: PathMenu.ORDER_CHOOSE_LABEL }],
  // [PathMenu.PICKING_PREORDER]: [{ label: NameMenu.PICKING_PREORDER, href: PathMenu.PICKING_PREORDER }],

  [PathMenu.PICKING_LISTS]: [{ label: NameMenu.PICKING_LISTS, href: PathMenu.PICKING_LISTS }],
  [PathMenu.PICKING_LIST_DETAIL]: [
    { label: NameMenu.PICKING_LISTS, href: PathMenu.PICKING_LISTS },
    { label: NameMenu.PICKING_LIST_DETAIL, href: PathMenu.PICKING_LIST_DETAIL }
  ],
  [PathMenu.PICKING_LIST_ADJUSTMENT]: [
    { label: NameMenu.PICKING_LISTS, href: PathMenu.PICKING_LISTS },
    { label: NameMenu.PICKING_LIST_ADJUSTMENT, href: PathMenu.PICKING_LIST_ADJUSTMENT }
  ],
  [PathMenu.PICKING_LIST_PRINT_ALL]: [
    { label: NameMenu.PICKING_LISTS, href: PathMenu.PICKING_LIST_PRINT_ALL },
    { label: NameMenu.PICKING_LIST_DETAIL, href: PathMenu.PICKING_LIST_DETAIL },
    { label: NameMenu.ORDER_PRINT, href: '' }
  ],
  [PathMenu.SCAN_PICKING_LIST]: [{ label: NameMenu.SCAN_PICKING_LIST, href: PathMenu.SCAN_PICKING_LIST }],

  [PathMenu.PACKING_ORDERS_PREPICK]: [
    { label: NameMenu.PACKING_ORDERS_PREPICK, href: PathMenu.PACKING_ORDERS_PREPICK }
  ],
  [PathMenu.PACKING_ORDERS_PREPARED]: [
    { label: NameMenu.PACKING_ORDERS_PREPARED, href: PathMenu.PACKING_ORDERS_PREPARED }
  ],
  [PathMenu.PACKING_NP_ORDERS]: [{ label: NameMenu.PACKING_NP_ORDERS, href: PathMenu.PACKING_NP_ORDERS }],
  [PathMenu.PACKING_ORDERS]: [{ label: NameMenu.PACKING_ORDERS, href: PathMenu.PACKING_ORDERS }],
  [PathMenu.SCAN_PRINTING]: [
    { label: NameMenu.PACKING_ORDERS, href: PathMenu.PACKING_ORDERS },
    { label: NameMenu.SCAN_PRINTING, href: PathMenu.SCAN_PRINTING }
  ],
  [PathMenu.SCAN_PRINTING_ONE_N]: [
    { label: NameMenu.PACKING_ORDERS, href: PathMenu.PACKING_ORDERS },
    { label: NameMenu.SCAN_PRINTING_ONE_N, href: PathMenu.SCAN_PRINTING_ONE_N }
  ],

  [PathMenu.SCAN_PACKED]: [
    { label: NameMenu.PACKING_ORDERS, href: PathMenu.PACKING_ORDERS },
    { label: NameMenu.SCAN_PACKED, href: PathMenu.SCAN_PACKED }
  ],
  [PathMenu.PACKING_ORDER_HISTORY]: [{ label: NameMenu.PACKING_ORDER_HISTORY, href: PathMenu.PACKING_ORDER_HISTORY }],
  [PathMenu.CHANGE_ORDER_STATE]: [{ label: NameMenu.CHANGE_ORDER_STATE, href: PathMenu.CHANGE_ORDER_STATE }],
  [PathMenu.ORDER_LIST_PACKING_HISTORY]: [
    { label: NameMenu.ORDER_LIST_PACKING_HISTORY, href: PathMenu.ORDER_LIST_PACKING_HISTORY }
  ],
  [PathMenu.PRODUCT_RECOMMENDATION]: [
    { label: NameMenu.PRODUCT_RECOMMENDATION, href: PathMenu.PRODUCT_RECOMMENDATION }
  ],
  [PathMenu.PRODUCT]: [{ label: NameMenu.PRODUCT, href: PathMenu.PRODUCT }],
  [PathMenu.PRODUCT_CREATE]: [
    { label: NameMenu.PRODUCT_LIST, href: PathMenu.PRODUCT_LIST },
    { label: NameMenu.PRODUCT_CREATE, href: PathMenu.PRODUCT_CREATE }
  ],
  [PathMenu.PRODUCT_EDIT]: [
    { label: NameMenu.PRODUCT_LIST, href: PathMenu.PRODUCT_LIST },
    { label: NameMenu.PRODUCT_EDIT, href: PathMenu.PRODUCT_EDIT }
  ],
  [PathMenu.PRODUCT_SHOW]: [
    { label: NameMenu.PRODUCT_LIST, href: PathMenu.PRODUCT_LIST },
    { label: NameMenu.PRODUCT_SHOW, href: PathMenu.PRODUCT_SHOW }
  ],
  [PathMenu.PRODUCT_SUMMARY_LOG]: [
    { label: NameMenu.PRODUCT_LIST, href: PathMenu.PRODUCT_LIST },
    { label: NameMenu.PRODUCT_SUMMARY_LOG, href: PathMenu.PRODUCT_SUMMARY_LOG }
  ],
  [PathMenu.PRODUCT_FAKE_REFILL_STOCK]: [
    { label: NameMenu.PRODUCT_FAKE_REFILL_STOCK, href: PathMenu.PRODUCT_FAKE_REFILL_STOCK }
  ],
  [PathMenu.PRODUCT_TIMELINE]: [
    { label: NameMenu.PRODUCT_LIST, href: PathMenu.PRODUCT_LIST },
    { label: NameMenu.PRODUCT_TIMELINE, href: PathMenu.PRODUCT_TIMELINE }
  ],
  [PathMenu.PRODUCT_CATEGORY]: [{ label: NameMenu.PRODUCT_CATEGORY, href: PathMenu.PRODUCT_CATEGORY }],
  [PathMenu.PRODUCT_CATEGORY_EDIT]: [
    { label: NameMenu.PRODUCT_CATEGORY, href: PathMenu.PRODUCT_CATEGORY },
    { label: NameMenu.PRODUCT_CATEGORY_EDIT, href: PathMenu.PRODUCT_CATEGORY_EDIT }
  ],
  [PathMenu.PRODUCT_CATEGORY_SHOW]: [
    { label: NameMenu.PRODUCT_CATEGORY, href: PathMenu.PRODUCT_CATEGORY },
    { label: NameMenu.PRODUCT_CATEGORY_SHOW, href: PathMenu.PRODUCT_CATEGORY_SHOW }
  ],

  [PathMenu.GENERAL_VEHICLE_USE_LIST]: [
    { label: NameMenu.GENERAL_VEHICLE_USE, href: PathMenu.GENERAL_VEHICLE_USE_LIST }
  ],
  [PathMenu.GENERAL_VEHICLE_USE_SHOW]: [
    { label: NameMenu.GENERAL_VEHICLE_USE, href: PathMenu.GENERAL_VEHICLE_USE_LIST },
    { label: NameMenu.GENERAL_VEHICLE_USE_SHOW, href: PathMenu.GENERAL_VEHICLE_USE_SHOW }
  ],
  [PathMenu.GENERAL_VEHICLE_USE_CREATE]: [
    { label: NameMenu.GENERAL_VEHICLE_USE, href: PathMenu.GENERAL_VEHICLE_USE_LIST },
    { label: NameMenu.GENERAL_VEHICLE_USE_CREATE, href: PathMenu.GENERAL_VEHICLE_USE_CREATE }
  ],
  [PathMenu.GENERAL_VEHICLE_USE_UPDATE]: [
    { label: NameMenu.GENERAL_VEHICLE_USE, href: PathMenu.GENERAL_VEHICLE_USE_LIST },
    { label: NameMenu.GENERAL_VEHICLE_USE_UPDATE, href: PathMenu.GENERAL_VEHICLE_USE_UPDATE }
  ],
  [PathMenu.GENERAL_VEHICLE_USE_PRINT]: [
    { label: NameMenu.GENERAL_VEHICLE_USE_PRINT, href: PathMenu.GENERAL_VEHICLE_USE_PRINT },
    { label: NameMenu.GENERAL_VEHICLE_USE_PRINT, href: PathMenu.GENERAL_VEHICLE_USE_PRINT }
  ],

  [PathMenu.BOOTH_STOCK_LIST]: [{ label: NameMenu.BOOTH_STOCK_LIST, href: PathMenu.BOOTH_STOCK_LIST }],

  [PathMenu.WAREHOUSE_LIST]: [{ label: NameMenu.WAREHOUSE_LIST, href: PathMenu.WAREHOUSE_LIST }],
  [PathMenu.STOCK]: [{ label: NameMenu.STOCK, href: PathMenu.STOCK }],
  [PathMenu.INSPECTION_STOCK_LIST]: [{ label: NameMenu.INSPECTION_STOCK_LIST, href: PathMenu.INSPECTION_STOCK_LIST }],
  [PathMenu.INSPECTION_STOCK_SHOW]: [
    { label: NameMenu.INSPECTION_STOCK_LIST, href: PathMenu.INSPECTION_STOCK_LIST },
    { label: NameMenu.INSPECTION_STOCK_SHOW, href: PathMenu.INSPECTION_STOCK_SHOW }
  ],
  [PathMenu.INSPECTION_STOCK_CREATE]: [
    { label: NameMenu.INSPECTION_STOCK_LIST, href: PathMenu.INSPECTION_STOCK_LIST },
    { label: NameMenu.INSPECTION_STOCK_CREATE, href: PathMenu.INSPECTION_STOCK_CREATE }
  ],
  [PathMenu.REDUCE_STOCK_LIST]: [{ label: NameMenu.REDUCE_STOCK_LIST, href: PathMenu.REDUCE_STOCK_LIST }],
  [PathMenu.REDUCE_STOCK_SHOW]: [
    { label: NameMenu.REDUCE_STOCK_LIST, href: PathMenu.REDUCE_STOCK_LIST },
    { label: NameMenu.REDUCE_STOCK_SHOW, href: PathMenu.REDUCE_STOCK_SHOW }
  ],
  [PathMenu.REDUCE_STOCK_CREATE]: [
    { label: NameMenu.REDUCE_STOCK_LIST, href: PathMenu.REDUCE_STOCK_LIST },
    { label: NameMenu.REDUCE_STOCK_CREATE, href: PathMenu.REDUCE_STOCK_CREATE }
  ],
  [PathMenu.ADD_STOCK_LIST]: [{ label: NameMenu.ADD_STOCK_LIST, href: PathMenu.ADD_STOCK_LIST }],
  [PathMenu.ADD_STOCK_SHOW]: [
    { label: NameMenu.ADD_STOCK_LIST, href: PathMenu.ADD_STOCK_LIST },
    { label: NameMenu.ADD_STOCK_SHOW, href: PathMenu.ADD_STOCK_SHOW }
  ],
  [PathMenu.ADD_STOCK_CREATE]: [
    { label: NameMenu.ADD_STOCK_LIST, href: PathMenu.ADD_STOCK_LIST },
    { label: NameMenu.ADD_STOCK_CREATE, href: PathMenu.ADD_STOCK_CREATE }
  ],

  [PathMenu.AUDIT_CYCLE_CHECK_LIST]: [
    { label: NameMenu.AUDIT_CYCLE_CHECK_LIST, href: PathMenu.AUDIT_CYCLE_CHECK_LIST }
  ],
  [PathMenu.AUDIT_CYCLE_CHECK_CREATE]: [
    { label: NameMenu.AUDIT_CYCLE_CHECK_LIST, href: PathMenu.AUDIT_CYCLE_CHECK_LIST },
    { label: NameMenu.AUDIT_CYCLE_CHECK_CREATE, href: PathMenu.AUDIT_CYCLE_CHECK_CREATE }
  ],
  [PathMenu.AUDIT_CYCLE_CHECK_DETAIL]: [
    { label: NameMenu.AUDIT_CYCLE_CHECK_LIST, href: PathMenu.AUDIT_CYCLE_CHECK_LIST },
    { label: NameMenu.AUDIT_CYCLE_CHECK_DETAIL, href: PathMenu.AUDIT_CYCLE_CHECK_DETAIL }
  ],

  [PathMenu.STOCK_OTHERS]: [{ label: NameMenu.STOCK_OTHERS, href: PathMenu.STOCK_OTHERS }],
  [PathMenu.PRODUCT_TRACKING_CREATE]: [
    { label: NameMenu.PRODUCT_TRACKING_CREATE, href: PathMenu.PRODUCT_TRACKING_CREATE }
  ],
  [PathMenu.PRODUCT_TRACKING_LIST]: [{ label: NameMenu.PRODUCT_TRACKING_LIST, href: PathMenu.PRODUCT_TRACKING_LIST }],
  [PathMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY]: [
    { label: NameMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY, href: PathMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY }
  ],
  [PathMenu.PRODUCT_TRACKING_SCAN_UPDATE_ACTIVITY]: [
    { label: NameMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY, href: PathMenu.PRODUCT_TRACKING_SCAN_CHANGE_ACTIVITY }
  ],
  [PathMenu.PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY]: [
    {
      label: NameMenu.PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY,
      href: PathMenu.PRODUCT_TRACKING_SCAN_WAIT_FOR_QC_ACTIVITY
    }
  ],
  [PathMenu.PRODUCTION_ORDER_LIST]: [{ label: NameMenu.PRODUCTION_ORDER_LIST, href: PathMenu.PRODUCTION_ORDER_LIST }],
  [PathMenu.PRODUCTION_ORDER_SHOW]: [
    { label: NameMenu.PRODUCTION_ORDER_LIST, href: PathMenu.PRODUCTION_ORDER_LIST },
    { label: NameMenu.PRODUCTION_ORDER_SHOW, href: PathMenu.PRODUCTION_ORDER_SHOW }
  ],
  [PathMenu.TRANSFER_IN_INVENTORY_ORDER_LIST]: [
    { label: NameMenu.TRANSFER_IN_INVENTORY_ORDER_LIST, href: PathMenu.TRANSFER_IN_INVENTORY_ORDER_LIST }
  ],
  [PathMenu.TRANSFER_IN_INVENTORY_ORDER_SHOW]: [
    { label: NameMenu.TRANSFER_IN_INVENTORY_ORDER_LIST, href: PathMenu.TRANSFER_IN_INVENTORY_ORDER_LIST },
    { label: NameMenu.TRANSFER_IN_INVENTORY_ORDER_SHOW, href: PathMenu.TRANSFER_IN_INVENTORY_ORDER_SHOW }
  ],
  [PathMenu.DOWNLOAD_QR_CODE]: [
    { label: NameMenu.PRODUCTION_ORDER_LIST, href: PathMenu.PRODUCTION_ORDER_LIST },
    { label: NameMenu.PRODUCTION_ORDER_SHOW, href: PathMenu.PRODUCTION_ORDER_SHOW },
    { label: NameMenu.DOWNLOAD_QR_CODE, href: PathMenu.DOWNLOAD_QR_CODE }
  ],
  [PathMenu.DOWNLOAD_QR_CODE_LABEL]: [
    { label: NameMenu.PRODUCTION_ORDER_LIST, href: PathMenu.PRODUCTION_ORDER_LIST },
    { label: NameMenu.PRODUCTION_ORDER_SHOW, href: PathMenu.PRODUCTION_ORDER_SHOW },
    { label: NameMenu.DOWNLOAD_QR_CODE, href: PathMenu.DOWNLOAD_QR_CODE },
    { label: NameMenu.DOWNLOAD_QR_CODE_LABEL, href: PathMenu.DOWNLOAD_QR_CODE_LABEL }
  ],
  [PathMenu.PRODUCTION_ORDER_CREATE]: [
    { label: NameMenu.PRODUCTION_ORDER_LIST, href: PathMenu.PRODUCTION_ORDER_LIST },
    { label: NameMenu.PRODUCTION_ORDER_CREATE, href: PathMenu.PRODUCTION_ORDER_CREATE }
  ],
  [PathMenu.PRODUCTION_ORDER_UPDATE]: [
    { label: NameMenu.PRODUCTION_ORDER_LIST, href: PathMenu.PRODUCTION_ORDER_LIST },
    { label: NameMenu.PRODUCTION_ORDER_UPDATE, href: PathMenu.PRODUCTION_ORDER_UPDATE }
  ],

  [PathMenu.FINISHED_GOODS_DEPOSIT_LIST]: [
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_LIST, href: PathMenu.FINISHED_GOODS_DEPOSIT_LIST }
  ],
  [PathMenu.FINISHED_GOODS_DEPOSIT_CREATE]: [
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_LIST, href: PathMenu.FINISHED_GOODS_DEPOSIT_LIST },
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_CREATE, href: PathMenu.FINISHED_GOODS_DEPOSIT_CREATE }
  ],
  [PathMenu.FINISHED_GOODS_DEPOSIT_DETAIL]: [
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_LIST, href: PathMenu.FINISHED_GOODS_DEPOSIT_LIST },
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_DETAIL, href: PathMenu.FINISHED_GOODS_DEPOSIT_DETAIL }
  ],
  [PathMenu.FINISHED_GOODS_DEPOSIT_PRINT]: [
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_LIST, href: PathMenu.FINISHED_GOODS_DEPOSIT_LIST },
    { label: NameMenu.FINISHED_GOODS_DEPOSIT_PRINT, href: PathMenu.FINISHED_GOODS_DEPOSIT_PRINT }
  ],

  [PathMenu.RAW_MATERIAL_WITHDRAW_LIST]: [
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_LIST, href: PathMenu.RAW_MATERIAL_WITHDRAW_LIST }
  ],
  [PathMenu.RAW_MATERIAL_WITHDRAW_CREATE]: [
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_LIST, href: PathMenu.RAW_MATERIAL_WITHDRAW_LIST },
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_CREATE, href: PathMenu.RAW_MATERIAL_WITHDRAW_CREATE }
  ],
  [PathMenu.RAW_MATERIAL_WITHDRAW_DETAIL]: [
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_LIST, href: PathMenu.RAW_MATERIAL_WITHDRAW_LIST },
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_DETAIL, href: PathMenu.RAW_MATERIAL_WITHDRAW_DETAIL }
  ],
  [PathMenu.RAW_MATERIAL_WITHDRAW_PRINT]: [
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_LIST, href: PathMenu.RAW_MATERIAL_WITHDRAW_LIST },
    { label: NameMenu.RAW_MATERIAL_WITHDRAW_PRINT, href: PathMenu.RAW_MATERIAL_WITHDRAW_PRINT }
  ],

  [PathMenu.RAW_MATERIAL_DEPOSIT_LIST]: [
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_LIST, href: PathMenu.RAW_MATERIAL_DEPOSIT_LIST }
  ],
  [PathMenu.RAW_MATERIAL_DEPOSIT_CREATE]: [
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_LIST, href: PathMenu.RAW_MATERIAL_DEPOSIT_LIST },
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_CREATE, href: PathMenu.RAW_MATERIAL_DEPOSIT_CREATE }
  ],
  [PathMenu.RAW_MATERIAL_DEPOSIT_DETAIL]: [
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_LIST, href: PathMenu.RAW_MATERIAL_DEPOSIT_LIST },
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_DETAIL, href: PathMenu.RAW_MATERIAL_DEPOSIT_DETAIL }
  ],
  [PathMenu.RAW_MATERIAL_DEPOSIT_PRINT]: [
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_LIST, href: PathMenu.RAW_MATERIAL_DEPOSIT_LIST },
    { label: NameMenu.RAW_MATERIAL_DEPOSIT_PRINT, href: PathMenu.RAW_MATERIAL_DEPOSIT_PRINT }
  ],

  [PathMenu.TRANSFER_ORDER_LIST]: [{ label: NameMenu.TRANSFER_ORDER_LIST, href: PathMenu.TRANSFER_ORDER_LIST }],
  [PathMenu.TRANSFER_ORDER_SHOW]: [
    { label: NameMenu.TRANSFER_ORDER_LIST, href: PathMenu.TRANSFER_ORDER_LIST },
    { label: NameMenu.TRANSFER_ORDER_SHOW, href: PathMenu.TRANSFER_ORDER_SHOW }
  ],
  [PathMenu.TRANSFER_ORDER_CREATE]: [
    { label: NameMenu.TRANSFER_ORDER_LIST, href: PathMenu.TRANSFER_ORDER_LIST },
    { label: NameMenu.TRANSFER_ORDER_CREATE, href: PathMenu.TRANSFER_ORDER_CREATE }
  ],
  [PathMenu.PRODUCT_ISSUE_REPORT_LIST]: [
    { label: NameMenu.PRODUCT_ISSUE_REPORT_LIST, href: PathMenu.PRODUCT_ISSUE_REPORT_LIST }
  ],
  [PathMenu.PRODUCT_ISSUE_REPORT_SHOW]: [
    { label: NameMenu.PRODUCT_ISSUE_REPORT_LIST, href: PathMenu.PRODUCT_ISSUE_REPORT_LIST },
    { label: NameMenu.PRODUCT_ISSUE_REPORT_SHOW, href: PathMenu.PRODUCT_ISSUE_REPORT_SHOW }
  ],
  [PathMenu.PRODUCT_ISSUE_REPORT_CREATE]: [
    { label: NameMenu.PRODUCT_ISSUE_REPORT_LIST, href: PathMenu.PRODUCT_ISSUE_REPORT_LIST },
    { label: NameMenu.PRODUCT_ISSUE_REPORT_CREATE, href: PathMenu.PRODUCT_ISSUE_REPORT_CREATE }
  ],
  [PathMenu.ORDER_INSPECT]: [{ label: NameMenu.ORDER_INSPECT, href: PathMenu.ORDER_INSPECT }],
  [PathMenu.ORDER_LIST]: [{ label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST }],
  [PathMenu.ORDER_CS_LIST]: [{ label: NameMenu.ORDER_CS_LIST, href: PathMenu.ORDER_CS_LIST }],
  [PathMenu.ORDER_CLAIM_LIST]: [{ label: NameMenu.ORDER_CLAIM_LIST, href: PathMenu.ORDER_CLAIM_LIST }],
  [PathMenu.ORDER_CREATE]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_CREATE, href: PathMenu.ORDER_CREATE }
  ],
  [PathMenu.ORDER_DETAIL]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_DETAIL, href: PathMenu.ORDER_DETAIL }
  ],
  [PathMenu.ORDER_GENERATE_PUBLIC_MAP_URL]: [
    { label: NameMenu.ORDER_GENERATE_PUBLIC_MAP_URL, href: PathMenu.ORDER_GENERATE_PUBLIC_MAP_URL }
  ],
  [PathMenu.ORDER_EXCEL_EXPORT]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_EXCEL_EXPORT, href: PathMenu.ORDER_EXCEL_EXPORT }
  ],
  [PathMenu.ORDER_LIST_RETURN]: [{ label: NameMenu.ORDER_LIST_RETURN, href: PathMenu.ORDER_LIST_RETURN }],
  [PathMenu.ORDER_LIST_WAIT_RETURN]: [
    { label: NameMenu.ORDER_LIST_WAIT_RETURN, href: PathMenu.ORDER_LIST_WAIT_RETURN }
  ],
  [PathMenu.ORDER_WAIT_FOR_APPROVAL]: [
    { label: NameMenu.ORDER_WAIT_FOR_APPROVAL, href: PathMenu.ORDER_WAIT_FOR_APPROVAL }
  ],
  [PathMenu.ORDER_RETURN_CREATE]: [
    { label: NameMenu.ORDER_LIST_RETURN, href: PathMenu.ORDER_LIST_RETURN },
    { label: NameMenu.ORDER_RETURN_CREATE, href: PathMenu.ORDER_RETURN_CREATE }
  ],
  [PathMenu.ORDER_RETURN_DETAIL]: [
    { label: NameMenu.ORDER_LIST_RETURN, href: PathMenu.ORDER_LIST_RETURN },
    { label: NameMenu.ORDER_RETURN_DETAIL, href: PathMenu.ORDER_RETURN_DETAIL }
  ],
  [PathMenu.ORDER_RETURN_PRINT_EVIDENCE]: [
    { label: NameMenu.ORDER_LIST_RETURN, href: PathMenu.ORDER_LIST_RETURN },
    { label: NameMenu.ORDER_RETURN_PRINT, href: PathMenu.ORDER_RETURN_PRINT_EVIDENCE }
  ],
  [PathMenu.ORDER_UPDATE_WHOLE]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_UPDATE_WHOLE, href: PathMenu.ORDER_UPDATE_WHOLE }
  ],
  [PathMenu.ORDER_PRINT]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_DETAIL, href: PathMenu.ORDER_DETAIL },
    { label: NameMenu.ORDER_PRINT, href: PathMenu.ORDER_PRINT }
  ],
  [PathMenu.ORDER_PRINT_PDF]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_DETAIL, href: PathMenu.ORDER_DETAIL },
    { label: NameMenu.ORDER_PRINT, href: '' }
  ],
  [PathMenu.ORDER_PRINT_ALL]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER_DETAIL, href: PathMenu.ORDER_DETAIL },
    { label: NameMenu.ORDER_PRINT, href: '' }
  ],
  [PathMenu.ORDER_WAIT_FOR_PRODUCTION]: [
    { label: NameMenu.ORDER_WAIT_FOR_PRODUCTION, href: PathMenu.ORDER_WAIT_FOR_PRODUCTION }
  ],
  [PathMenu.TICKET_CREATE]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER, href: PathMenu.ORDER },
    { label: NameMenu.TICKET_CREATE, href: PathMenu.TICKET_CREATE }
  ],
  [PathMenu.TICKET_UPDATE]: [
    { label: NameMenu.ORDER_LIST, href: PathMenu.ORDER_LIST },
    { label: NameMenu.ORDER, href: PathMenu.ORDER },
    { label: NameMenu.TICKET_UPDATE, href: PathMenu.TICKET_UPDATE }
  ],
  [PathMenu.TICKET_LIST]: [{ label: NameMenu.TICKET_LIST, href: PathMenu.TICKET_LIST }],
  [PathMenu.CHANGE_DELIVERY_CHANNEL]: [
    { label: NameMenu.CHANGE_DELIVERY_CHANNEL, href: PathMenu.CHANGE_DELIVERY_CHANNEL }
  ],
  [PathMenu.RESERVE_PRODUCT_LIST]: [{ label: NameMenu.RESERVE_PRODUCT_LIST, href: PathMenu.RESERVE_PRODUCT_LIST }],
  [PathMenu.RESERVE_PRODUCT_SHOW]: [{ label: NameMenu.RESERVE_PRODUCT_SHOW, href: PathMenu.RESERVE_PRODUCT_SHOW }],
  [PathMenu.RESERVE_PRODUCT_CREATE]: [
    { label: NameMenu.RESERVE_PRODUCT_CREATE, href: PathMenu.RESERVE_PRODUCT_CREATE }
  ],
  [PathMenu.REPORT]: [{ label: NameMenu.REPORT, href: PathMenu.REPORT }],
  [PathMenu.OVERVIEW_REPORT]: [{ label: NameMenu.OVERVIEW_REPORT, href: PathMenu.OVERVIEW_REPORT }],
  [PathMenu.INCOME_REPORT]: [{ label: NameMenu.INCOME_REPORT, href: PathMenu.INCOME_REPORT }],
  [PathMenu.PRODUCT_REPORT]: [{ label: NameMenu.PRODUCT_REPORT, href: PathMenu.PRODUCT_REPORT }],
  [PathMenu.STAFF_JOB_REPORT_LIST]: [{ label: NameMenu.STAFF_JOB_REPORT_LIST, href: PathMenu.STAFF_JOB_REPORT_LIST }],
  [PathMenu.STAFF_JOB_REPORT_SHOW]: [
    { label: NameMenu.STAFF_JOB_REPORT_LIST, href: PathMenu.STAFF_JOB_REPORT_LIST },
    { label: NameMenu.STAFF_JOB_REPORT_SHOW, href: PathMenu.STAFF_JOB_REPORT_SHOW }
  ],
  [PathMenu.PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT]: [
    { label: NameMenu.PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT, href: PathMenu.PRODUCTION_ORDER_BY_JOB_DETAILS_REPORT }
  ],
  [PathMenu.SALARY_REPORT]: [{ label: NameMenu.SALARY_REPORT, href: PathMenu.SALARY_REPORT }],
  [PathMenu.SALARY_REPORT_PRINT]: [
    { label: NameMenu.SALARY_REPORT, href: PathMenu.SALARY_REPORT },
    { label: NameMenu.SALARY_REPORT_PRINT, href: PathMenu.SALARY_REPORT_PRINT }
  ],
  [PathMenu.SALARY_REPORT_SHOW]: [
    { label: NameMenu.SALARY_REPORT, href: PathMenu.SALARY_REPORT },
    { label: NameMenu.SALARY_REPORT_SHOW, href: PathMenu.SALARY_REPORT_SHOW }
  ],

  [PathMenu.BOOTH_EVENT_LIST]: [{ label: NameMenu.BOOTH_EVENT_LIST, href: PathMenu.BOOTH_EVENT_LIST }],
  [PathMenu.BOOTH_EVENT_SHOW]: [
    { label: NameMenu.BOOTH_EVENT_LIST, href: PathMenu.BOOTH_EVENT_LIST },
    { label: NameMenu.BOOTH_EVENT_SHOW, href: PathMenu.BOOTH_EVENT_SHOW }
  ],
  [PathMenu.BOOTH_EVENT_CREATE]: [{ label: NameMenu.BOOTH_EVENT_CREATE, href: PathMenu.BOOTH_EVENT_CREATE }],
  [PathMenu.BOOTH_EVENT_UPDATE]: [{ label: NameMenu.BOOTH_EVENT_UPDATE, href: PathMenu.BOOTH_EVENT_UPDATE }],

  [PathMenu.ORDER_FLEET_DASHBOARD]: [{ label: NameMenu.ORDER_FLEET_DASHBOARD, href: PathMenu.ORDER_FLEET_DASHBOARD }],

  [PathMenu.STAFF_CONTROL_ROOM]: [{ label: NameMenu.STAFF_CONTROL_ROOM, href: PathMenu.STAFF_CONTROL_ROOM }],
  [PathMenu.WORKSPACE_SETTING]: [{ label: NameMenu.WORKSPACE_SETTING, href: PathMenu.WORKSPACE_SETTING }],
  [PathMenu.WORKSPACE_SHOW]: [{ label: NameMenu.WORKSPACE_SHOW, href: PathMenu.WORKSPACE_SHOW }],
  [PathMenu.WORKSPACE_UPDATE]: [
    { label: NameMenu.WORKSPACE_SHOW, href: PathMenu.WORKSPACE_SHOW },
    { label: NameMenu.WORKSPACE_UPDATE, href: PathMenu.WORKSPACE_UPDATE }
  ],
  [PathMenu.MEMBER_LIST]: [{ label: NameMenu.MEMBER_LIST, href: PathMenu.MEMBER_LIST }],
  [PathMenu.ROLE_LIST]: [{ label: NameMenu.ROLE_LIST, href: PathMenu.ROLE_LIST }],
  [PathMenu.ROLE_SHOW]: [{ label: NameMenu.ROLE_SHOW, href: PathMenu.ROLE_SHOW }],
  [PathMenu.ROLE_CREATE]: [{ label: NameMenu.ROLE_CREATE, href: PathMenu.ROLE_CREATE }],
  [PathMenu.ROLE_UPDATE]: [{ label: NameMenu.ROLE_UPDATE, href: PathMenu.ROLE_UPDATE }],
  [PathMenu.ORDER_SOURCE_LIST]: [{ label: NameMenu.ORDER_SOURCE_LIST, href: PathMenu.ORDER_SOURCE_LIST }],
  [PathMenu.LOGIN]: [{ label: PathMenu.LOGIN, href: PathMenu.LOGIN }],
  [PathMenu.REGISTER]: [{ label: PathMenu.REGISTER, href: PathMenu.REGISTER }],
  [PathMenu.FORGET_PASSWORD]: [{ label: PathMenu.FORGET_PASSWORD, href: PathMenu.FORGET_PASSWORD }],
  [PathMenu.CHANGE_PASSWORD]: [{ label: PathMenu.CHANGE_PASSWORD, href: PathMenu.CHANGE_PASSWORD }],
  [PathMenu.LOGIN_WORKSPACE]: [{ label: PathMenu.LOGIN_WORKSPACE, href: PathMenu.LOGIN_WORKSPACE }],
  [PathMenu.CREATE_WORKSPACE]: [{ label: PathMenu.CREATE_WORKSPACE, href: PathMenu.CREATE_WORKSPACE }],
  [PathMenu.DELIVERY_CHANNEL_LIST]: [{ label: NameMenu.DELIVERY_CHANNEL_LIST, href: PathMenu.DELIVERY_CHANNEL_LIST }],
  [PathMenu.VEHICLE_LIST]: [{ label: NameMenu.VEHICLE_LIST, href: PathMenu.VEHICLE_LIST }],
  [PathMenu.TRCLOUD_SOURCE]: [{ label: NameMenu.TRCLOUD_SOURCE, href: PathMenu.TRCLOUD_SOURCE }],
  [PathMenu.TRCLOUD_LOG]: [{ label: NameMenu.TRCLOUD_LOG, href: PathMenu.TRCLOUD_LOG }],
  [PathMenu.TRCLOUD_LOG_SHOW]: [
    { label: NameMenu.TRCLOUD_LOG, href: PathMenu.TRCLOUD_LOG },
    { label: NameMenu.TRCLOUD_LOG_SHOW, href: PathMenu.TRCLOUD_LOG_SHOW }
  ],
  [PathMenu.BANK_ACCOUNT]: [{ label: NameMenu.BANK_ACCOUNT, href: PathMenu.BANK_ACCOUNT }],
  [PathMenu.BOOTH_LOCATION_LIST]: [{ label: NameMenu.BOOTH_LOCATION_LIST, href: PathMenu.BOOTH_LOCATION_LIST }],
  [PathMenu.SCAN]: [{ label: NameMenu.SCAN, href: PathMenu.SCAN }],
  [PathMenu.SCAN_DEPOSIT_PRODUCT]: [{ label: NameMenu.SCAN_DEPOSIT_PRODUCT, href: PathMenu.SCAN_DEPOSIT_PRODUCT }],
  [PathMenu.SCAN_CHANGE_STATE_ORDER]: [
    { label: NameMenu.SCAN_CHANGE_STATE_ORDER, href: PathMenu.SCAN_CHANGE_STATE_ORDER }
  ],
  [PathMenu.SCAN_ADJUST_PICKING_LIST]: [
    { label: NameMenu.SCAN_ADJUST_PICKING_LIST, href: PathMenu.SCAN_ADJUST_PICKING_LIST }
  ],
  [PathMenu.BOARDED_EVIDENCE_LIST]: [{ label: NameMenu.BOARDED_ORDER_LIST, href: PathMenu.BOARDED_EVIDENCE_LIST }],
  [PathMenu.BOARDED_EVIDENCE_SHOW]: [
    { label: NameMenu.BOARDED_ORDER_LIST, href: PathMenu.BOARDED_EVIDENCE_LIST },
    { label: NameMenu.BOARDED_ORDER_SHOW, href: PathMenu.BOARDED_EVIDENCE_SHOW }
  ],
  [PathMenu.KERRY_TRACKING_LIST]: [{ label: NameMenu.KERRY_TRACKING_LIST, href: PathMenu.KERRY_TRACKING_LIST }],
  [PathMenu.THAIPOST_TRACKING_LIST]: [
    { label: NameMenu.THAIPOST_TRACKING_LIST, href: PathMenu.THAIPOST_TRACKING_LIST }
  ],
  [PathMenu.SALESLEAD_LIST]: [{ label: NameMenu.SALESLEAD_LIST, href: PathMenu.SALESLEAD_LIST }],
  [PathMenu.SALESLEAD_CREATE]: [
    { label: NameMenu.SALESLEAD_LIST, href: PathMenu.SALESLEAD_LIST },
    { label: NameMenu.SALESLEAD_CREATE, href: PathMenu.SALESLEAD_CREATE }
  ],
  [PathMenu.SALESLEAD_SHOW]: [
    { label: NameMenu.SALESLEAD_LIST, href: PathMenu.SALESLEAD_LIST },
    { label: NameMenu.SALESLEAD_SHOW, href: PathMenu.SALESLEAD_SHOW }
  ],
  [PathMenu.SALESLEAD_EDIT]: [
    { label: NameMenu.SALESLEAD_LIST, href: PathMenu.SALESLEAD_LIST },
    { label: NameMenu.SALESLEAD_EDIT, href: PathMenu.SALESLEAD_EDIT }
  ],

  [PathMenu.PURCHASE_REQUISITION_LIST]: [
    { label: NameMenu.PURCHASE_REQUISITION_LIST, href: PathMenu.PURCHASE_REQUISITION_LIST }
  ],
  [PathMenu.PURCHASE_REQUISITION_CREATE]: [
    { label: NameMenu.PURCHASE_REQUISITION_LIST, href: PathMenu.PURCHASE_REQUISITION_LIST },
    { label: NameMenu.PURCHASE_REQUISITION_CREATE, href: PathMenu.PURCHASE_REQUISITION_CREATE }
  ],
  [PathMenu.PURCHASE_REQUISITION_SHOW]: [
    { label: NameMenu.PURCHASE_REQUISITION_LIST, href: PathMenu.PURCHASE_REQUISITION_LIST },
    { label: NameMenu.PURCHASE_REQUISITION_SHOW, href: PathMenu.PURCHASE_REQUISITION_SHOW }
  ],
  [PathMenu.PURCHASE_REQUISITION_EDIT]: [
    { label: NameMenu.PURCHASE_REQUISITION_LIST, href: PathMenu.PURCHASE_REQUISITION_LIST },
    { label: NameMenu.PURCHASE_REQUISITION_EDIT, href: PathMenu.PURCHASE_REQUISITION_EDIT }
  ],
  [PathMenu.PURCHASE_REQUISITION_APPROVAL]: [
    { label: NameMenu.PURCHASE_REQUISITION_APPROVAL, href: PathMenu.PURCHASE_REQUISITION_APPROVAL }
  ],
  [PathMenu.PURCHASE_REQUISITION_SUMMARY]: [
    { label: NameMenu.PURCHASE_REQUISITION_SUMMARY, href: PathMenu.PURCHASE_REQUISITION_SUMMARY }
  ],

  [PathMenu.PURCHASE_ORDER_LIST]: [{ label: NameMenu.PURCHASE_ORDER_LIST, href: PathMenu.PURCHASE_ORDER_LIST }],
  [PathMenu.PURCHASE_ORDER_CREATE]: [
    { label: NameMenu.PURCHASE_ORDER_LIST, href: PathMenu.PURCHASE_ORDER_LIST },
    { label: NameMenu.PURCHASE_ORDER_CREATE, href: PathMenu.PURCHASE_ORDER_CREATE }
  ],
  [PathMenu.PURCHASE_ORDER_SHOW]: [
    { label: NameMenu.PURCHASE_ORDER_LIST, href: PathMenu.PURCHASE_ORDER_LIST },
    { label: NameMenu.PURCHASE_ORDER_SHOW, href: PathMenu.PURCHASE_ORDER_SHOW }
  ],
  [PathMenu.PURCHASE_ORDER_EDIT]: [
    { label: NameMenu.PURCHASE_ORDER_LIST, href: PathMenu.PURCHASE_ORDER_LIST },
    { label: NameMenu.PURCHASE_ORDER_EDIT, href: PathMenu.PURCHASE_ORDER_EDIT }
  ],
  [PathMenu.PURCHASE_ORDER_APPROVAL]: [
    { label: NameMenu.PURCHASE_ORDER_APPROVAL, href: PathMenu.PURCHASE_ORDER_APPROVAL }
  ],
  [PathMenu.PURCHASE_ORDER_SUMMARY]: [
    { label: NameMenu.PURCHASE_ORDER_SUMMARY, href: PathMenu.PURCHASE_ORDER_SUMMARY }
  ],

  [PathMenu.PURCHASE_ORDER_ACCOUNT_LIST]: [
    { label: NameMenu.PURCHASE_ORDER_ACCOUNT_LIST, href: PathMenu.PURCHASE_ORDER_ACCOUNT_LIST }
  ],

  [PathMenu.SUPPLIER_LIST]: [{ label: NameMenu.SUPPLIER_LIST, href: PathMenu.SUPPLIER_LIST }],
  [PathMenu.SUPPLIER_CREATE]: [
    { label: NameMenu.SUPPLIER_LIST, href: PathMenu.SUPPLIER_LIST },
    { label: NameMenu.SUPPLIER_CREATE, href: PathMenu.SUPPLIER_CREATE }
  ],
  [PathMenu.SUPPLIER_SHOW]: [
    { label: NameMenu.SUPPLIER_LIST, href: PathMenu.SUPPLIER_LIST },
    { label: NameMenu.SUPPLIER_SHOW, href: PathMenu.SUPPLIER_SHOW }
  ],
  [PathMenu.SUPPLIER_EDIT]: [
    { label: NameMenu.SUPPLIER_LIST, href: PathMenu.SUPPLIER_LIST },
    { label: NameMenu.SUPPLIER_EDIT, href: PathMenu.SUPPLIER_EDIT }
  ],

  [PathMenu.PRIVACY_POLICY]: [
    { label: NameMenu.SETTING_OTHERS, href: PathMenu.SETTING_OTHERS },
    { label: NameMenu.PRIVACY_POLICY, href: PathMenu.PRIVACY_POLICY }
  ],
  [PathMenu.CUSTOMER_LIST]: [{ label: NameMenu.CUSTOMER_LIST, href: PathMenu.CUSTOMER_LIST }],
  [PathMenu.CUSTOMER_SHOW]: [
    { label: NameMenu.CUSTOMER_LIST, href: PathMenu.CUSTOMER_LIST },
    { label: NameMenu.CUSTOMER_SHOW, href: PathMenu.CUSTOMER_SHOW }
  ],
  [PathMenu.STAFF_LIST]: [{ label: NameMenu.STAFF_LIST, href: PathMenu.STAFF_LIST }],
  [PathMenu.STAFF_GENERATE_QR_CODE]: [
    { label: NameMenu.STAFF_LIST, href: PathMenu.STAFF_LIST },
    { label: NameMenu.STAFF_GENERATE_QR_CODE, href: PathMenu.STAFF_GENERATE_QR_CODE }
  ],
  [PathMenu.STAFF_ROLE_LIST]: [{ label: NameMenu.STAFF_ROLE_LIST, href: PathMenu.STAFF_ROLE_LIST }],
  [PathMenu.STAFF_ROLE_PERMISSIONS_CONFIG]: [
    { label: NameMenu.STAFF_ROLE_PERMISSIONS_CONFIG, href: PathMenu.STAFF_ROLE_PERMISSIONS_CONFIG }
  ],
  [PathMenu.SHOPEE_CONFIG]: [{ label: NameMenu.SHOPEE_CONFIG, href: PathMenu.SHOPEE_CONFIG }],
  [PathMenu.SHOPEE_CATEGORY_FEE]: [
    { label: NameMenu.SHOPEE_CONFIG, href: PathMenu.SHOPEE_CONFIG },
    { label: NameMenu.SHOPEE_CATEGORY_FEE, href: PathMenu.SHOPEE_CATEGORY_FEE }
  ],
  [PathMenu.SHOPEE_PROMOTIONS]: [
    { label: NameMenu.SHOPEE_CONFIG, href: PathMenu.SHOPEE_CONFIG },
    { label: NameMenu.SHOPEE_PROMOTIONS, href: PathMenu.SHOPEE_PROMOTIONS }
  ],
  [PathMenu.SHOPEE_PAYMENT_METHODS]: [
    { label: NameMenu.SHOPEE_CONFIG, href: PathMenu.SHOPEE_CONFIG },
    { label: NameMenu.SHOPEE_PAYMENT_METHODS, href: PathMenu.SHOPEE_PAYMENT_METHODS }
  ],
  [PathMenu.LAZADA_CONFIG]: [{ label: NameMenu.LAZADA_CONFIG, href: PathMenu.LAZADA_CONFIG }],
  [PathMenu.LAZADA_CATEGORY_FEE]: [
    { label: NameMenu.LAZADA_CONFIG, href: PathMenu.LAZADA_CONFIG },
    { label: NameMenu.LAZADA_CATEGORY_FEE, href: PathMenu.LAZADA_CATEGORY_FEE }
  ],
  [PathMenu.LAZADA_TRANSACTION_FEE]: [
    { label: NameMenu.LAZADA_CONFIG, href: PathMenu.LAZADA_CONFIG },
    { label: NameMenu.LAZADA_TRANSACTION_FEE, href: PathMenu.LAZADA_TRANSACTION_FEE }
  ],

  [PathMenu.ACCOUNTING]: [{ label: NameMenu.ACCOUNTING, href: PathMenu.ACCOUNTING }],
  [PathMenu.ORDER_WITH_PAYMENT]: [{ label: NameMenu.ORDER_WITH_PAYMENT, href: PathMenu.ORDER_WITH_PAYMENT }],

  [PathMenu.TECHNICAL_DATA_SHEET]: [{ label: NameMenu.TECHNICAL_DATA_SHEET, href: PathMenu.TECHNICAL_DATA_SHEET }],
  [PathMenu.TECHNICAL_DATA_SHEET_CREATE]: [
    { label: NameMenu.TECHNICAL_DATA_SHEET, href: PathMenu.TECHNICAL_DATA_SHEET },
    { label: NameMenu.TECHNICAL_DATA_SHEET_CREATE, href: PathMenu.TECHNICAL_DATA_SHEET_CREATE }
  ],
  [PathMenu.TECHNICAL_DATA_SHEET_EDIT]: [
    { label: NameMenu.TECHNICAL_DATA_SHEET, href: PathMenu.TECHNICAL_DATA_SHEET },
    { label: NameMenu.TECHNICAL_DATA_SHEET_EDIT, href: PathMenu.TECHNICAL_DATA_SHEET_EDIT }
  ],
  [PathMenu.TECHNICAL_DATA_SHEET_SHOW]: [
    { label: NameMenu.TECHNICAL_DATA_SHEET, href: PathMenu.TECHNICAL_DATA_SHEET },
    { label: NameMenu.TECHNICAL_DATA_SHEET_SHOW, href: PathMenu.TECHNICAL_DATA_SHEET_SHOW }
  ],
  [PathMenu.PRODUCT_TRACKING]: [{ label: NameMenu.PRODUCT_TRACKING, href: PathMenu.PRODUCT_TRACKING }],
  [PathMenu.PRODUCT_TRACKING_DETAIL]: [
    { label: NameMenu.PRODUCT_TRACKING, href: PathMenu.PRODUCT_TRACKING },
    { label: NameMenu.PRODUCT_TRACKING_DETAIL, href: PathMenu.PRODUCT_TRACKING_DETAIL }
  ],
  [PathMenu.ADD_PRODUCT_TRACKING_ACTION]: [
    { label: NameMenu.PRODUCT_TRACKING, href: PathMenu.PRODUCT_TRACKING },
    { label: NameMenu.PRODUCT_TRACKING_DETAIL, href: PathMenu.PRODUCT_TRACKING_DETAIL },
    { label: NameMenu.ADD_PRODUCT_TRACKING_ACTION, href: PathMenu.ADD_PRODUCT_TRACKING_ACTION }
  ],
  [PathMenu.PRODUCT_TRACKING_LABEL]: [
    { label: NameMenu.PRODUCT_TRACKING_LABEL, href: PathMenu.PRODUCT_TRACKING_LABEL }
  ],

  [PathMenu.GENERATE_PRODUCT_GENERIC_QR_CODE]: [
    { label: NameMenu.GENERATE_PRODUCT_GENERIC_QR_CODE, href: PathMenu.GENERATE_PRODUCT_GENERIC_QR_CODE }
  ],

  [PathMenu.NP_ORDER]: [{ label: NameMenu.NP_ORDER_LIST, href: PathMenu.NP_ORDER }],
  [PathMenu.NP_ORDER_DETAIL]: [
    { label: NameMenu.NP_ORDER_LIST, href: PathMenu.NP_ORDER },
    { label: NameMenu.NP_ORDER_DETAIL, href: PathMenu.NP_ORDER_DETAIL }
  ],
  [PathMenu.NP_ORDER_CREATE]: [
    { label: NameMenu.NP_ORDER_LIST, href: PathMenu.NP_ORDER },
    { label: NameMenu.NP_ORDER_CREATE, href: PathMenu.NP_ORDER_CREATE }
  ],

  [PathMenu.PRODUCTION_PLAN]: [{ label: NameMenu.PRODUCTION_PLAN, href: PathMenu.PRODUCTION_PLAN }],
  [PathMenu.PRODUCTION_PLAN_SHOW]: [
    { label: NameMenu.PRODUCTION_PLAN, href: PathMenu.PRODUCTION_PLAN },
    { label: NameMenu.PRODUCTION_PLAN_SHOW, href: PathMenu.PRODUCTION_PLAN }
  ],
  [PathMenu.PRODUCTION_PLAN_UPDATE]: [
    { label: NameMenu.PRODUCTION_PLAN, href: PathMenu.PRODUCTION_PLAN },
    { label: NameMenu.PRODUCTION_PLAN_UPDATE, href: PathMenu.PRODUCTION_PLAN_UPDATE }
  ],
  [PathMenu.PRODUCTION_PLAN_CREATE]: [
    { label: NameMenu.PRODUCTION_PLAN, href: PathMenu.PRODUCTION_PLAN },
    { label: NameMenu.PRODUCTION_PLAN_CREATE, href: PathMenu.PRODUCTION_PLAN_CREATE }
  ],
  [PathMenu.STATEMENT_MATCHING_MATCH]: [
    { label: NameMenu.STATEMENT_MATCHING_MATCH, href: PathMenu.STATEMENT_MATCHING_MATCH }
  ],
  [PathMenu.STATEMENT_MATCHING_BANK_TRANSACTION]: [
    { label: NameMenu.AUDITING },
    { label: NameMenu.STATEMENT_MATCHING_BANK_TRANSACTION, href: PathMenu.STATEMENT_MATCHING_BANK_TRANSACTION }
  ],

  [PathMenu.DELIVERY_FLEET_LIST]: [{ label: NameMenu.DELIVERY_FLEET_LIST, href: PathMenu.DELIVERY_FLEET_LIST }],
  [PathMenu.DELIVERY_FLEET_PRINT]: [
    { label: NameMenu.DELIVERY_FLEET_LIST, href: PathMenu.DELIVERY_FLEET_LIST },
    { label: NameMenu.DELIVERY_FLEET_PRINT, href: PathMenu.DELIVERY_FLEET_PRINT }
  ],
  [PathMenu.DELIVERY_FLEET_PRINT_SOF]: [
    { label: NameMenu.DELIVERY_FLEET_LIST, href: PathMenu.DELIVERY_FLEET_LIST },
    { label: NameMenu.DELIVERY_FLEET_PRINT_SOF, href: PathMenu.DELIVERY_FLEET_PRINT_SOF }
  ],
  [PathMenu.TRCLOUD_TEMP_ARRAY_INSPECT]: [
    { label: NameMenu.TRCLOUD_TEMP_ARRAY_INSPECT, href: PathMenu.TRCLOUD_TEMP_ARRAY_INSPECT }
  ],

  [PathMenu.STOCK_MOVEMENT_FACTORY]: [
    { label: NameMenu.STOCK_MOVEMENT_FACTORY, href: PathMenu.STOCK_MOVEMENT_FACTORY }
  ],

  [PathMenu.STOCK_MOVEMENT_BOOTH]: [{ label: NameMenu.STOCK_MOVEMENT_BOOTH, href: PathMenu.STOCK_MOVEMENT_BOOTH }],
  [PathMenu.STOCK_MOVEMENT_DETAIL]: [
    { label: NameMenu.STOCK_MOVEMENT_FACTORY, href: PathMenu.STOCK_MOVEMENT_FACTORY },
    { label: NameMenu.STOCK_MOVEMENT_DETAIL, href: PathMenu.STOCK_MOVEMENT_DETAIL }
  ],

  [PathMenu.PRODUCTION_LINE]: [{ label: NameMenu.PRODUCTION_LINE, href: PathMenu.PRODUCTION_LINE }],
  [PathMenu.PRODUCTION_LINE_CALENDAR]: [
    { label: NameMenu.PRODUCTION_LINE, href: PathMenu.PRODUCTION_LINE },
    { label: NameMenu.PRODUCTION_LINE_CALENDAR, href: PathMenu.PRODUCTION_LINE_CALENDAR }
  ],

  [PathMenu.COD_MATCHING]: [{ label: NameMenu.COD_MATCHING, href: PathMenu.COD_MATCHING }],
  [PathMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST]: [
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST }
  ],
  [PathMenu.TRANSFER_OUT_INVENTORY_ORDER_CREATE]: [
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST },
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_CREATE, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_CREATE }
  ],
  [PathMenu.TRANSFER_OUT_INVENTORY_ORDER_SHOW]: [
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST },
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_SHOW, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_SHOW }
  ],
  [PathMenu.TRANSFER_OUT_INVENTORY_ORDER_UPDATE]: [
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_LIST },
    { label: NameMenu.TRANSFER_OUT_INVENTORY_ORDER_UPDATE, href: PathMenu.TRANSFER_OUT_INVENTORY_ORDER_UPDATE }
  ],
  [PathMenu.TRANSFER_INTERNAL_ORDER_LIST]: [
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_LIST, href: PathMenu.TRANSFER_INTERNAL_ORDER_LIST }
  ],
  [PathMenu.TRANSFER_INTERNAL_ORDER_CREATE]: [
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_LIST, href: PathMenu.TRANSFER_INTERNAL_ORDER_LIST },
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_CREATE, href: PathMenu.TRANSFER_INTERNAL_ORDER_CREATE }
  ],
  [PathMenu.TRANSFER_INTERNAL_ORDER_UPDATE]: [
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_LIST, href: PathMenu.TRANSFER_INTERNAL_ORDER_LIST },
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_UPDATE, href: PathMenu.TRANSFER_INTERNAL_ORDER_UPDATE }
  ],
  [PathMenu.TRANSFER_INTERNAL_ORDER_SHOW]: [
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_LIST, href: PathMenu.TRANSFER_INTERNAL_ORDER_LIST },
    { label: NameMenu.TRANSFER_INTERNAL_ORDER_SHOW, href: PathMenu.TRANSFER_INTERNAL_ORDER_SHOW }
  ],
  [PathMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST]: [
    {
      label: NameMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST,
      href: PathMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST
    }
  ],
  [PathMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_SHOW]: [
    {
      label: NameMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST,
      href: PathMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_LIST
    },
    {
      label: NameMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_SHOW,
      href: PathMenu.PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW_SHOW
    }
  ],
  [PathMenu.UNIVERSAL_TF]: [{ label: NameMenu.UNIVERSAL_TF, href: PathMenu.UNIVERSAL_TF }],
  [PathMenu.FAKE_SHIPPED]: [{ label: NameMenu.FAKE_SHIPPED, href: PathMenu.FAKE_SHIPPED }],
  [PathMenu.QR_CODE_TRACKING]: [{ label: NameMenu.QR_CODE_TRACKING, href: PathMenu.QR_CODE_TRACKING }]
}

export const ProductType = {
  NORMAL: {
    LABEL: 'สินค้าธรรมดา',
    VALUE: 'normal'
  },
  SET: {
    LABEL: 'สินค้าจัดเซต',
    VALUE: 'set'
  },
  WIP: {
    LABEL: 'สินค้าระหว่างผลิต',
    VALUE: 'wip'
  },
  RAW_MATERIAL: {
    LABEL: 'วัตถุดิบ',
    VALUE: 'raw_material'
  },
  REF: {
    LABEL: 'อ้างอิง',
    VALUE: 'ref'
  },
  BUNDLE: {
    LABEL: 'สินค้าแถมของ',
    VALUE: 'bundle'
  },
  CONVERTIBLE: {
    LABEL: 'สินค้าแยกชิ้นได้',
    VALUE: 'convertible'
  },
  EXTRA: {
    LABEL: 'ผลิตภัณฑ์เสริม',
    VALUE: 'extra'
  },
  SUB: {
    LABEL: 'ช้ินส่วน FG',
    VALUE: 'sub'
  },
  WIP_G: {
    LABEL: 'Generic WIP',
    VALUE: 'wip_g'
  },
  FG_G: {
    LABEL: 'Generic FG',
    VALUE: 'fg_g'
  },
  RAW_MATERIAL_G: {
    LABEL: 'Generic Raw Material',
    VALUE: 'raw_material_g'
  }
}

export enum ProductTypeValue {
  NORMAL = 'normal',
  SET = 'set',
  WIP = 'wip',
  RAW_MATERIAL = 'raw_material',
  REF = 'ref',
  BUNDLE = 'bundle',
  CONVERTIBLE = 'convertible',
  SUB = 'sub',
  WIP_G = 'wip_g',
  RAW_MATERIAL_G = 'raw_material_g',
  FG_G = 'fg_g'
}

export enum ProductTypeLabel {
  NORMAL = 'สินค้าธรรมดา',
  SET = 'สินค้าจัดเซต',
  WIP = 'สินค้าระหว่างผลิต',
  RAW_MATERIAL = 'วัตถุดิบ',
  REF = 'อ้างอิง',
  BUNDLE = 'สินค้าแถมของ',
  CONVERTIBLE = 'สินค้าแยกชิ้นได้',
  SUB = 'ช้ินส่วน FG',
  WIP_G = 'wip_g',
  RAW_MATERIAL_G = 'raw_material_g',
  FG_G = 'fg_g'
}

export enum ProductStatus {
  ALL = 'all',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFT = 'draft'
}

export enum ProductStatusLabel {
  ALL = 'สินค้าทั้งหมด',
  ACTIVE = 'สินค้าขายอยู่',
  INACTIVE = 'ไม่แสดงสินค้า',
  DRAFT = 'แบบร่าง'
}

export const FamilyRatioType = {
  MAIN: {
    LABEL: 'ส่วนประกอบหลัก',
    VALUE: 'main'
  },
  SHARED: {
    LABEL: 'ส่วนประกอบทั่วไป',
    VALUE: 'shared'
  }
}

export enum STATUS_MODAL_ALERT {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  LOADING = 'loading'
}

export enum MESSAGES {
  NO_PDF_FOUND = 'no_pdf_found'
}

export const statusAlert = {
  [STATUS_MODAL_ALERT.LOADING]: 'PDF loading...',
  [STATUS_MODAL_ALERT.ERROR]: 'Invalid file format: The specified file could not be supported!',
  [MESSAGES.NO_PDF_FOUND]: 'No result pdf found!'
}

export enum NotiLabel {
  TOTAL_COUNT = 'total_count'
}

export enum SyncMenu {
  SEE_SYNC_HISTORY = 'seeSyncHistory',
  SEE_SYNC_MARKETPLACE_INFO = 'seeSyncMarketplaceInfo'
}

export enum ShopeeReturnStatus {
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  JUDGING = 'judging',
  REFUND_PAID = 'refund_paid',
  CLOSED = 'closed',
  PROCESSING = 'processing',
  SELLER_DISPUTE = 'seller_dispute'
}

export enum ShopeeReturnStatusLabel {
  REQUESTED = 'ยื่นขอคืนแล้ว',
  ACCEPTED = 'ได้รับการยอมรับแล้ว',
  CANCELLED = 'ถูกยกเลิก',
  JUDGING = 'กำลังตัดสิน',
  REFUND_PAID = 'คืนเงินแล้ว',
  CLOSED = 'ปิด',
  PROCESSING = 'กำลังดำเนินการ',
  SELLER_DISPUTE = 'ข้อพิพาทกับผู้ขาย'
}

export enum ShopeeLogisticsStatus {
  LOGISTICS_NOT_STARTED = 'logistics_not_started',
  LOGISTICS_REQUEST_CREATED = 'logistics_request_created',
  LOGISTICS_PICKUP_DONE = 'logistics_pickup_done',
  LOGISTICS_PICKUP_RETRY = 'logistics_pickup_retry',
  LOGISTICS_PICKUP_FAILED = 'logistics_pickup_failed',
  LOGISTICS_DELIVERY_DONE = 'logistics_delivery_done',
  LOGISTICS_DELIVERY_FAILED = 'logistics_delivery_failed',
  LOGISTICS_REQUEST_CANCELED = 'logistics_request_canceled',
  LOGISTICS_COD_REJECTED = 'logistics_cod_rejected',
  LOGISTICS_READY = 'logistics_ready',
  LOGISTICS_INVALID = 'logistics_invalid',
  LOGISTICS_LOST = 'logistics_lost',
  LOGISTICS_PENDING_ARRANGE = 'logistics_pending_arrange'
}

export enum ShopeeLogisticsStatusLabelEN {
  LOGISTICS_NOT_STARTED = 'Initial status, order not ready for fulfillment',
  LOGISTICS_REQUEST_CREATED = 'Order arranged shipment',
  LOGISTICS_PICKUP_DONE = 'Order handed over to 3PL',
  LOGISTICS_PICKUP_RETRY = 'Order pending 3PL retry pickup',
  LOGISTICS_PICKUP_FAILED = 'Order cancelled by 3PL due to failed pickup or picked up but not able to proceed with delivery',
  LOGISTICS_DELIVERY_DONE = 'Order successfully delivered',
  LOGISTICS_DELIVERY_FAILED = 'Order cancelled due to 3PL delivery failed',
  LOGISTICS_REQUEST_CANCELED = 'Order cancelled when order at LOGISTICS_REQUEST_CREATED',
  LOGISTICS_COD_REJECTED = 'Integrated logistics COD: Order rejected for COD.',
  LOGISTICS_READY = 'Order ready for fulfilment from a payment perspective: non-COD: order paid COD: order passed COD screening',
  LOGISTICS_INVALID = 'Order cancelled when order at LOGISTICS_READY',
  LOGISTICS_LOST = 'Order cancelled due to 3PL lost the order',
  LOGISTICS_PENDING_ARRANGE = 'Order logistics pending arrangement'
}

export enum ShopeeLogisticsStatusLabelTH {
  LOGISTICS_NOT_STARTED = 'สถานะเริ่มต้น, ออร์เดอร์ไม่พร้อมให้บริการ',
  LOGISTICS_REQUEST_CREATED = 'การจัดส่งเริ่มต้น, ออร์เดอร์ที่จัดส่ง',
  LOGISTICS_PICKUP_DONE = 'การจัดส่งเสร็จสิ้น, ออร์เดอร์มอบให้ 3PL',
  LOGISTICS_PICKUP_RETRY = 'การจัดส่งล้มเหลว, ออร์เดอร์รอการจัดส่งใหม่จาก 3PL',
  LOGISTICS_PICKUP_FAILED = 'การจัดส่งล้มเหลว, ออร์เดอร์ยกเลิกโดย 3PL เนื่องจากจัดส่งล้มเหลวหรือได้รับแต่ไม่สามารถดำเนินการต่อไปได้',
  LOGISTICS_DELIVERY_DONE = 'การจัดส่งสำเร็จ, ออร์เดอร์ได้รับสำเร็จ',
  LOGISTICS_DELIVERY_FAILED = 'การจัดส่งล้มเหลว, ออร์เดอร์ถูกยกเลิกเนื่องจากการจัดส่งล้มเหลวจาก 3PL',
  LOGISTICS_REQUEST_CANCELED = 'การยกเลิกคำขอการจัดส่ง, ออร์เดอร์ถูกยกเลิกเมื่ออยู่ที่ LOGISTICS_REQUEST_CREATED',
  LOGISTICS_COD_REJECTED = 'การจัดส่งรหัส COD ถูกปฏิเสธ, ออร์เดอร์ถูกปฏิเสธสำหรับ COD',
  LOGISTICS_READY = 'ออร์เดอร์พร้อมให้บริการจากมุมมองการชำระเงิน: ไม่ใช่ COD: ออร์เดอร์ชำระเงินแล้ว COD: ออร์เดอร์ผ่านการตรวจสอบ COD',
  LOGISTICS_INVALID = 'การยกเลิกออร์เดอร์เมื่ออยู่ที่ LOGISTICS_READY',
  LOGISTICS_LOST = 'การยกเลิกออร์เดอร์เนื่องจาก 3PL สูญหาย',
  LOGISTICS_PENDING_ARRANGE = 'การจัดส่งออร์เดอร์รอการจัดส่ง'
}

export enum LazadaReverseStatus {
  REQUEST_INITIATE = 'request initiate',
  REQUEST_REJECT = 'request reject',
  REQUEST_CANCEL = 'request cancel',
  CANCEL_SUCCESS = 'cancel success',
  REFUND_PENDING = 'refund pending',
  REFUND_AUTHORIZED = 'refund authorized',
  REFUND_SUCCESS = 'refund success',
  REFUND_REJECT = 'refund reject',
  REQUEST_COMPLETE = 'request complete',
  SELLER_AGREE_RETURN = 'seller agree return',
  SELLER_REJECT_RETURN = 'seller reject return',
  BUYER_RETURN_ITEM = 'buyer return item',
  SELLER_AGREE_REFUND = 'seller agree refund',
  SELLER_REJECT_REFUND = 'seller reject refund',
  CS_APPROVING = 'customer service approving',
  AGREE_CANCEL_ORDER = 'agree cancel order',
  REJECT_CANCEL_ORDER = 'reject cancel order'
}

export enum LazadaReverseStatusTH {
  REQUEST_INITIATE = 'เริ่มต้นคำขอ',
  REQUEST_REJECT = 'ปฏิเสธคำขอ',
  REQUEST_CANCEL = 'ยกเลิกคำขอ',
  CANCEL_SUCCESS = 'ยกเลิกสำเร็จ',
  REFUND_PENDING = 'รอการคืนเงิน',
  REFUND_AUTHORIZED = 'อนุมัติคืนเงิน',
  REFUND_SUCCESS = 'คืนเงินสำเร็จ',
  REFUND_REJECT = 'ปฏิเสธการคืนเงิน',
  REQUEST_COMPLETE = 'เสร็จสิ้นคำขอ',
  SELLER_AGREE_RETURN = 'ผู้ขายตกลงคืนสินค้า',
  SELLER_REJECT_RETURN = 'ผู้ขายปฏิเสธการคืนสินค้า',
  BUYER_RETURN_ITEM = 'ผู้ซื้อคืนสินค้า',
  SELLER_AGREE_REFUND = 'ผู้ขายตกลงคืนเงิน',
  SELLER_REJECT_REFUND = 'ผู้ขายปฏิเสธการคืนเงิน',
  CS_APPROVING = 'การอนุมัติจากบริการลูกค้า',
  AGREE_CANCEL_ORDER = 'ตกลงยกเลิกคำสั่งซื้อ',
  REJECT_CANCEL_ORDER = 'ปฏิเสธยกเลิกคำสั่งซื้อ'
}

// Warning!! this ordering has effect to timeline order status in order show page!
// Please carefully to change ordering.
export enum OrderStatusLabel {
  RESERVED = 'ที่สงวนไว้',
  RAW = 'เริ่มสร้าง',
  DRAFT = 'รอระบุข้อมูล',
  PREORDER = 'สั่งของล่วงหน้า(ห้าง)',
  WAIT_FOR_PRODUCTION = 'คำสั่งซื้อรอผลิต',
  MADE_TO_ORDER = 'ผลิตตามสั่ง',
  ASSEMBLY = 'กำลังประกอบ',
  QC_PASSED = 'ผ่านการตรวจคุณภาพแล้ว',
  WAIT_FOR_APPROVAL = 'คำสั่งซื้อรออนุมัติ',
  REJECTED_APPROVAL = 'คำสั่งซื้อถูกปฏิเสธ',
  OPEN = 'รับคำสั่งซื้อ',
  READY_TO_SHIP = 'พร้อมจัดการ',
  PREPICK = 'วางแผนหยิบสินค้า',
  PREPARED = 'จัดเตรียมสินค้าเรียบร้อย',
  PICKED = 'หยิบสินค้าสำเร็จ',
  PRINTED = 'พิมพ์ใบปะหน้า',
  PACKED = 'แพ็คสินค้า',
  BOARDED = 'นำขึ้นรถ',
  TRANSPORTED = 'ระหว่างขนส่ง',
  DELIVERED = 'ส่งสินค้าสำเร็จ',
  NON_DELIVERED = 'ส่งไม่สำเร็จ',
  CHANGE_PRODUCT = 'ขอเปลี่ยนสินค้า',
  CANCELLED = 'ยกเลิกคำสั่งซื้อ',
  /**
   * @deprecated
   * use WAIT_RETURN_TRANSPORTED instead
   */
  WAIT_RETURN = 'รอคืนสินค้า',
  /**
   * @deprecated
   * use WAIT_RETURN_CUSTOMER instead
   */
  WAIT_RETURN_CANCELLED = 'รอคืนสินค้าหลังส่งแล้ว', //on the transported and cancel

  WAIT_RETURN_TRANSPORTED = 'รอคืนสินค้า', // new status for wait_return
  WAIT_RETURN_CUSTOMER = 'รอคืนสินค้าหลังส่งแล้ว', // new status for wait_return_cancelled
  RETURNED = 'คืนสินค้าสำเร็จ',
  RETURNED_BY_DELIVERY_FIRM = 'คืนสินค้าสำเร็จแจ้งโดยบริษัทขนส่ง',
  DOUBTED = 'พบปัญหา',
  LOST = 'สูญหาย',
  LOST_AND_COMPENSATED = 'ของหายได้เงินชดเชยแล้ว',
  UNKNOWN_SOURCE_STATUS = 'ไม่ทราบสถานะ',
  RESOLVED_BY_MARKETPLACE = 'ดำเนินการโดย Marketplace',
  FLEET_READY = 'พร้อมจัดคิว',
  FLEET_IN_PROGRESS = 'ยังจัดคิวไม่เสร็จ',
  FLEETED = 'จัดคิวแล้ว',
  PARTIAL_DELIVERED = 'ส่งมอบบางส่วน',

  RETRIEVED = 'รับกลับแล้ว',

  CLAIM = 'ทำเรื่องเคลม',
  WAIT_RETURN_CLAIM = 'รอคืนเพื่อเคลม',
  WAIT_REPAIR_ONSITE = 'รอซ่อมหน้างาน',
  WAIT_REPAIR_INHOUSE = 'รอซ่อมในโรงงาน',
  REPAIR_INHOUSE = 'กำลังซ่อมในโรงงาน',
  MADE_REPAIR_INHOUSE = 'กำลังซ่อมในโรงงาน'
}

// Warning!! this ordering has effect to timeline order status in order show page!
// Please carefully to change ordering.
export enum OrderStatus {
  RESERVED = 'reserved',
  RAW = 'raw',
  DRAFT = 'draft',
  OPEN = 'open',
  WAIT_FOR_APPROVAL = 'wait_for_approval',
  REJECTED_APPROVAL = 'rejected_approval', // order is closed
  PREORDER = 'preorder',
  WAIT_FOR_PRODUCTION = 'wait_for_production',
  MADE_TO_ORDER = 'made_to_order',
  ASSEMBLY = 'assembly',
  QC_PASSED = 'qc_passed',
  READY_TO_SHIP = 'ready_to_ship',
  PREPICK = 'prepick',
  PICKED = 'picked',
  PREPARED = 'prepared',
  PRINTED = 'printed',
  PACKED = 'packed',
  FLEET_READY = 'fleet_ready',
  FLEET_IN_PROGRESS = 'fleet_in_progress',
  FLEETED = 'fleeted',
  PARTIAL_DELIVERED = 'partial_delivered',
  BOARDED = 'boarded',
  TRANSPORTED = 'transported',
  DELIVERED = 'delivered',
  NON_DELIVERED = 'non_delivered',
  CHANGE_PRODUCT = 'change_product',

  RETRIEVED = 'retrieved',
  CLAIM = 'claim',
  WAIT_RETURN_CLAIM = 'wait_return_claim',
  WAIT_REPAIR_ONSITE = 'wait_repair_onsite',
  WAIT_REPAIR_INHOUSE = 'wait_repair_inhouse',
  REPAIR_INHOUSE = 'repair_inhouse',
  MADE_REPAIR_INHOUSE = 'made_repair_inhouse',

  CANCELLED = 'cancelled',

  /**
   * @deprecated
   * use WAIT_RETURN_TRANSPORTED instead
   */
  WAIT_RETURN = 'wait_return',
  /**
   * @deprecated
   * use WAIT_RETURN_CUSTOMER instead
   */
  WAIT_RETURN_CANCELLED = 'wait_return_cancelled', //on the transported and cancel

  WAIT_RETURN_TRANSPORTED = 'wait_return_transported', // new status for wait_return
  WAIT_RETURN_CUSTOMER = 'wait_return_customer', // new status for wait_return_cancelled

  RETURNED_BY_DELIVERY_FIRM = 'returned_by_delivery_firm',
  RETURNED = 'returned',
  DOUBTED = 'doubted',
  LOST = 'lost',
  LOST_AND_COMPENSATED = 'lost_and_compensated',
  UNKNOWN_SOURCE_STATUS = 'unknown_source_status',
  RESOLVED_BY_MARKETPLACE = 'resolved_by_marketplace'
}

export enum OrderPaymentVerifyStatus {
  VERIFIED = 'verified',
  NOT_VERIFIED = 'not_verified',
  PARTIAL_VERIFIED = 'partial_verified',
  ISSUE = 'issue'
}

export enum OrderPaymentVerifyStatusLabel {
  VERIFIED = 'ตรวจสอบแล้ว',
  NOT_VERIFIED = 'ยังไม่ตรวจสอบ',
  PARTIAL_VERIFIED = 'ตรวจสอบแล้วบางส่วน',
  ISSUE = 'มีปัญหา'
}

export enum CODMatchingStatusLabel {
  VERIFIED = 'ได้รับเงินจากขนส่งแล้ว',
  NOT_VERIFIED = 'ยังไม่ได้รับเงินจากขนส่ง',
  NOT_FOUND = 'ไม่พบข้อมูล',
  ROUNDED_VERIFIED = 'ปัดเศษ',
  ISSUE = 'มีปัญหา'
}

export enum PrintUpdateStatus {
  UPDATE_CHANGED_STATUS = 'print_and_change_status_printed'
}

export enum TransportationStatusLabel {
  TRANSPORTATION_NORMAL = 'การขนส่งปกติ',
  TRANSPORTATION_ISSUE = 'ปัญหาพื้นที่จัดส่ง'
}

export enum TransportationStatus {
  TRANSPORTATION_NORMAL = 'transportation_normal',
  TRANSPORTATION_ISSUE = 'transportation_issue'
}

export enum TRCloudStatusLabel {
  CREATED_ORDER = 'สร้าง Order',
  CREATED_SO = 'สร้างใบสั่งขาย [SO]',
  CREATED_IV = 'สร้างใบกำกับภาษี [IV]',
  CREATED_MR = 'สร้างใบเบิกสินค้า [MR]',
  CREATED_RV = 'สร้างใบเสร็จรับเงิน [RV]'
}

export enum TRCloudStatus {
  CREATED_ORDER = 'created_order',
  CREATED_SO = 'created_so',
  CREATED_IV = 'created_iv',
  CREATED_MR = 'created_mr',
  CREATED_RV = 'created_rv'
}

export enum TRCloudLogDocsType {
  CREATED_ORDER = 'order',
  CREATED_SO = 'so',
  CREATED_IV = 'iv',
  CREATED_MR = 'mr',
  CREATED_RV = 'rv'
}

export enum TicketMainStatus {
  SUBMIT_TICKET = 'submit_ticket',
  DECISION_PROCESS = 'decision_process',
  APPROVED = 'approved',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELLED = 'cancelled',
  APPEAL = 'appeal',
  DISAPPROVED = 'disapproved',
  KILLSWITCH = 'killswitch'
}

export enum TicketMainStatusLabel {
  SUBMIT_TICKET = 'ส่งปัญหา',
  DECISION_PROCESS = 'การดำเนินการตัดสินใจ',
  APPROVED = 'อนุมัติเคลม',
  IN_PROGRESS = 'กำลังดำเนินการ',
  DONE = 'เสร็จสิ้น',
  CANCELLED = 'ยกเลิก',
  APPEAL = 'ขออุทธรณ์',
  DISAPPROVED = 'ไม่อนุมัติคำร้องขอ',
  KILLSWITCH = 'ยกเลิกปัญหานี้ฉุกเฉิน'
}

export enum TicketAction {
  APPEAL = 'appeal',
  NEGOTIATE = 'negotiate',

  APPROVED = 'approved',
  DISAPPROVED = 'disapproved',

  ACCEPT = 'accept',

  KILLSWITCH = 'killswitch'
}

export enum TicketActionLabel {
  APPEAL = 'ขออุทธรณ์',
  NEGOTIATE = 'ส่งให้เจรจาต่อรอง',
  WAIT_FOR_APPROVE = 'รอการอนุมัติ',
  WAIT_FOR_INFORM = 'รอแจ้งลูกค้า',
  WAIT_FOR_CUSTOMER_DECIDE = 'รอลูกค้าตัดสินใจ',

  APPROVED = 'อนุมัติ',
  DISAPPROVED = 'ไม่อนุมัติคำร้องขอ',

  ACCEPT = 'ยอมรับคำตัดสินเนื่องจากการไม่อนุมัติ',
  CREATE_NEW_ORDER = 'สร้างคำสั่งตามแนะนำ',

  KILLSWITCH = 'ยกเลิกปัญหานี้ฉุกเฉิน'
}

export enum TicketNegotiateAction {
  WAIT_FOR_APPROVE = 'wait_for_approve',
  WAIT_FOR_INFORM = 'wait_for_inform',
  WAIT_FOR_CUSTOMER_DECIDE = 'wait_for_customer_decide',
  NEGOTIATE = 'negotiate'
}

export enum TicketNegotiateActionLabel {
  WAIT_FOR_APPROVE = 'รอการอนุมัติ',
  WAIT_FOR_INFORM = 'รอแจ้งลูกค้า',
  WAIT_FOR_CUSTOMER_DECIDE = 'รอลูกค้าตัดสินใจ',
  NEGOTIATE = 'ส่งให้เจรจาต่อรอง'
}

export enum TicketSpecialAction {
  PICKED_TO_REPAIR = 'picked_to_repair',
  PICKED_TO_SEND_NEW = 'picked_to_send_new',
  CREATE_NEW_ORDER = 'create_new_order',
  CREATE_CARRY_BACK_ORDER = 'create_carry_back_order',
  FORCE_DONE = 'force_done'
}

export enum TicketEngineerAction {
  PICKED_TO_CLAIM = 'picked_to_claim',
  PICKED_TO_REPAIR = 'picked_to_repair',
  PICKED_TO_SERVICE = 'picked_to_service',
  LOCK_FLEET = 'lock_fleet',
  GENERATE_PRODUCT_TRACKING = 'generate_product_tracking'
}

export enum TicketEngineerActionLabel {
  PICKED_TO_CLAIM = 'ตัดสินใจเปลี่ยนของใหม่ให้ลูกค้่า',
  PICKED_TO_REPAIR = 'ตัดสินใจรับสินค้ากลับมาซ่อม',
  PICKED_TO_SERVICE = 'ตัดสินใจบริการซ่อมนอกสถานที่',
  LOCK_FLEET = 'ล็อคคิวรถ',
  GENERATE_PRODUCT_TRACKING = 'สร้าง QR สินค้า'
}

export enum TicketStatus {
  SUBMIT_TICKET = 'submit_ticket',

  WAIT_FOR_APPROVE = 'wait_for_approve',
  WAIT_FOR_INFORM = 'wait_for_inform',
  WAIT_FOR_CUSTOMER_DECIDE = 'wait_for_customer_decide',
  NEGOTIATE = 'negotiate',

  APPEAL = 'appeal',
  APPROVED = 'approved',

  PICKED_TO_SEND_NEW = 'picked_to_send_new', // old flow

  PICKED_TO_CLAIM = 'picked_to_claim',
  PICKED_TO_REPAIR = 'picked_to_repair',
  PICKED_TO_SERVICE = 'picked_to_service',
  LOCK_FLEET = 'lock_fleet',
  GENERATE_PRODUCT_TRACKING = 'generate_product_tracking',

  CREATE_INCOMPLETE_DELIVER_ORDER = 'create_incomplete_deliver_order',

  CREATE_NEW_ORDER = 'create_new_order',
  CREATE_CLAIM_ORDER = 'create_claim_order',

  CREATE_RETRIEVE_ORDER = 'create_retrieve_order',
  RECEIVED_BACK = 'received_back',
  BOARDED_NEW = 'boarded_new',
  IN_PROGRESS = 'in_progress',
  DECISION_PROCESS = 'decision_process',
  DELIVERED = 'delivered',
  ACCEPT = 'accept',

  DONE = 'done',

  DISAPPROVED = 'disapproved',
  KILLSWITCH = 'killswitch',
  CANCELLED = 'cancelled'
}

export enum TicketStatusLabel {
  SUBMIT_TICKET = 'ส่งปัญหา',

  WAIT_FOR_APPROVE = 'รอการอนุมัติ',
  WAIT_FOR_INFORM = 'รอแจ้งลูกค้า',
  WAIT_FOR_CUSTOMER_DECIDE = 'รอลูกค้าตัดสินใจ',
  NEGOTIATE = 'ส่งให้เจรจาต่อรอง',

  APPEAL = 'ขออุทธรณ์',
  APPROVED = 'อนุมัติคำร้องขอ',

  PICKED_TO_SEND_NEW = 'ตัดสินใจเปลี่ยนให้ลูกค้า', // old flow

  PICKED_TO_CLAIM = 'ตัดสินใจเปลี่ยนของใหม่ให้ลูกค้่า',
  PICKED_TO_REPAIR = 'ตัดสินใจรับสินค้ากลับมาซ่อม',
  PICKED_TO_SERVICE = 'ตัดสินใจบริการซ่อมนอกสถานที่',
  LOCK_FLEET = 'ล็อคคิวรถ',
  GENERATE_PRODUCT_TRACKING = 'สร้าง QR สินค้า',

  CREATE_INCOMPLETE_DELIVER_ORDER = 'สร้างคำสั่งส่งสินค้าตกหล่น',

  CREATE_NEW_ORDER = 'สร้างคำสั่งซื้อใหม่',
  CREATE_CARRY_BACK_ORDER = 'สร้างคำสั่งรับสินค้าเคลมกลับ',

  CREATE_CLAIM_ORDER = 'สร้างคำสั่งส่งสินค้าเคลม', // unused

  CREATE_RETRIEVE_ORDER = 'สร้างคำสั่งรับสินค้าเคลม',
  RECEIVED_BACK = 'รับสินค้ากลับคืนคลัง',
  BOARDED_NEW = 'ส่งสินค้าใหม่ไป',
  IN_PROGRESS = 'กำลังดำเนินการ',
  DECISION_PROCESS = 'การดำเนินการตัดสินใจ',
  DELIVERED = 'ส่งสำเร็จ',
  ACCEPT = 'ยอมรับคำตัดสินเนื่องจากการไม่อนุมัติ',

  DONE = 'เสร็จสิ้น',
  FORCE_DONE = 'บังคับเสร็จสิ้น',

  DISAPPROVED = 'ไม่อนุมัติคำร้องขอ',
  KILLSWITCH = 'ยกเลิกปัญหานี้ฉุกเฉิน',
  CANCELLED = 'ยกเลิก'
}

export const TicketStatusByDepartmentMeta = [
  TicketStatus.SUBMIT_TICKET,

  TicketStatus.WAIT_FOR_APPROVE,
  TicketStatus.NEGOTIATE,
  TicketStatus.WAIT_FOR_INFORM,
  TicketStatus.WAIT_FOR_CUSTOMER_DECIDE,

  TicketStatus.APPEAL,
  TicketStatus.APPROVED,
  TicketStatus.PICKED_TO_CLAIM,
  TicketStatus.PICKED_TO_REPAIR,
  TicketStatus.PICKED_TO_SERVICE,
  TicketStatus.CREATE_INCOMPLETE_DELIVER_ORDER,
  TicketStatus.DONE
]

export enum TicketTypeAction {
  CLAIM = 'claim',
  ASK_QUESTION = 'ask_question',
  REFUND = 'refund',

  // REPAIR = 'repair',
  INCOMPLETE_DELIVER = 'incomplete_deliver',
  DISCOUNT = 'discount'
}

export enum TicketTypeActionLabel {
  CLAIM = 'เคลมสินค้า',
  ASK_QUESTION = 'สอบถามข้อมูลทั่วไป',
  REFUND = 'คืนสินค้า',
  // REPAIR = 'ซ่อมสินค้า',
  INCOMPLETE_DELIVER = 'ของไม่ครบ',
  DISCOUNT = 'ส่วนลดสินค้า'
}

export enum TicketTopicAction {
  DEFECTIVE_PRODUCT = 'defective_product',
  DEFECTIVE_PRODUCT_STRUCTURE = 'defective_product_structure',
  ISSUE_WITH_FABRIC_LEATHER = 'issue_with_fabric_leather',
  ISSUE_WITH_ELECTRICAL_SYSTEM = 'issue_with_electrical_system',
  DELIVERY_TRACKING = 'delivery_tracking',
  RECEIVED_WRONG_MODEL_DESIGN_SIZE = 'received_wrong_model_design_size',
  RECEIVED_INCOMPLETE_ORDER = 'received_incomplete_order',
  PRODUCT_FOR_REPAIR = 'product_for_repair',
  OUT_OF_STOCK_CHANGE_ITEM = 'out_of_stock_change_item',
  INCORRECT_INFO_IN_PC_SALES_MANUAL = 'incorrect_info_in_pc_sales_manual',
  INCORRECT_INFO_FROM_CS = 'incorrect_info_from_cs',
  MISUNDERSTANDING_FROM_CUSTOMER = 'misunderstanding_from_customer',
  OTHER = 'other'
}

export enum TicketTopicActionLabel {
  DEFECTIVE_PRODUCT = 'สินค้ามีตำหนิ',
  DEFECTIVE_PRODUCT_STRUCTURE = 'สินค้ามีตำหนิ โครงสร้าง',
  ISSUE_WITH_FABRIC_LEATHER = 'สินค้ามีปัญหา ผ้า/หนัง',
  ISSUE_WITH_ELECTRICAL_SYSTEM = 'สินค้ามีปัญหาที่ระบบไฟฟ้า',
  DELIVERY_TRACKING = 'ติดตามวันจัดส่ง',
  RECEIVED_WRONG_MODEL_DESIGN_SIZE = 'ได้รับสินค้าไม่ตรงรุ่น / แบบ / ไซส์',
  RECEIVED_INCOMPLETE_ORDER = 'ได้รับของไม่ครบ',
  PRODUCT_FOR_REPAIR = 'สินค้ารับซ่อมปกติ',
  OUT_OF_STOCK_CHANGE_ITEM = 'ของหมด / เปลี่ยนของ',
  INCORRECT_INFO_IN_PC_SALES_MANUAL = 'PC / Sales Manual ให้ข้อมูลผิดพลาด',
  INCORRECT_INFO_FROM_CS = 'CS ให้ข้อมูลผิดพลาด',
  MISUNDERSTANDING_FROM_CUSTOMER = 'ลูกค้าเข้าใจผิดเอง',
  OTHER = 'อื่นๆ'
}

export enum TicketAskQuestionAction {
  PACKED = 'packed',
  NOT_YET_PRODUCED = 'not_yet_produced',
  FOLLOW_UP_DELIVERY_RECEIPT = 'follow_up_delivery_receipt',
  CHANGE_DELIVERY_INFORMATION = 'change_delivery_information',
  CUSTOMER_FOLLOW_UP_REFUND = 'customer_follow_up_refund',
  ORDER_CANCELED_MARKETPLACE = 'order_canceled_marketplace',
  OUT_OF_STOCK_NOTIFY_REFUND = 'out_of_stock_notify_refund',
  OUT_OF_STOCK_NOTIFY_CHANGE_PRODUCT = 'out_of_stock_notify_change_product',
  PRODUCT_RETURNED = 'product_returned',
  CONTACT_CUSTOMER_FACTORY_CANNOT_REACH = 'contact_customer_factory_cannot_reach',
  OTHER = 'other'
}

export enum TicketAskQuestionActionLabel {
  PACKED = 'ติดตามวันจัดส่ง (ผลิตแล้ว-Packed)',
  NOT_YET_PRODUCED = 'ติดตามวันจัดส่ง (ยังไม่ได้ผลิต)',
  FOLLOW_UP_DELIVERY_RECEIPT = 'ติดตามใบกำกับภาษี',
  CHANGE_DELIVERY_INFORMATION = 'เปลี่ยนแปลงข้อมูลจัดส่ง',
  CUSTOMER_FOLLOW_UP_REFUND = 'ลูกค้าติดตามยอดโอนคืน',
  ORDER_CANCELED_MARKETPLACE = 'ออเดอร์ถูกยกเลิก (Market place)',
  OUT_OF_STOCK_NOTIFY_REFUND = 'สินค้าหมดแจ้งเปลี่ยนสินค้า (คืนเงิน)',
  OUT_OF_STOCK_NOTIFY_CHANGE_PRODUCT = 'สินค้าหมดแจ้งเปลี่ยนสินค้า (เปลี่ยนสินค้า)',
  PRODUCT_RETURNED = 'สินค้าตีกลับ',
  CONTACT_CUSTOMER_FACTORY_CANNOT_REACH = 'ติดต่อลูกค้า (กรณีที่โรงงานติดต่อลูกค้าไม่ได้)',
  OTHER = 'อื่นๆ'
}

export enum SuggestionClaimType {
  SUGGEST_TO_CLAIM_QC = 'suggest_to_claim_qc',
  SUGGEST_TO_CLAIM_REPAIR = 'suggest_to_claim_repair',
  SUGGEST_TO_CLAIM_SERVICE = 'suggest_to_claim_service',
  ENGINEER_SUGGESTION = 'engineer_suggestion'
}

export enum SuggestionClaimTypeLabel {
  SUGGEST_TO_CLAIM_QC = 'เปลี่ยนของใหม่ให้ลูกค้่า',
  SUGGEST_TO_CLAIM_REPAIR = 'รับสินค้ากลับมาซ่อม',
  SUGGEST_TO_CLAIM_SERVICE = 'บริการซ่อมนอกสถานที่',
  ENGINEER_SUGGESTION = 'ให้วิศวกรตัดสินใจ'
}

export enum TicketTypeActionDescription {
  CLAIM = 'เคลมเพื่อนำสินค้าเก่ามาเปลี่ยน และส่งสินค้าชิ้นใหม่ไปให้',
  // REPAIR = 'ซ่อมสินค้าที่อยู่ในประกันและหมดประกับ รับกลับมาและส่งกลับไป',
  INCOMPLETE_DELIVER = 'ส่งสินค้าที่ขาดตกไปเพิ่ม',
  REFUND = 'คืนสินค้าและรับเงินคืน',
  DISCOUNT = 'ให้ส่วนลดสินค้า'
}

export enum TicketModifyAction {
  UPDATE_TICKET = 'update_ticket'
}

export enum TicketError {
  NO_SHIP_FROM_DELIVERY_CHANNEL = 'no_ship_from_delivery_channel',
  NO_SHIP_TO_DELIVERY_CHANNEL = 'no_ship_to_delivery_channel',
  NO_SHIP_TO_CUSTOMER_ADDRESS = 'no_ship_to_customer_address',
  NO_EXPRESSION_AT = 'no_expression_at',
  NO_DISCOUNT = 'no_discount'
}

export enum TicketErrorLabel {
  NO_SHIP_FROM_DELIVERY_CHANNEL = 'ไม่พบช่องทางการรับกลับ',
  NO_SHIP_TO_DELIVERY_CHANNEL = 'ไม่พบช่องทางการจัดส่งกลับ',
  NO_SHIP_TO_CUSTOMER_ADDRESS = 'ไม่พบข้อมูลที่อยู่รับส่งสินค้า',
  NO_EXPRESSION_AT = 'ไม่มีวันที่ลูกค้าแจ้งปัญหา',
  NO_DISCOUNT = 'ไม่พบส่วนลด'
}

export enum GenericItemUnit {
  PIECE = 'piece',
  ITEM = 'item',
  BOX = 'box',
  BOTTLE = 'bottle',
  CAN = 'can',
  BOOK = 'book',
  SHEET = 'sheet',
  PAIR = 'pair',
  SLICE = 'slice',
  BALL = 'ball',
  LUMP = 'lump',
  HANDLE = 'handle',
  UNIT = 'unit',
  STICK = 'stick',
  RING = 'ring'
}

export enum GenericItemUnitLabel {
  PIECE = 'อัน',
  ITEM = 'ชิ้น',
  BOX = 'กล่อง',
  BOTTLE = 'ขวด',
  CAN = 'กระป๋อง',
  BOOK = 'เล่ม',
  SHEET = 'ใบ',
  PAIR = 'คู่',
  SLICE = 'แผ่น',
  BALL = 'ลูก',
  LUMP = 'ก้อน',
  HANDLE = 'ด้าม',
  UNIT = 'ตัว',
  STICK = 'แท่ง',
  RING = 'วง'
}

export enum TicketPaymentMethod {
  BANK_TRANSFER = 'bank_transfer',
  CASH = 'cash',
  TWOCTWOP = '2c2p',
  CREDIT_CARD = 'credit_card',
  // COD = 'cod',
  // PARTIAL_COD = 'partial_cod',
  // PAY_LATER = 'pay_later',
  FREE = 'free'
  // SYSTEM = 'system'
}

export enum TicketPaymentMethodLabel {
  BANK_TRANSFER = 'โอนผ่านธนาคาร',
  TWOCTWOP = '2c2p',
  CREDIT_CARD = 'บัตรเครดิต',
  // COD = 'COD เต็มจำนวน',
  // PARTIAL_COD = 'COD พร้อมมัดจำ',
  CASH = 'เงินสด',
  // PAY_LATER = 'ชำระภายหลัง',
  FREE = 'ไม่ต้องชำระเงิน'
  // SYSTEM = 'จ่ายแล้วผ่านระบบ(lnwshop, wordpress)'
}

export enum TicketListTab {
  // ALL = 'all',
  // OPEN = 'open',

  SUBMIT_TICKET = 'submit_ticket',
  WAIT_FOR_APPROVE = 'wait_for_approve',
  APPROVED = 'approved',
  // IN_PROGRESS = 'in_progress', // created_evidence
  PICKED_TO_CLAIM = 'picked_to_claim',
  PICKED_TO_REPAIR = 'picked_to_repair',
  PICKED_TO_SERVICE = 'picked_to_service',

  WAIT_FOR_INFORM = 'wait_for_inform',
  WAIT_FOR_CUSTOMER_DECIDE = 'wait_for_customer_decide',
  NEGOTIATE = 'negotiate',

  APPEAL = 'appeal',
  CREATE_INCOMPLETE_DELIVER_ORDER = 'create_incomplete_deliver_order',
  DONE = 'done', // confirm_received
  // DONE_AND_CANCELLED = 'done_and_cancelled',
  CANCELLED = 'cancelled'
}

export enum TicketListTabLabel { // can be reused with TicketStatusLabel
  // ALL = 'แสดงทั้งหมด',
  // OPEN = 'รอดำเนินการ',

  SUBMIT_TICKET = 'ส่งปัญหา',
  APPROVED = 'อนุมัติ',
  IN_PROGRESS = 'กำลังดำเนินการ', // created_evidence
  PICKED_TO_CLAIM = 'ตัดสินใจเปลี่ยนของใหม่ให้ลูกค้่า',
  PICKED_TO_REPAIR = 'ตัดสินใจรับสินค้ากลับมาซ่อม',
  PICKED_TO_SERVICE = 'ตัดสินใจบริการซ่อมนอกสถานที่',
  WAIT_FOR_APPROVE = 'รอการอนุมัติ',
  WAIT_FOR_INFORM = 'รอแจ้งลูกค้า',
  WAIT_FOR_CUSTOMER_DECIDE = 'รอลูกค้าตัดสินใจ',
  NEGOTIATE = 'ส่งให้เจรจาต่อรอง',
  APPEAL = 'ขออุทธรณ์',
  CREATE_INCOMPLETE_DELIVER_ORDER = 'สร้างคำสั่งส่งสินค้าตกหล่น',
  DONE = 'ดำเนินการสำเร็จ',
  // DONE_AND_CANCELLED = 'ประวัติรับสินค้าทั้งหมด',
  CANCELLED = 'ยกเลิก'
}

export enum TicketOrderSource {
  CLAIM_QC = 'claim-qc',
  CLAIM_REPAIR = 'claim-repair',
  CLAIM_SERVICE = 'claim-service',
  CLAIM_QC_CARRY_BACK = 'claim-qc-carry-back',
  CLAIM_INCOMPLETE_DELIVER = 'claim-incomplete-deliver'
}

export enum TicketOrderPrice {
  CLAIM_QC = 0,
  CLAIM_REPAIR = 700,
  CLAIM_SERVICE = 350
}

export const TicketOrderPriceObj = {
  CLAIM_QC: TicketOrderPrice['CLAIM_QC'],
  CLAIM_REPAIR: TicketOrderPrice['CLAIM_REPAIR'],
  CLAIM_SERVICE: TicketOrderPrice['CLAIM_SERVICE']
}

export enum sourceRef {
  PREORDER = '6246d885aec8bdd2e0fac6a5'
}

export enum sourceLabel {
  PREORDER = 'ส่งห้าง'
}

export enum DeliveryChannelName {
  SELLER_OWN_FLEET_TH = 'ผู้ขายทำการจัดส่งให้',
  SELLER_OWN_FLEET_EN = 'Seller Own Fleet'
}

export const WarehouseStatusOrdering = [
  OrderStatus.PREPICK,
  OrderStatus.PREPARED,
  OrderStatus.PICKED,
  OrderStatus.PRINTED,
  OrderStatus.PACKED,
  OrderStatus.BOARDED
]

export enum OrderPackageStatusLabel {
  OPEN = 'รับคำสั่งซื้อ',
  READY_TO_SHIP = 'พร้อมจัดการ',
  PREPICK = 'วางแผนหยิบสินค้า',
  PREPARED = 'จัดเตรียมสินค้าเรียบร้อย',
  PICKED = 'หยิบสินค้าสำเร็จ',
  PRINTED = 'พิมพ์ใบปะหน้า',
  PACKED = 'แพ็คสินค้า',
  BOARDED = 'นำขึ้นรถ',
  TRANSPORTED = 'ระหว่างขนส่ง',
  DELIVERED = 'ส่งสินค้าสำเร็จ',
  CANCELLED = 'ยกเลิกคำสั่งซื้อ',
  WAIT_RETURN = 'รอคืนสินค้า',
  WAIT_RETURN_CANCELLED = 'รอคืนสินค้าหลังส่งแล้ว',
  RETURNED = 'คืนสินค้าสำเร็จ',
  RETURNED_BY_DELIVERY_FIRM = 'คืนสินค้าสำเร็จแจ้งโดยบริษัทขนส่ง',
  DOUBTED = 'พบปัญหา',
  LOST = 'สูญหาย',
  UNKNOWN_SOURCE_STATUS = 'ไม่ทราบสถานะ',
  RESOLVED_BY_MARKETPLACE = 'ดำเนินการโดย Marketplace'
}

export enum KerryTrackingStatus {
  READY_TO_SHIP = 'พัสดุพร้อมนำส่งให้เคอรี่ เอ็กซ์เพรส',
  TRANSPORTING = 'พัสดุของคุณอยู่ระหว่างขนส่ง',
  SHIPING = 'พนักงานกำลังจัดส่งพัสดุของคุณ',
  SHIPED = 'เคอรี่จัดส่งพัสดุของคุณเรียบร้อยแล้ว',
  RETRY_SHIPING = 'อยู่ระหว่างดำเนินการเพื่อจัดส่งใหม่อีกครั้ง',
  TRACKING_NUMBER_NOT_FOUND = 'ไม่พบหมายเลขพัสดุ',
  PARCEL_SENDER_AT_BRANCH = 'ผู้ส่งมาส่งพัสดุที่สาขา',
  PICKS_UP_PARCEL = 'เคอรี่เข้ารับพัสดุแล้ว',
  PARCEL_ARRIVED = 'พัสดุของคุณถึงสาขาปลายทางแล้ว เตรียมจัดส่ง',
  PARCEL_RETURNING = 'เคอรี่กำลังตีกลับพัสดุของคุณไปยังต้นทาง',
  PARCEL_RETURNED = 'พัสดุของคุณถูกตีกลับไปยังต้นทางเรียบร้อยแล้ว'
}

export enum OrderPackageStatus {
  OPEN = 'open',
  READY_TO_SHIP = 'ready_to_ship',
  PREPICK = 'prepick',
  PREPARED = 'prepared',
  PICKED = 'picked',
  PRINTED = 'printed',
  PACKED = 'packed',
  BOARDED = 'boarded',
  TRANSPORTED = 'transported',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
  WAIT_RETURN = 'wait_return',
  WAIT_RETURN_CANCELLED = 'wait_return_cancelled',
  RETURNED = 'returned',
  RETURNED_BY_DELIVERY_FIRM = 'returned_by_delivery_firm',
  DOUBTED = 'doubted',
  LOST = 'lost',
  UNKNOWN_SOURCE_STATUS = 'unknown_source_status',
  RESOLVED_BY_MARKETPLACE = 'resolved_by_marketplace'
}

export enum OrderStatusWithAll {
  ALL = 'all',
  OPEN = 'open',
  READY_TO_SHIP = 'ready_to_ship',
  PICKED = 'picked',
  PRINTED = 'printed',
  PACKED = 'packed',
  BOARDED = 'boarded',
  TRANSPORTED = 'transported',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
  WAIT_RETURN = 'wait_return',
  RETURNED = 'returned',
  DOUBTED = 'doubted',
  LOST = 'lost',
  UNKNOWN_SOURCE_STATUS = 'unknown_source_status'
}

// Note: Changing order of this enum will affect the UI
export enum OrderPaymentMethod {
  // KBANK_QR_CODE = 'kbank_qr_code',
  BANK_TRANSFER = 'bank_transfer',
  CASH = 'cash',
  TWOCTWOP = '2c2p',
  CREDIT_CARD = 'credit_card',
  COD = 'cod',
  PARTIAL_COD = 'partial_cod',
  PAY_LATER = 'pay_later',
  FREE = 'free',
  SYSTEM = 'system',
  KBANK_QR_CODE = 'kbank_qr_code'
}

export enum OrderPaymentMethodLabel {
  BANK_TRANSFER = 'โอนผ่านธนาคาร',
  TWOCTWOP = '2c2p',
  CREDIT_CARD = 'บัตรเครดิต',
  COD = 'COD เต็มจำนวน',
  PARTIAL_COD = 'COD พร้อมมัดจำ',
  CASH = 'เงินสด',
  PAY_LATER = 'ชำระภายหลัง',
  FREE = 'ไม่ต้องชำระเงิน (ต้องให้หัวหน้า approve)',
  SYSTEM = 'จ่ายแล้วผ่านระบบ(lnwshop, wordpress)',
  KBANK_QR_CODE = 'QR Code ธนาคารกสิกรไทย'
}

export enum OrderPaymentStatus {
  UNPAID = 'unpaid',
  COD = 'cod',
  PARTIAL_COD = 'partial_cod',
  PARTIAL = 'partial',
  PAID = 'paid',
  TRANSFERRED = 'transferred',
  FREE = 'free',
  CANCELLED = 'cancelled',
  WAIT_REFUND = 'wait_refund',
  REFUNDED = 'refunded',
  COMPENSATED = 'compensated',
  REJECTED = 'rejected'
}

export enum OrderPaymentStatusLabel {
  UNPAID = 'ยังไม่ชำระ',
  COD = 'เก็บเงินปลายทาง',
  PARTIAL_COD = 'COD พร้อมมัดจำ',
  PARTIAL = 'ชำระบางส่วน',
  PAID = 'ชำระสำเร็จ (p)',
  TRANSFERRED = 'ชำระสำเร็จ (t)',
  FREE = 'ไม่ต้องชำระเงิน',
  CANCELLED = 'ยกเลิกการชำระเงิน',
  WAIT_REFUND = 'รอคืนเงิน',
  REFUNDED = 'คืนเงินสำเร็จ',
  COMPENSATED = 'ได้รับเงินชดเชย',
  REJECTED = 'rejected'
}

export enum OrderBillingMode {
  NONE = 'none',
  DEFAULT = 'default',
  CUSTOM = 'custom'
}

export enum OrderIssueType {
  SHOPEE_IN_CANCEL = 'shopee_in_cancel',
  UNKNOWN_SOURCE_STATUS = 'unknown_source_status',
  OUT_OF_STOCK = 'out_of_stock',
  WRONG_ITEM = 'wrong_item',
  OTHERS = 'others'
}

export enum OrderIssueLabel {
  SHOPEE_IN_CANCEL = 'ลูกค้า Shopee ขอยกเลิก',
  UNKNOWN_SOURCE_STATUS = 'ไม่ทราบสถานะ',
  OUT_OF_STOCK = 'ของหมด',
  WRONG_ITEM = 'สินค้าผิด',
  OTHERS = 'อื่นๆ'
}

export enum OrderIssueStatus {
  SUBMIT_TICKET = 'submit_ticket',

  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  RESOLVED = 'resolved'
}

export enum OrderIssueStatusLabel {
  SUBMIT_TICKET = 'ส่งปัญหา',
  OPEN = 'รอดำเนินการ',
  IN_PROGRESS = 'กำลังดำเนินการ',
  RESOLVED = 'ดำเนินการแล้ว'
}

export enum ACTION_VAT {
  NONE = 'none',
  EXCLUDE = 'exclude',
  INCLUDE = 'include'
}

export enum ACTION_VAT_LABEL {
  NONE = 'ไม่มีภาษี',
  EXCLUDE = 'แยกภาษีมูลค่าเพิ่ม 7% (ขอใบกำกับภาษีได้)',
  INCLUDE = 'รวมภาษีมูลค่าเพิ่ม 7% (ขอใบกำกับภาษีได้)'
}

export enum PRODUCT_AMOUNT_FIELD {
  AVAILABLE = 'available',
  POTENTIAL = 'potential',
  ACTUAL = 'actual',
  PENDING_ORDER = 'pending_order',
  FAKE_AMOUNT = 'fake_amount',
  FAKE_REMAINING = 'fakeRemaining'
}

export enum PRODUCT_AMOUNT_FIELD_LABEL {
  AVAILABLE = 'พร้อมขาย',
  POTENTIAL = 'ผลิตได้อีก',
  ACTUAL = 'คงเหลือ',
  FAKE_AMOUNT = 'หลอก'
}

export enum SUMMARY_LOG_RESULT {
  SUCCESSFUL = 'successful',
  PARTIAL_FAILURE = 'partial_failure',
  FAILED = 'failed'
}

export enum ScanToUpdateOrderStatusResultType {
  SUCCESS = 'successful',
  SUSPEND = 'suspend',
  UNPACK = 'unpack',
  NOT_FOUND = 'notfound',
  UNHANDLED = 'unhandled',
  ERROR = 'error'
}

export const COMPANY_NAME = 'บริษัท ฟิวเจอร์ เมคเกอร์ จำกัด'
export const COMPANY_NAME_EN = 'FUTURE MAKERS COMPANY LIMITED'
export const COMPANY_ADDRESS = '298/1 ซอย วัดอนงค์ ถ.สมเด็จเจ้าพระยา แขวงสมเด็จเจ้าพระยา เขตคลองสาน กรุงเทพมหานคร 10600'
export const COMPANY_PHONE_NUMBER = '089-512-5421'
export const COMPANY_PHONE_NUMBER_CONTACT1 = '02-114-3408'
// export const COMPANY_PHONE_NUMBER_CONTACT2 = '093-140-1711'
// export const COMPANY_PHONE_NUMBER_CONTACT3 = '063-214-5205'
export const COMPANY_EMAIL = 'info@futuremakers.co.th'

export const COMPANY_NAME_NO_VAT = 'Future Living'
export const COMPANY_PHONE_NUMBER_NO_VAT = '063-214-5205'
export const COMPANY_MERCHANT_NAME = 'Future Living Thailand'

export enum OrderDocumentSize {
  A4 = 'a4',
  A5 = 'a5',
  C6 = 'c6',
  STICKER = 'sticker',
  STICKER_ADMIN = 'sticker-admin',
  STICKER_SINGLE = 'sticker-single'
}

export enum OrderDocumentType {
  SHIPPING_LABEL = 'shipping_label',
  RETRIEVAL_LABEL = 'retrieval_label',
  CONTENT_LIST = 'content_list',
  RECEIPT = 'receipt',
  POS_RECEIPT = 'pos_receipt',
  OVERDUE_BILL = 'overdue_bill',
  DELIVERY_NOTE = 'delivery_note',
  SOF_NOTE = 'seller_own_fleet_note',
  SHIPPER_ORDER_RETURN = 'shipper_order_return',
  SALARY_REPORT = 'salary_report',
  COMMISSION_PAID = 'commission_paid'
}

export enum OrderParamType {
  PRINT = 'print_all',
  PICKING = 'picking'
}

export enum OrderPrintListTab {
  PRINT_INFO = 'print_info',
  PDF_VIEW = 'pdf_view',
  ALL_ORDERS = 'all_orders',
  REJECTED_PRINTING = 'rejected_printing'
}

export enum OrderPrintListTabLabel {
  PRINT_INFO = '👀 ใบปะหน้าส่งของ 📝',
  PDF_VIEW = '👀 ใบปะหน้าจากไฟล์แนบ 📎',
  ALL_ORDERS = 'คำสั่งซื้อที่เลือก',
  REJECTED_PRINTING = 'Rejected Printing'
}

export enum PickingPrintListTab {
  PRINT_INFO = 'print_info',
  PDF_VIEW = 'pdf_view',
  ALL_ORDERS = 'all_orders',
  CANCEL_ORDERS = 'cancel_status',
  REJECTED_PRINTING = 'rejected_printing',
  FAILED_SHIPPING_LABEL = 'failed_shipping_label'
}

export enum PickingPrintListTabLabel {
  PRINT_INFO = '👀 ใบปะหน้าส่งของ 📝',
  PDF_VIEW = '👀 ใบปะหน้าจากไฟล์แนบ 📎',
  ALL_ORDERS = 'คำสั่งซื้อที่เลือก',
  CANCEL_ORDERS = 'คำสั่งซื้อมีปัญหา',
  REJECTED_PRINTING = 'Rejected Printing',
  FAILED_SHIPPING_LABEL = 'มีปัญหาใบปะหน้า'
}

export enum PrintButtonType {
  PRINT_ALL = 'print_all',
  PRINT_ALL_SHOPEE = 'print_all_shopee',
  PRINT_ALL_LAZADA = 'print_all_lazada',
  PRINT_ALL_TIKTOK = 'print_all_tiktok',
  PRINT_ALL_MANUAL = 'print_all_manual',
  PRINT_SHOPEE = 'print_shopee',
  PRINT_LAZADA = 'print_lazada',
  PRINT_TIKTOK = 'print_tiktok',
  PRINT = 'print',
  SELECTED_ORDER = 'selected'
}

export const printButtonLabel = {
  [PrintButtonType.PRINT_ALL]: 'ไปหน้าใบปะหน้า',
  [PrintButtonType.PRINT_ALL_MANUAL]: 'พิมพ์ manual/SOF ทั้งหมด',
  [PrintButtonType.PRINT_ALL_SHOPEE]: 'พิมพ์ shopee ทั้งหมด',
  [PrintButtonType.PRINT_ALL_LAZADA]: 'พิมพ์ lazada ทั้งหมด',
  [PrintButtonType.PRINT_ALL_TIKTOK]: 'พิมพ์ tiktok ทั้งหมด',
  [PrintButtonType.PRINT_SHOPEE]: 'พิมพ์ shopee',
  [PrintButtonType.PRINT_LAZADA]: 'พิมพ์ lazada',
  [PrintButtonType.PRINT_TIKTOK]: 'พิมพ์ tiktok',
  [PrintButtonType.SELECTED_ORDER]: 'เลือก',
  [PrintButtonType.PRINT]: 'พิมพ์'
}

export enum ActionCRUD {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum AuditLogType {
  PRODUCT = 'product',
  ORDER = 'order',
  ORDER_ISSUE = 'order_issue',
  INVENTORY = 'inventory',
  INVENTORY_ADJUSTMENT_ORDER = 'inventory_adjustment_order',
  INVENTORY_TRANSFER_ORDER = 'inventory_transfer_order',
  RESERVE_ORDER = 'reserve_order',
  PICKING_LIST = 'picking_list',
  ORDER_SOURCE = 'order_source',
  DELIVERY_CHANNEL = 'delivery_channel',
  CATEGORY = 'category',
  PRODUCT_ISSUE_REPORT = 'product_issue_report',
  PRODUCTION_ORDER = 'production_order',
  ROLE = 'role',
  WAREHOUSE = 'warehouse',
  USER = 'user',
  WORKSPACE = 'workspace',
  WORKSPACE_MEMBER = 'workspace_member',
  STOCK_PRODUCT = 'stock_product',
  STOCK_PRODUCT_DOCUMENT = 'stock_product_document',
  STOCK_PRODUCT_QR_CODE = 'stock_product_qr_code',

  // qr code payment
  PAYMENT_QR_CODE = 'payment_qr_code'
}

export enum AuditLogAction {
  INSERT = 'insert', // 'insert' instead of 'create' to stay consistent with mongoose
  UPSERT = 'upsert',
  UPDATE = 'update',
  DELETE = 'delete',

  // unique information log
  UPDATE_INVENTORY = 'update_inventory',
  SYNC_INVENTORY = 'sync_inventory',
  SYNC_INVENTORY_SUMMARY = 'sync_inventory_summary',
  SCAN_TO_CHANGE_STATUS = 'scan_to_change_status', // should be in order action

  // old ones
  ADD_ORDER = 'add_order',
  EDIT_ORDER = 'edit_order',
  DELETE_ORDER = 'delete_order',
  ADD_ORDER_ISSUE = 'add_order_issue',
  EDIT_ORDER_ISSUE = 'edit_order_issue',
  DELETE_ORDER_ISSUE = 'delete_order_issue',
  EDIT_PRODUCT = 'edit_product',

  AUDIT = 'audit'
}

export enum InventoryAction {
  // manual
  STOCK_ADJUSTMENT = 'stock_adjustment', // product edit
  STOCK_ADJUSTMENT_PICKING_LIST = 'stock_adjustment_picking_list', // adjust stock from system
  // batch stock edit
  INSPECTION = 'inspection',
  BATCH_ADD = 'batch_add',
  BATCH_REDUCE = 'batch_reduce',
  PRODUCTION_ORDER = 'production_order',
  FINISHED_GOODS_DEPOSIT = 'finished_goods_deposit',
  RAW_MATERIAL_WITHDRAW = 'raw_material_withdraw',
  RAW_MATERIAL_DEPOSIT = 'raw_material_deposit',
  TRANSFER_IN_INVENTORY_ORDER = 'transfer_in_inventory_order',
  TRANSFER_OUT_INVENTORY_ORDER = 'transfer_out_inventory_order',
  TRANSFER_INTERNAL_ORDER = 'transfer_internal_order',
  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW = 'production_order_raw_material_withdraw',
  PRODUCTION_ORDER_RAW_MATERIAL_RETURN = ' production_order_raw_material_return',
  TRANSFER_ORDER = 'transfer_order',
  PRODUCT_ISSUE_REPORT = 'product_issue_report',
  //auto
  PAID_CUSTOMER_ORDER = 'paid_customer_order',
  UNBOARDED_CUSTOMER_ORDER = 'unboarded_customer_order', // boarded -> packed
  BOARDED_CUSTOMER_ORDER = 'boarded_customer_order',
  PICKED_CUSTOMER_ORDER = 'picked_customer_order', //prepick -> picked
  CANCELLED_CUSTOMER_ORDER = 'cancelled_customer_order',
  RETURNED_CUSTOMER_ORDER = 'returned_customer_order',
  EDITED_CUSTOMER_ORDER = 'edited_customer_order',
  RESERVED = 'reserved',
  PURCHASE_ORDER = 'purchase_order',
  EXPIRED_RESERVATION = 'expired_reservation',
  CANCELLED_RESERVATION = 'cancelled_reservation',
  APPROVED_RESERVATION = 'approved_reservation',
  MATERIAL_CHANGE = 'material_change', //for backward compat
  MATERIAL_CHANGE_ACTUAL = 'material_change_actual',
  MATERIAL_CHANGE_AVAILABLE = 'material_change_available',
  AUTOMATIC_STOCK_CORRECTION = 'automatic_stock_correction',
  BORROW_RETURN = 'borrow_return',
  INSERT_WORKING_TIME = 'insert_working_time',
  FAKE_REFILL_STOCK = 'fake_refill_stock'
}

export enum ProductTrackingAction {
  PRODUCTION_ORDER = 'production_order',
  TRANSFER_IN_INVENTORY_ORDER = 'transfer_in_inventory_order',
  PICKING_LIST_ORDER = 'picking_list_order',
  CANCEL_ORDER = 'cancel_order',
  DELIVERY_ORDER = 'delivery_order',
  LINKING_ORDER = 'linking_order',
  MADE_TO_ORDER = 'made_to_order'
}

export enum ProductTrackingActionLabel {
  PRODUCTION_ORDER = 'ผลิตสินค้า',
  TRANSFER_IN_INVENTORY_ORDER = 'แจ้งโอนสินค้า',
  PICKING_LIST_ORDER = 'หยิบสินค้า',
  CANCEL_ORDER = 'ยกเลิกคำสั่งซื้อ',
  DELIVERY_ORDER = 'ใบสั่งจัดส่ง'
}

export enum OrderAction {
  ADD_ORDER = 'add_order',
  UPDATE_ORDER_FROM_SALESLEAD = 'update_order_from_saleslead',
  UPSERT_ORDER_FROM_SALESLEAD = 'upsert_order_from_saleslead',
  UPSERT_ORDER = 'upsert_order',
  DELETE_ORDER = 'delete_order',
  GET_SHIPPING_LABEL = 'get_shipping_label',
  SELLER_OWN_FLEET_TRACKING_NO = 'seller_own_fleet_tracking_no',
  // edit
  SET_DELIVERED_BY_USER = 'set_delivered_by_user',
  OVERRIDE_CHANGE_STATUS = 'override_change_status',
  CHANGE_STATUS = 'change_status',
  RETURN_ORDER = 'return_order',
  WAIT_RETURN = 'wait_return',
  CHANGE_PACKAGE_INFO = 'change_package_info',
  UPDATE_ORDER_DETAILS = 'update_order_details',
  UPDATE_PLAIN_ORDER = 'update_plain_order',
  MODIFY_ITEMS = 'modify_items',
  MODIFY_PACKAGES_ITEMS = 'modify_packages_items',
  SPLIT_PACKAGES = 'split_packages',
  MODIFY_EXPENSE = 'modify_expenses',
  EDIT_PAYMENT = 'edit_payment',
  ADD_ISSUE = 'add_new_issue',
  DELETE_ISSUE = 'delete_issue',
  REQUEST_TRACKING_NUMBER_KERRY = 'request_tracking_number_kerry',
  REQUEST_TRACKING_NUMBER_KERRY_REASON = 'request_tracking_number_kerry_reason',
  UPDATE_STATUS_FROM_KERRY_API = 'update_status_from_kerry_api',
  UPDATE_STATUS_FROM_SCAN_API = 'update_status_from_scan_api',
  UPDATE_STATUS_FROM_SCAN_API_SINGLE = 'update_status_from_scan_api_single',
  SET_SHIPPED_SHOPEE = 'set_shipped_shopee',
  OVERRIDE_SET_SHIPPED_SHOPEE = 'override_set_shipped_shopee',

  RESET_ORDER_PRINT_COUNT = 'reset_order_print_count',
  RESET_PACKAGE_PRINT_COUNT = 'reset_package_print_count',
  UPDATE_QR_CODE_PAYMENT = 'update_qr_code_payment'
}

export enum ProductTrackingLinkingOrderAction {
  ATTACH_ORDER = 'attach_order',
  ATTACH_ORDER_WITH_TRACKING = 'attach_order_with_tracking',
  ATTACH_ORDER_WITH_PACKAGE_ID = 'attach_order_with_package_id',
  ATTACH_ORDER_WITH_TRACKING_BY_SCAN_PRINTING = 'attach_order_with_tracking_by_scan_printing',
  ATTACH_ORDER_WITH_PACKAGE_ID_BY_SCAN_PRINTING = 'attach_order_with_package_id_by_scan_printing',
  DETACH_ORDER = 'detach_order',
  DETACH_ORDER_WITH_TRACKING = 'detach_order_with_tracking',
  DETACH_ORDER_WITH_PACKAGE_ID = 'detach_order_with_package_id'
}

export enum ProductIssueType {
  UNUSED_PRODUCT = 'unused_product',
  LOST_PRODUCT = 'lost_product'
}

export enum ProductIssueTypeLabel {
  UNUSED_PRODUCT = 'สินค้าชำรุด',
  LOST_PRODUCT = 'สินค้าสูญหาย'
}

export enum ProductResolveMethod {
  REPAIR = 'repair',
  DESTROY = 'destroy'
}

export enum ProductResolveMethodLabel {
  REPAIR = 'นำไปซ่อมแซม',
  DESTROY = 'ทำลายสินค้า'
}

export enum InventoryActionLabel {
  // manual
  STOCK_ADJUSTMENT = 'แก้เลขคลัง',
  STOCK_ADJUSTMENT_PICKING_LIST = 'แก้เลขคลังจากใบหยิบ',
  PURCHASE_ORDER = 'คำสั่งซื้อฝ่ายจัดซื้อ',
  PRODUCTION_ORDER = 'คำสั่งผลิต',
  BORROW_RETURN = 'ยืม/คืน',
  INSPECTION = 'ปรับปรุงคลัง ',
  BATCH_ADD = 'รับเข้าคลัง ',
  BATCH_REDUCE = 'เบิกออกคลัง ',
  BATCH_REDUCE_ACTUAL = 'ยืนยันเบิกออกคลัง:', //label from appending field suffix
  BATCH_REDUCE_AVAILABLE = 'อนุมัติเบิกออกคลัง:', //label from appeding field suffix
  TRANSFER_ORDER = 'การแปลงสินค้า',
  EDITED_CUSTOMER_ORDER = 'แก้ไขคำสั่งซื้อ',
  UNBOARDED_CUSTOMER_ORDER = 'ปรับ packed มา boarded',
  //auto
  PAID_CUSTOMER_ORDER = 'คำสั่งซื้อใหม่ยืนยันจ่ายเงิน',
  BOARDED_CUSTOMER_ORDER = 'คำสั่งซื้อขึ้นรถขนส่ง',

  PICKED_CUSTOMER_ORDER = 'หยิบเพื่อจัดส่งให้ลูกค้า',

  CANCELLED_CUSTOMER_ORDER = 'รับเข้าคลังคำสั่งซื้อยกเลิก',
  RETURNED_CUSTOMER_ORDER = 'รับเข้าคลังยืนยันคำสั่งซื้อคืน',
  RESERVED = 'จอง',
  EXPIRED_RESERVATION = 'การจองหมดอายุ',
  CANCELLED_RESERVATION = 'ยกเลิกการจอง',
  APPROVED_RESERVATION = 'ยืนยันการจอง',
  MATERIAL_CHANGE = 'พร้อมขายคำนวณใหม่ (คงเหลือลบคำสั่งซื้อดำเนินการ)',
  MATERIAL_CHANGE_AVAILABLE = 'พร้อมขายคำนวณใหม่ (คงเหลือลบคำสั่งซื้อดำเนินการ)',
  MATERIAL_CHANGE_POTENTIAL = 'พร้อมขายคำนวณใหม่ (คงเหลือวัตถุดิบลบคำสั่งซื้อดำเนินการ)',
  AUTOMATIC_STOCK_CORRECTION = 'ปรับปรุงเลขคลังอัตโนมัติ',
  FAKE_REFILL_STOCK = 'ปรับปรุงสต๊อกหลอก'
}

export enum OrderIssueAction {
  ADD_COMMENT = 'add_comment'
}

export enum ProductionType {
  ASSEMBLY = 'assembly',
  DISASSEMBLY = 'disassembly'
}

export enum ReserveOrderStatus {
  ALL = '',
  WAIT_APPROVAL = 'wait_approval',
  APPROVED = 'approved',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled'
}

export enum ReserveOrderStatusLabel {
  ALL = 'การจองทั้งหมด',
  WAIT_APPROVAL = 'รอยืนยัน',
  APPROVED = 'ยืนยันแล้ว',
  EXPIRED = 'หมดอายุ',
  CANCELLED = 'ยกเลิก'
}

export enum ProductTrackingActionStatus {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELLED = 'cancelled'
}

export enum ProductTrackingActionStatusLabel {
  TODO = 'รอดำเนินการ',
  IN_PROGRESS = 'กำลังดำเนินการ',
  DONE = 'ดำเนินการแล้ว',
  CANCELLED = 'ยกเลิก'
}

export enum ProductionOrderStatus {
  ALL = 'all',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELLED = 'cancelled'
}

export enum ProductionOrderStatusLabel {
  ALL = 'ใบผลิตทั้งหมด',
  OPEN = 'รอดำเนินการ',
  IN_PROGRESS = 'กำลังดำเนินการ',
  DONE = 'ดำเนินการแล้ว',
  CANCELLED = 'ยกเลิก'
}

export enum ProductionOrderStatusMeta {
  ALL = 'all',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELLED = 'cancel'
}

export enum NameTabTransferInInventoryOrderLabel {
  ALL = 'ประวัติโอนสินค้าทั้งหมด',
  OPEN = 'รอตรวจสอบ',
  CHECKED = 'ขึ้นรถแล้ว',
  IN_PROGRESS = 'กำลังตรวจสอบ',
  DONE = 'โอนสินค้าสำเร็จ',
  CANCELLED = 'ยกเลิก'
}

export enum NameTabTransferInInventoryOrder {
  ALL = '',
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  CANCELLED = 'cancelled'
}

export enum InvAdjustmentOrderStatus {
  ALL = '',
  WAIT_APPROVAL = 'wait_approval',
  WAIT_CONFIRM = 'wait_confirm',
  APPROVED = 'approved',
  CONFIRMED = 'confirmed',
  DRAFT = 'draft',
  OPEN = 'open',
  CHECKED = 'checked',
  IN_PROGRESS = 'in_progress',
  PREPARATION = 'preparation',
  SHIPPING = 'shipping',
  DONE = 'done',
  CANCELLED = 'cancelled'
}

export enum InvAdjustmentOrderStatusLabel {
  WAIT_APPROVAL = 'รอตรวจสอบ',
  APPROVED = 'รอยืนยัน',
  WAIT_CONFIRM = 'รอวิศวกรตรวจสอบ',
  CONFIRMED = 'ยืนยันแล้ว',
  DRAFT = 'รอระบุข้อมูล',
  OPEN = 'รอดำเนินการ',
  IN_PROGRESS = 'กำลังดำเนินการ',
  PREPARATION = 'คลังเตรียมของ',
  SHIPPING = 'กำลังจัดส่ง',
  DONE = 'ดำเนินการแล้ว',
  CANCELLED = 'ยกเลิก',
  UNKNOWN = 'unknown status',
  CHECKED = 'ขึ้นรถแล้ว'
}

export enum WithdrawRawMaterialStatus {
  OPEN = 'open',
  WAIT_FOR_APPROVAL = 'wait_approval',
  PREPARATION = 'preparation',
  SHIPPING = 'shipping',
  SUCCESS = 'done',
  CANCELLED = 'cancelled',
  HISTORY = 'history'
}

export enum WithdrawRawMaterialStatusLabel {
  OPEN = 'รอดำเนินการ',
  WAIT_FOR_APPROVAL = 'รอวิศวะกรอนุมัติ',
  PREPARATION = 'คลังเตรียมของ',
  SHIPPING = 'กำลังจัดส่ง',
  SUCCESS = 'สำเร็จ',
  CANCELLED = 'ยกเลิก',
  HISTORY = 'ประวัติการเบิก'
}

export enum DepositRawMaterialStatus {
  WAIT_APPROVAL = 'wait_approval',
  CANCELLED = 'cancelled',
  APPROVED = 'approved',
  DONE = 'done',
  HISTORY = 'history'
}

export enum DepositRawMaterialStatusLabel {
  WAIT_APPROVAL = 'รออนุมัติ',
  CANCELLED = 'รอดำเนินการ',
  APPROVED = 'อนุมัติแล้ว',
  DONE = 'ดำเนินการแล้ว',
  HISTORY = 'ประวัติการรับ'
}

export enum rawMaterialTabLabel {
  PR = 'วัตถุดิบตามรายการขอซื้อ',
  PO = 'วัตถุดิบที่สั่งซื้อแล้ว'
}

export enum rawMaterialTab {
  PR = 'purchase_request',
  PO = 'purchase_order'
}

export enum rawMaterialPRTabLabel {
  ALL = 'วัตถุดิบทั้งหมด',
  SELECTED = 'สินค้าที่เลือก'
}

export enum rawMaterialPRTab {
  ALL = 'all',
  SELECTED = 'selected'
}

export enum WithdrawOrderStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  ALL = '',
  CANCELLED = 'cancelled'
}

export enum WithdrawOrderStatusLabel {
  OPEN = 'รอตรวจสอบ',
  IN_PROGRESS = 'รอยืนยันเบิกสินค้า',
  DONE = 'ยืนยันแล้ว',
  ALL = 'ประวัติใบเบิก',
  CANCELLED = 'ยกเลิก',
  DONE_BTN = 'ยืนยันการเบิกสินค้า',
  CANCELLED_BTN = 'ยกเลิกใบเบิกสินค้า',
  DONE_NOTI = 'ยืนยันรับสินค้าเรียบร้อยแล้ว',
  CANCELED_NOTI = 'ใบเบิกสินค้าถูกยกเลิกเรียบร้อยแล้ว',
  EDITED_NOTI = 'แก้ไขใบเบิกสินค้าเรียบร้อยแล้ว'
}

export enum WithdrawOrderDescribe {
  OPEN = 'รอตรวจสอบสินค้าในคลัง',
  IN_PROGRESS = 'รอเพิ่มหลักฐานในการรับสินค้า',
  DONE = 'ผู้ขอเบิกได้รับสินค้าแล้ว',
  CANCELLED = 'ยกเลิกการเบิกสินค้า'
}

export enum DepositOrderStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  ALL = '',
  CANCELLED = 'cancelled'
}

export enum DepositOrderStatusLabel {
  OPEN = 'รอตรวจสอบ',
  IN_PROGRESS = 'รอยืนยันรับสินค้า',
  DONE = 'ยืนยันแล้ว',
  ALL = 'ประวัติใบรับสินค้า',
  CANCELLED = 'ยกเลิก',
  DONE_BTN = 'ยืนยันการรับสินค้า',
  CANCELLED_BTN = 'ยกเลิกใบรับสินค้า',
  DONE_NOTI = 'ยืนยันรับสินค้าเรียบร้อยแล้ว',
  CANCELED_NOTI = 'ใบรับสินค้าถูกยกเลิกเรียบร้อยแล้ว',
  EDITED_NOTI = 'แก้ไขใบรับสินค้าเรียบร้อยแล้ว'
}

export enum DepositOrderDescribe {
  OPEN = 'รอตรวจสอบสินค้าในคลัง',
  IN_PROGRESS = 'รอเพิ่มหลักฐานในการรับสินค้า',
  DONE = 'ผู้ขอรับได้รับสินค้าแล้ว',
  CANCELLED = 'ยกเลิกการรับสินค้า'
}

export enum InvAdjustmentOrderStatusMeta {
  ALL = 'all',
  WAIT_APPROVAL = 'waitApproval',
  APPROVED = 'approved',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled'
}

export enum OrderCancelReason {
  CHANGE_OF_MIND = 'change_of_mind',
  CHANGE_ADDRESS = 'change_address',
  CHANGE_ITEMS = 'change_items',
  CHANGE_PAYMENT_METHOD = 'change_payment_method',
  CANNOT_BE_DELIVERED = 'cannot_be_delivered',
  DUPLICATE = 'duplicate',
  SLOW_SHIPPING = 'slow_shipping',
  CHEAPER_ALTERNATIVE = 'cheaper_alternative',
  UNRESPONSIVE_SELLER = 'unresponsive_seller',
  QUALITY_NOT_MATCHED = 'quality_not_matched',
  OTHERS = 'others',
  UNKNOWN = 'unknown' // when shopee or lazada does not give reason
}

export enum OrderCancelReasonLabel {
  CHANGE_OF_MIND = 'ลูกค้าเปลี่ยนใจ ไม่ต้องการซื้อสินค้าแล้ว',
  CHANGE_ADDRESS = 'ลูกค้าต้องการเปลี่ยนที่อยู่ในการจัดส่ง',
  CHANGE_ITEMS = 'ลูกค้าต้องการเปลี่ยนสินค้าที่สั่งซื้อ',
  CHANGE_PAYMENT_METHOD = 'ลูกค้าต้องการเปลี่ยนช่องทางชำระเงิน',
  CANNOT_BE_DELIVERED = 'ไม่สามารถจัดส่งสินค้าได้',
  DUPLICATE = 'คำสั่งซื้อซ้ำ',
  SLOW_SHIPPING = 'ใช้เวลาในการจัดส่งสินค้านานเกินไป',
  CHEAPER_ALTERNATIVE = 'พบผู้ขายอื่นขายสินค้าในราคาที่ถูกกว่า',
  UNRESPONSIVE_SELLER = 'ลูกค้าไม่สามารถติดต่อกับทางผู้ขายได้',
  QUALITY_NOT_MATCHED = 'สินค้าไม่ตรงกับความเป็นจริง',
  OTHERS = 'อื่นๆ',
  UNKNOWN = 'ไม่ทราบเหตุผลการยกเลิก'
}

export enum OrderFeeType {
  COMMISSION_FEE = 'commission_fee',
  SERVICE_FEE = 'service_fee',
  TRANSACTION_FEE = 'transaction_fee'
}

export enum OrderExpenseType {
  SHOPEE_COMMISSION_FEE = 'shopee_commission_fee',
  SHOPEE_SERVICE_FEE = 'shopee_service_fee',
  LAZADA_COMMISSION_FEE = 'lazada_commission_fee',
  SHIPPING_FEE_DISCOUNT_PLATFORM = 'shipping_fee_discount_platform',
  LCP_FEE = 'lazada_cashback_program_fee',
  WRONG_WEIGHT_ADJUSTMENT = 'wrong_weight_adjustment',
  PAYMENT_FEE = 'payment_fee',
  ORIGINAL_SHOPEE_DISCOUNT = 'original_shopee_discount',
  SHOPEE_SHIPPING_REBATE = 'shopee_shipping_rebate',
  VOUCHER_PLATFORM = 'voucher_platform',
  VOUCHER_SELLER_LPI = 'voucher_seller_lpi',
  TIKTOK_COMMISSION_FEE = 'tiktok_commission_fee',
  TIKTOK_TRANSACTION_FEE = 'tiktok_transaction_fee',
  TIKTOK_SERVICE_FEE = 'tiktok_service_fee',
  OTHERS = 'others'
}

export enum OrderExpenseTypeLabel {
  SHOPEE_COMMISSION_FEE = 'ค่านายหน้าจาก Shopee',
  SHOPEE_SERVICE_FEE = 'ค่าธรรมเนียมจาก Shopee',
  LAZADA_COMMISSION_FEE = 'ค่านายหน้าจาก Lazada',
  SHIPPING_FEE_DISCOUNT_PLATFORM = 'ส่วนลดค่าจัดส่ง Lazada',
  LCP_FEE = 'ค่าธรรมเนียมเครดิตเงินคืนสนับสนุนโดยผู้ขาย',
  WRONG_WEIGHT_ADJUSTMENT = 'wrong weight adjustment',
  PAYMENT_FEE = 'ค่าธรรมเนียมการจ่ายเงิน',
  ORIGINAL_SHOPEE_DISCOUNT = 'ส่วนลดราคาสินค้าจาก Shopee',
  SHOPEE_SHIPPING_REBATE = 'ค่าจัดส่งสินค้าที่ชำระโดย Shopee',
  VOUCHER_PLATFORM = 'ส่วนลดราคาสินค้าจาก Lazada',
  VOUCHER_SELLER_LPI = 'ส่วนลด Co-Pay จากร้านค้า',
  TIKTOK_COMMISSION_FEE = 'ค่านายหน้าจาก Tiktok',
  TIKTOK_TRANSACTION_FEE = 'ค่าธรรมเนียมการจ่ายเงิน Tiktok',
  TIKTOK_SERVICE_FEE = 'ค่าธรรมเนียมจาก Tiktok',
  OTHERS = 'อื่นๆ'
}

export enum RoleAction {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  CANCEL = 'cancel',
  IMPORT = 'import',
  EXPORT = 'export'
}

export enum RoleActionLabel {
  READ = 'ดูข้อมูล',
  CREATE = 'เพิ่มข้อมูล',
  UPDATE = 'แก้ไขข้อมูล',
  DELETE = 'ลบข้อมูล',
  CANCEL = 'ยกเลิกรายการ',
  IMPORT = 'Import File',
  EXPORT = 'Export File'
}

export enum RoleFeature {
  REPORT = 'report',
  PRODUCT = 'product',
  ORDER = 'order',
  STOCK = 'stock',
  SETTING = 'setting',
  SALE = 'sale',
  TDS = 'tds',
  PRODUCTION_ORDER = 'production_order', //MF
  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW = 'production_order_raw_material_withdraw', //MF
  PRODUCTION_PLAN = 'production_plan',
  CHANGE_DELIVERY_CHANNEL = 'change_delivery_channel',
  TRANSFER_INTERNAL_ORDER = 'transfer_internal_order', // TFG
  STOCK_AUDIT_CYCLE_CHECK = 'stock_audit_cycle_check', // ACC
  TRANSFER_OUT_INVENTORY_ORDER = 'transfer_out_inventory_order', // TFW
  FAKE_SHIPPED = 'fake_shipped',
  MTO = 'mto'
}

export enum RoleFeatureLabel {
  REPORT = 'รายงาน',
  PRODUCT = 'สินค้า',
  ORDER = 'รายการสั่งซื้อ',
  STOCK = 'ควบคุมสต็อก',
  SETTING = 'การตั้งค่า',
  SALE = 'การขาย',
  PRODUCTION_ORDER = 'ผลิตสินค้า',
  PRODUCTION_ORDER_RAW_MATERIAL_WITHDRAW = 'RWMF',
  PRODUCTION_PLAN = 'แผนการผลิต',
  CHANGE_DELIVERY_CHANNEL = 'เปลี่ยนช่องทางขนส่ง',
  TRANSFER_INTERNAL_ORDER = 'แก้ไขใบ TFG',
  STOCK_AUDIT_CYCLE_CHECK = 'audit คลัง',
  FAKE_SHIPPED = 'จัดส่งหลอก',
  MTO = 'ผลิตตามสั่ง'
}

export enum WorkspaceMemberStatus {
  ACTIVE = 'active',
  IN_ACTIVE = 'in_active'
}

export enum ProductReportTab {
  SHORT_STOCK = 'short_stock',
  DEAD_STOCK = 'dead_stock'
}

export enum ProductReportLabel {
  SHORT_STOCK = 'สินค้าขาดสต๊อก',
  DEAD_STOCK = 'สินค้าที่ขายไม่ได้'
}

export enum SalesLeadTab {
  ALL = 'all',
  UNCONFIRMED = 'unconfirmed',
  CONFIRM = 'confirm'
}

export enum SalesLeadLabel {
  ALL = 'รายการทั้งหมด',
  UNCONFIRMED = 'ปิดการขายไม่สำเร็จ',
  CONFIRM = 'ปิดการขายสำเร็จ'
}

export enum SalesLeadStatus {
  BOUGHT = 'bought',
  BOUGHT_OTHER_CHANNEL = 'bought_other_channel',
  WAIT_TO_DECIDE = 'wait_to_decide',
  ASK_QUESTION = 'ask_question',
  LINE_MENU = 'line_menu',
  BLOCKED = 'blocked'
}

export enum SalesLeadStatusLabel {
  BOUGHT = 'ซื้อแล้ว',
  BOUGHT_OTHER_CHANNEL = 'ซื้อแล้วอีกช่องทาง',
  WAIT_TO_DECIDE = 'รอตัดสินใจ',
  ASK_QUESTION = 'ถามคำถาม',
  LINE_MENU = 'กดแถบไลน์',
  BLOCKED = 'โดนปิดการติดต่อ'
}

export enum SalePriceType {
  RETAIL = 'retail',
  WHOLESALE = 'wholesale'
}

export enum SalePriceTypeLabel {
  RETAIL = 'ขายปลีก',
  WHOLESALE = 'ขายส่ง'
}

export enum SaleContactChannel {
  FACEBOOK = 'facebook',
  LINE = 'line',
  LINE1 = 'line1',
  LINE2 = 'line2',
  LINE3 = 'line3',
  LINE4 = 'line4',
  LINE5 = 'line5',
  LINE6 = 'line6',
  LINE7 = 'line7',
  LINE8 = 'line8',
  LINE9 = 'line9',
  LINE10 = 'line10',
  LINE11 = 'line11',
  LINE12 = 'line12',
  LINE13 = 'line13',
  LINE14 = 'line14',
  LINE15 = 'line15',
  LINE16 = 'line16',
  LINE17 = 'line17',
  LINE18 = 'line18',

  TIKTOK = 'tiktok',
  // ZWIZ = 'zwiz',
  INSTAGRAM = 'instagram',
  NOC_NOC = 'noc_noc',
  WEBSITE = 'website',
  // JD_CENTRAL = 'jd_central',
  // ICON_SIAM = 'iconsiam',
  // BZBS = 'bzbs',
  BOOTH = 'booth'
}

export enum SaleContactChannelLabel {
  FACEBOOK = 'Facebook',
  LINE = 'Line',
  LINE1 = 'LineShop1',
  LINE2 = 'LineShop2',
  LINE3 = 'LineShop3',
  LINE4 = 'LineShop4',
  LINE5 = 'LineShop5',
  LINE6 = 'LineShop6',
  LINE7 = 'LineShop7',
  LINE8 = 'LineShop8',
  LINE9 = 'LineShop9',
  LINE10 = 'LineShop10',
  LINE11 = 'LineShop11',
  LINE12 = 'LineShop12',
  LINE13 = 'LineShop13',
  LINE14 = 'LineShop14',
  LINE15 = 'LineShop15',
  LINE16 = 'LineShop16',
  LINE17 = 'LineShop17',
  LINE18 = 'LineShop18',

  TIKTOK = 'Tiktok',
  // ZWIZ = 'Zwiz',
  INSTAGRAM = 'Instagram',
  NOC_NOC = 'NocNoc',
  WEBSITE = 'Website',
  // JD_CENTRAL = 'JD Central',
  // ICON_SIAM = 'Iconsiam',
  // BZBS = 'BZBS',
  BOOTH = 'Booth'
}

export enum SaleRefFromType {
  WALK_IN = 'walk_in',
  FB_ADS = 'fb_ads',
  IG_ADS = 'ig_ads',
  FB_PAGE = 'fb_page',
  TIKTOK = 'tiktok',
  SHOPEE = 'shopee',
  LAZADA = 'lazada',
  GOOGLE = 'google',
  ICONSIAM = 'iconsiam',
  INDEX_LIVING_MALL = 'index_living_mall',
  WEBSITE = 'website',
  JD_CENTRAL = 'jd_central',
  NOC_NOC = 'noc_noc',
  BZBS = 'bzbs',
  CENTRAL_WORLD = 'central_world',
  RECOMMEND = 'recommend',
  READED = 'readed',
  UNREAD = 'unread',
  OLD_CUSTOMER = 'old_customer'
}

export enum SaleRefFromLabel {
  WALK_IN = 'หน้าร้าน',
  FB_ADS = 'Facebook Ads',
  IG_ADS = 'Instagram Ads',
  FB_PAGE = 'Facebook Page',
  TIKTOK = 'Tiktok',
  SHOPEE = 'Shopee',
  LAZADA = 'Lazada',
  GOOGLE = 'Google',
  ICONSIAM = 'Iconsiam',
  INDEX_LIVING_MALL = 'Index Living Mall',
  WEBSITE = 'Website',
  JD_CENTRAL = 'JD Central',
  NOC_NOC = 'Noc Noc',
  BZBS = 'BZBS',
  CENTRAL_WORLD = 'central_world',
  RECOMMEND = 'บอกต่อ',
  READED = 'อ่านแล้วไม่ตอบ',
  UNREAD = 'ไม่อ่าน',
  OLD_CUSTOMER = 'ลูกค้าเก่า'
}

export enum PickingListStatus {
  ALL = 'all',
  UNPICK = 'unpick', //deprecated
  PREPICK = 'prepick',
  PREPARED = 'prepared',
  PREPARED_CONFIRMED = 'prepared_confirmed',
  PICKED = 'picked',
  CANCELLED = 'cancelled'
}

export enum PickingListStatusLabel {
  UNPICK = 'ยังไม่ตรวจสอบ',
  PREPICK = 'วางแผนหยิบสินค้า',
  PREPARED = 'จัดเตรียมสินค้าเรียบร้อย',
  PREPARED_CONFIRMED = 'เช็คงานสำเร็จ',
  PICKED = 'หยิบสินค้าสำเร็จ',
  CANCELLED = 'ยกเลิก'
}

/* ------------------------------- OrderReturn ------------------------------ */
export enum OrderReturnListStatus {
  ALL = 'all',
  OPEN = 'open',
  // CONFIRM_RECEIVED = 'confirm_received',
  IN_PROGRESS = 'in_progress',
  DONE_AND_CANCELLED = 'done_and_cancelled',
  DONE = 'done',
  CANCELLED = 'cancelled'
}

export enum OrderReturnStatusLabel {
  ALL = 'all',
  OPEN = 'ยังไม่ดำเนินการ',
  // CONFIRM_RECEIVED = 'ยืนยันได้รับหลักฐานเรียบร้อย',
  IN_PROGRESS = 'กำลังดำเนินการ',
  DONE_AND_CANCELLED = 'ประวัติรับสินค้าทั้งหมด',
  DONE = 'สำเร็จ',
  CANCELLED = 'ยกเลิกใบคืนสินค้าสำเร็จ'
}

export enum OrderReturnEvidenceStatus {
  OPEN = 'open',
  CREATED_EVIDENCE = 'created_evidence',
  CONFIRM_RECEIVED = 'confirm_received'
}

export enum OrderReturnEvidenceStatusLabel {
  OPEN = 'ยังไม่ดำเนินการ',
  CREATED_EVIDENCE = 'สร้างเอกสารรับสินค้าคืน',
  CONFIRM_RECEIVED = 'รับคืนสินค้าสำเร็จ'
}

export enum OrderReturnItemStatus {
  OPEN = 'open',
  DONE = 'done'
}

export enum OrderReturnItemStatusLabel {
  OPEN = 'ยังไม่ดำเนินการ',
  DONE = 'สำเร็จ'
}

export enum OrderReturnActions {
  NORMAL_DEPOSIT = 'normal_deposit',
  OUTLET_DEPOSIT = 'outlet_deposit',
  DEFECT_PRODUCT = 'defect_product',
  TRANSFER_PRODUCTION = 'transfer_production'
}

export enum OrderReturnActionsLabel {
  NORMAL_DEPOSIT = 'คินสินค้าปกติ',
  TRANSFER_PRODUCTION = 'ส่งสินค้าซ่อม',
  DEFECT_PRODUCT = 'สินค้าชำรุด',
  OUTLET_DEPOSIT = 'สินค้ามีตำหนิขายได้ (outlet)'
}

export enum OrderReturnCancelReason {
  CHANGE_ADDRESS = 'change_address',
  CHANGE_ITEMS = 'change_items',
  CHANGE_OF_MIND = 'change_of_mind',
  CHEAPER_ALTERNATIVE = 'cheaper_alternative',
  DUPLICATE = 'duplicate',
  SLOW_SHIPPING = 'slow_shipping',
  UNKNOWN = 'unknown',
  UNRESPONSIVE_SELLER = 'unresponsive_seller',
  OTHERS = 'others'

  // MIGRATE_CAUSE = 'เป็น order ที่เซิทในระบบ shopee ไม่เจอเดาว่าเกิดจากการ migrate ระบบเพี้ยน'
}

export enum OrderReturnCancelReasonLabel {
  CHANGE_ADDRESS = 'ต้องการเปลี่ยนข้อมูลการจัดส่ง',
  CHANGE_ITEMS = 'ต้องการเปลี่ยนไปซื้อสินค้าอื่นแทน',
  CHANGE_OF_MIND = 'เปลี่ยนใจ',
  CHEAPER_ALTERNATIVE = 'เจอตัวเลือกอื่นที่ถูกกว่า',
  DUPLICATE = 'คำสั่งซื้อซ้ำ',
  SLOW_SHIPPING = 'รอการจัดส่งนานเกินไป',
  UNKNOWN = 'unknown',
  UNRESPONSIVE_SELLER = 'ระยะเวลาในการดำเนินการนานเกินไป',
  OTHERS = 'อื่นๆ'
}

/* -------------------------------- Follow Up ------------------------------- */
export enum OrderWaitReturnFollowUpStatus {
  PENDING = 'pending',
  LOST_AND_REQUEST_COMPENSATION = 'lost_and_request_compensation',
  LOST_AND_COMPENSATED = 'lost_and_compensated',
  LOST_BUT_REJECTED = 'lost_but_rejected'
}

export enum OrderWaitReturnFollowUpStatusLabel {
  PENDING = 'ติดตามแล้ว',
  LOST_AND_REQUEST_COMPENSATION = 'ของหายตามเรื่องคืนเงิน',
  LOST_AND_COMPENSATED = 'ของหายได้เงินชดเชยแล้ว',
  LOST_BUT_REJECTED = 'ของหาย'
}

/* ------------------------------ Notification ------------------------------ */
export enum NotificationFilterStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in_progress'
}

export enum NotificationFilterStatusLabel {
  OPEN = 'ยังไม่ดำเนินการ',
  IN_PROGRESS = 'กำลังดำเนินการ'
}

export enum NotificationUrgency {
  HIGH_URGENCY = 'high_urgency'
}

export enum NotificationUrgencyLabel {
  HIGH_URGENCY = 'เร่งด่วน'
}

export enum NotificationActionType {
  ORDER_ISSUE_ADD = 'order_issue_add',
  ORDER_ISSUE_REPLY = 'order_issue_reply',
  PREORDER_COMMENT_ADD = 'preorder_comment_add',
  PREORDER_COMMENT_REPLY = 'preorder_comment_reply'
}

export enum NotificationActionLabel {
  ORDER_ISSUE_ADD = 'เพิ่มปัญหาที่พบ',
  ORDER_ISSUE_REPLY = 'ตอบกลับปัญหาที่พบ',
  PREORDER_COMMENT_ADD = 'เพิ่มการสนทนา',
  PREORDER_COMMENT_REPLY = 'ตอบกลับการสนทนา'
}

export enum NotificationEvent {
  CREATE = 'create',
  UPDATE = 'update',
  REPLY = 'reply',
  ACTION_CHANGE_STATUS = 'action_change_status'
}

export enum NotificationType {
  TICKET = 'ticket'
}

export enum DateLabel {
  TODAY = 'วันนี้',
  YESTERDAY = 'เมื่อวาน'
}

export enum NotificationFilterReadState {
  ALL = 'all',
  UNREAD = 'unread',
  READ = 'read'
}

export enum NotificationFilterReadStateLabel {
  ALL = 'ทั้งหมด',
  UNREAD = 'ยังไม่อ่าน',
  READ = 'อ่านแล้ว'
}

export const TicketInProgressState = [
  TicketStatus.SUBMIT_TICKET,
  TicketStatus.WAIT_FOR_APPROVE,
  TicketStatus.WAIT_FOR_APPROVE,
  TicketStatus.WAIT_FOR_INFORM,
  TicketStatus.WAIT_FOR_CUSTOMER_DECIDE,
  TicketStatus.NEGOTIATE,
  TicketStatus.APPEAL,
  TicketStatus.APPROVED,
  TicketStatus.PICKED_TO_SEND_NEW,
  TicketStatus.CREATE_INCOMPLETE_DELIVER_ORDER,
  TicketStatus.CREATE_RETRIEVE_ORDER,
  TicketStatus.RECEIVED_BACK,
  TicketStatus.CREATE_CLAIM_ORDER,
  TicketStatus.BOARDED_NEW,
  TicketStatus.DISAPPROVED,
  TicketStatus.DELIVERED,
  TicketEngineerAction.PICKED_TO_CLAIM,
  TicketEngineerAction.PICKED_TO_REPAIR,
  TicketEngineerAction.PICKED_TO_SERVICE
]

export const TicketCancelledState = [TicketStatus.KILLSWITCH, TicketStatus.CANCELLED, TicketStatus.ACCEPT]

export const TicketDoneState = [TicketStatus.DONE]

// hardcode from above better later
export const TicketStatusFilter = [
  TicketStatus.SUBMIT_TICKET,
  TicketStatus.APPEAL,
  TicketStatus.APPROVED,
  TicketStatus.PICKED_TO_SEND_NEW,
  TicketStatus.CREATE_INCOMPLETE_DELIVER_ORDER,
  TicketStatus.CREATE_RETRIEVE_ORDER,
  TicketStatus.RECEIVED_BACK,
  TicketStatus.CREATE_CLAIM_ORDER,
  TicketStatus.BOARDED_NEW,
  TicketStatus.DISAPPROVED,
  TicketStatus.DELIVERED,
  TicketEngineerAction.PICKED_TO_CLAIM,
  TicketEngineerAction.PICKED_TO_REPAIR,
  TicketEngineerAction.PICKED_TO_SERVICE,
  TicketStatus.KILLSWITCH,
  TicketStatus.CANCELLED,
  TicketStatus.ACCEPT,
  TicketStatus.DONE
]

export const colorOptions = [
  'default',
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple'
]

export enum DepartmentType {
  ACCOUNTING = 'accounting',
  FINANCE = 'finance',
  MARKETING = 'marketing',
  SALES = 'sales',
  HUMAN_RESOURCES = 'human_resources',
  ENGINEERING = 'engineering',
  DEVELOPMENT = 'development',
  COMMUNICATIONS = 'communications',
  INFORMATION_TECHNOLOGY = 'information_technology',
  CUSTOMER_SERVICE = 'customer_service',
  CUSTOMER_SERVICE_MANAGER = 'customer_service_manager',
  PRODUCTION = 'production',
  RESEARCH_AND_DEVELOPMENT = 'research_and_development',
  PROCUREMENT = 'procurement',
  QUALITY_CONTROL = 'quality_control',
  MANAGEMENT = 'management'
}

export enum DepartmentTypeLabel {
  ACCOUNTING = 'ฝ่ายบัญชี',
  FINANCE = 'ฝ่ายการเงิน',
  MARKETING = 'ฝ่ายการตลาด',
  SALES = 'ฝ่ายขาย',
  HUMAN_RESOURCES = 'ฝ่ายบุคคล',
  ENGINEERING = 'ฝ่ายวิศวกรรม',
  DEVELOPMENT = 'ฝ่ายพัฒนาระบบ',
  COMMUNICATIONS = 'ฝ่ายสื่อสาร',
  INFORMATION_TECHNOLOGY = 'ฝ่ายเทคโนโลยีสารสนเทศ',
  CUSTOMER_SERVICE = 'ฝ่ายบริการลูกค้า',
  CUSTOMER_SERVICE_MANAGER = 'ผู้จัดการฝ่ายบริการลูกค้า',
  PRODUCTION = 'ฝ่ายการผลิต',
  RESEARCH_AND_DEVELOPMENT = 'ฝ่ายวิจัยและพัฒนา',
  PROCUREMENT = 'ฝ่ายจัดซื้อ',
  QUALITY_CONTROL = 'ฝ่ายควบคุมคุณภาพ',
  MANAGEMENT = 'ฝ่ายบริหารจัดการ'
}

export enum TypeComment {
  MAIN = 'main',
  SUB = 'sub',
  COMMENT = 'comment'
}

export enum TypeQRCode {
  NORMAL = 'normal',
  LOT = 'lot'
}

export enum DeliveryType {
  DELIVER = 'deliver',
  IN_HOUSE_DELIVER = 'in-house-deliver',
  PICKUP = 'pickup',
  PICKUP_DONE = 'pickup-done',
  EXPRESS = 'express'
}

export enum DeliveryTypeLabel {
  DELIVER = 'ขนส่งภายนอก',
  IN_HOUSE_DELIVER = 'ขนส่งภายใน',
  PICKUP = 'มารับเอง',
  PICKUP_DONE = 'มารับเองสำเร็จ',
  EXPRESS = 'ขนส่งเฉพาะกิจ'
}

export enum SupplierType {
  TRADER = 'trader',
  MANUFACTURER = 'manufacturer'
}

export enum SupplierTypeLabel {
  TRADER = 'ผู้ค้า',
  MANUFACTURER = 'ผู้ผลิต'
}

/* ------------------------------- Booth Event ------------------------------ */
export enum BoothEventListTab {
  ALL = 'all'
}

export enum BoothEventListTabLabel {
  ALL = 'ทั้งหมด'
}

export enum BoothEventColumn {
  BOOTH_EVENT_ID = 'booth_event_id',
  EVENT_NAME = 'event_name',
  NOTE = 'note',
  ACTIVE_FROM = 'active_from',
  AREAS = 'areas',
  COST_PER_SQM_PER_DAY = 'cost_per_sqm_per_day',
  COSTPERDAY = 'costperday',
  WHOLE_AMOUNT_PER_SQM_PER_DAY = 'whole_amount_per_sqm_per_day',
  WHOLE_AMOUNT = 'whole_amount',
  AVG_ORIGINAL_WHOLE_AMOUNT_PER_DAY = 'avg_original_whole_amount_per_day',
  COST_PER_AMOUNT_PERCENTAGE = 'cost_per_amount_percentage',
  AVG_AMOUNT_PER_DAY7_DAYS_AGO = 'avg_amount_per_day7_days_ago'
}

export enum BoothEventColumnLabel {
  BOOTH_EVENT_ID = 'รหัสงานบูธ',
  EVENT_NAME = 'ชื่องานบูธ',
  NOTE = 'หมายเหตุ',
  ACTIVE_FROM = 'ช่วงเวลา',
  AREAS = 'ขนาด (ตรม.)',
  COST_PER_SQM_PER_DAY = 'ค่าที่/ตรม./วัน',
  COSTPERDAY = 'ค่าที่/วัน',
  WHOLE_AMOUNT_PER_SQM_PER_DAY = 'ยอดขายรวม/ตรม./วัน',
  WHOLE_AMOUNT = 'ยอดขายรวม',
  AVG_ORIGINAL_WHOLE_AMOUNT_PER_DAY = 'ยอดขายรวมเฉลี่ย/วัน',
  COST_PER_AMOUNT_PERCENTAGE = '% ค่าที่/ยอดขาย',
  AVG_AMOUNT_PER_DAY7_DAYS_AGO = 'ยอดขายเฉลี่ย/วัน (7วันย้อนหลัง)'
}

/* -------------------------- Purchase Requisition -------------------------- */

// TODO: should order follows discussed flow
export enum PurchaseRequisitionTab {
  ALL = 'all',
  OPEN = 'open',
  REQUEST_SUPER_APPROVAL = 'request_super_approval',
  REQUEST_PURCHASE_CONFIRM = 'request_purchase_confirm',
  REJECTED = 'rejected',
  RETURNED = 'returned',
  APPROVED = 'approved'
}

export enum PurchaseRequisitionTabLabel {
  ALL = 'รายการทั้งหมด',
  OPEN = 'รายการขอซื้อที่ถูกสร้าง',
  REQUEST_SUPER_APPROVAL = 'รายการขอซื้อที่รออนุมัติ',
  REQUEST_PURCHASE_CONFIRM = 'รอแผนกจัดซื้อสร้างใบ PO',
  REJECTED = 'รายการขอซื้อที่ไม่ผ่าน',
  RETURNED = 'รายการขอซื้อที่ส่งกลับไปแก้ไข',
  APPROVED = 'รายการขอซื้อที่อนุมัติแล้ว'
}

/* ----------------------------- Purchase Order ----------------------------- */

// TODO: should order follows discussed flow
export enum PurchaseOrderTab {
  // ALL = 'all',
  // REQUEST_PURCHASE_CONFIRM = 'request_purchase_confirm',
  // OPEN = 'open',
  // REQUEST_SUPER_APPROVAL = 'request_super_approval',
  // // APPROVED = 'approved',
  // REJECTED = 'rejected',
  // RETURNED = 'returned'
  // RECEIVED_INCORRECT = 'received_incorrect',
  // RECEIVED_CORRECT = 'received_correct'

  // more availabe tabs
  // PAID = 'paid',
  ALL = 'all',
  WAIT_FOR_APPROVE = 'wait_for_approve',
  NOT_APPROVED = 'not_approved',
  RETURN_TO_EDIT = 'return_to_edit',
  APPROVED = 'approved'
}

export enum PurchaseOrderCreate {
  PR_PRODUCT_LIST = 'pr_product_list',
  PR_PRODUCT_SELECTED = 'pr_product_selected'
}

export enum PurchaseOrderCreateLabel {
  PR_PRODUCT_LIST = 'วัตถุดิบตามรายการขอซื้อ',
  PR_PRODUCT_SELECTED = 'วัตถุดิบที่เลือกแล้ว'
}

export enum PurchaseOrderTabLabel {
  // ALL = 'รายการทั้งหมด',
  // REQUEST_PURCHASE_CONFIRM = 'รอสร้างใบสั่งซื้อ',
  // OPEN = 'สร้างใบสั่งซื้อ',
  // REQUEST_SUPER_APPROVAL = 'รอ หน.{Dname} ยืนยันการจัดสั่งซื้อ',
  // // APPROVED = 'รอจ่ายเงิน',
  // REJECTED = 'ถูกปฏิเสธ',
  // RETURNED = 'ส่งกลับแก้ไข'

  // RECEIVED_INCORRECT = 'received_incorrect',
  // RECEIVED_CORRECT = 'received_correct',
  // more availabe tabs
  // PAID = 'paid',
  ALL = 'รายการทั้งหมด',
  WAIT_FOR_APPROVE = 'รายการจัดซื้อที่รออนุมัติ',
  NOT_APPROVED = 'รายการจัดซื้อที่ไม่ผ่าน',
  RETURN_TO_EDIT = 'รายการจัดซื้อที่ส่งกลับไปแก้ไข',
  APPROVED = 'รายการจัดซื้อที่อนุมัติแล้ว'
}

/* ----------------------------- Purchase order Account ----------------------------- */
export enum PurchaseOrderAccountTab {
  APPROVED = 'approved',
  PAID = 'paid'
}

export enum PurchaseOrderAccountTabLabel {
  APPROVED = 'รอจ่ายเงิน',
  PAID = 'จ่ายเงินแล้ว'
}

/* ----------------------------- Purchase order Warehouse ----------------------------- */
export enum PurchaseOrderWarehouseTab {
  PAID = 'paid',
  PURCHASED = 'purchased',
  RECEIVED = 'received',
  DOCS_MISSING = 'documents_missing'
  // RECEIVED_CORRECT = 'received_correct',
  // RECEIVED_INCORRECT = 'received_incorrect'
}

export enum PurchaseOrderWarehouseTabLabel {
  PAID = 'รอสั่งซื้อ',
  PURCHASED = 'สั่งซื้อแล้ว',
  RECEIVED = 'ได้รับแล้ว',
  DOCS_MISSING = 'เอกสารยังไม่ครบ'
  // RECEIVED_CORRECT = 'ได้รับครบถ้วน',
  // RECEIVED_INCORRECT = 'ได้รับไม่ครบ'
}

/* ----------------------- Purchase Requisition Status ---------------------- */
export enum PurchaseRequisitionStatus {
  OPEN = 'open',
  REQUEST_SUPER_APPROVAL = 'request_super_approval',
  REQUEST_PURCHASE_CONFIRM = 'request_purchase_confirm',
  WAIT_FOR_PO_CONFIRM = 'wait_for_po_confirm',

  APPROVED = 'approved',
  RETURNED = 'returned',
  REJECTED = 'rejected'
}

// OPEN --> REQUEST_SUPER_APPROVAL --> REQUEST_PURCHASE_CONFIRM --> APPROVED / REJECTED
export enum PurchaseRequisitionStatusLabel {
  OPEN = 'รออนุมัติ',
  REQUEST_SUPER_APPROVAL = 'รออนุมัติ',
  REQUEST_PURCHASE_CONFIRM = 'รอแผนกจัดซื้อสร้างใบ PO',
  WAIT_FOR_PO_CONFIRM = 'รอ PO อนุมัติ',
  APPROVED = 'อนุมัติแล้ว', // cont. to PO flow
  REJECTED = 'ไม่ผ่าน',
  RETURNED = 'ถูกส่งกลับไปแก้ไข',
  CANCEL = 'ยกเลิกการขอซื้อ',
  COMPLETED = 'เสร็จสิ้น'
}

/* ------------------- Purchase Requisition Payment Status ------------------ */

export enum PurchaseRequisitionPaymentStatus {
  PARTIAL = 'partial',
  PAID = 'paid'
}

export enum PurchaseRequisitionPaymentStatusLabel {
  PARTIAL = 'ชำระบางส่วน',
  PAID = 'ชำระเงินแล้ว'
}

/* --------------------------------- Purchase Order Status --------------------------------- */
/** Deprecated */
export enum PurchaseOrderStatus {
  OPEN = 'open',
  REQUEST_SUPER_APPROVAL = 'request_super_approval',
  APPROVED = 'approved',
  PAID = 'paid',
  PURCHASED = 'purchased',
  RECEIVED_CORRECT = 'received_correct',
  RECEIVED_INCORRECT = 'received_incorrect',
  RETURNED = 'returned',
  REJECTED = 'rejected'
}

/** Deprecated */
export enum PurchaseOrderStatusLabel {
  OPEN = 'สร้างใบสั่งซื้อ',
  REQUEST_SUPER_APPROVAL = 'รอ หน.{Dname} ยืนยันการสั่งซื้อ',
  APPROVED = 'รอจ่ายเงิน',
  PAID = 'จ่ายเงินแล้ว',
  PURCHASED = 'สั่งซื้อแล้ว',
  RECEIVED_CORRECT = 'ได้รับสินค้าครบ',
  RECEIVED_INCORRECT = 'ได้รับสินค้าไม่ครบ',
  RETURNED = 'ส่งกลับแก้ไข',
  REJECTED = 'ถูกปฏิเสธ'
}

/** Deprecated */
export enum PurchaseOrderAttachmentsLabel {
  purchaseOrderInvoiceAttachments = 'ใบกำกับภาษี',
  purchaseOrderDeliveryNoteAttachments = 'ใบส่งของ',
  purchaseOrderReceiptAttachments = 'บิลเงินสด'
}

/* ------------------------------ Purchase Order Payment Status ----------------------------- */
/** Deprecated */
export enum PurchaseOrderPaymentStatus {
  UNPAID = 'unpaid',
  PARTIAL = 'partial',
  CREDIT = 'credit',
  PAID = 'paid',
  CANCELLED = 'cancelled',
  WAIT_REFUND = 'wait_refund',
  REFUNDED = 'refunded'
}

/** Deprecated */
export enum PurchaseOrderPaymentStatusLabel {
  UNPAID = 'ยังไม่ชำระ',
  PARTIAL = 'ชำระบางส่วน',
  CREDIT = 'ชำระแบบเงินเชื่อ',
  PAID = 'ชำระเงินแล้ว',
  CANCELLED = 'ยกเลิกการชำระเงิน',
  WAIT_REFUND = 'รอรับเงินคืน',
  REFUNDED = 'ได้รับเงินคืนแล้ว'
}

/** Deprecated */
export const PurchaseOrderStatusHiding = [PurchaseOrderStatus.REJECTED] // PurchaseOrderStatus.APPROVED,

/* -------------------------- Purchase Order Method ------------------------- */
/** Deprecated */
export enum PurchaseOrderPaymentMethod {
  BANK_TRANSFER = 'bank_transfer',
  CASH = 'cash',
  PAY_LATER = 'pay_later'
}

/** Deprecated */
export enum PurchaseOrderPaymentMethodLabel {
  BANK_TRANSFER = 'โอนผ่านธนาคาร',
  CASH = 'เงินสด',
  PAY_LATER = 'ชำระภายหลัง'
}

export enum CurrencySymbolValue {
  BAHT = 'baht',
  YEN_YUAN = 'yen_yuan',
  DOLLAR = 'dollar'
}

export enum CurrencySymbolLabel {
  BAHT = '฿',
  YEN_YUAN = '¥',
  DOLLAR = '$'
}

export enum PurchaseOrderApprovalStatus {
  WAIT_FOR_APPOVAL = 'wait_for_approval',
  APPROVED = 'approved',
  DISAPPROVED = 'disapproved'
}

export enum PurchaseOrderApprovalLabel {
  WAIT_FOR_APPOVAL = 'รออนุมัติ',
  APPROVED = 'อนุมัติแล้ว',
  DISAPPROVED = 'ไม่อนุมัติ'
}

export enum AccountType {
  SALES_ACCOUNT = 'sales_account',
  PAY_ACCOUNT = 'pay_account',
  SUPPLIER_ACCOUNT = 'supplier_account'
}

export enum ActionStatus {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done'
}

export enum Activity {
  /* ------------------------- production_order action ------------------------ */
  ASSIGN_PRODUCTION_JOB = 'assign_production_job',
  START_PRODUCTION_JOB = 'start_production_job',
  FINISH_PRODUCTION_JOB = 'finish_production_job',
  DEFECT_PRODUCT = 'defect_product',
  WAIT_FOR_QC_JOB = 'wait_for_qc_job',
  CLOSE_PRODUCTION_JOB = 'close_production_job',
  ASSEMBLE_PRODUCT_JOB = 'assemble_product_job',
  FIXABLE_PRODUCTION_JOB = 'fixable_production_job',

  /* ---------------------- transfer_in_inventory action ---------------------- */
  ASSIGN_FROM_PRODUCTION_JOB = 'assign_from_production_job',
  IMPORT_TO_INVENTORY_JOB = 'import_to_inventory_job',

  /* ----------------------- board_customer_order action ---------------------- */
  SCAN_FOR_DELIVERY_JOB = 'scan_for_delivery_job',

  /* -------------------------- made_to_order action ------------------------- */
  START_MAKING_TO_ORDER = 'start_making_to_order',
  CLOSE_MAKING_TO_ORDER = 'close_making_to_order',

  /* -------------------------- WIP action ------------------------- */
  PREPARED_COVER = 'prepared_cover',
  PREPARED_FRAME = 'prepared_frame',
  PREPARED_PLYWOOD = 'prepared_plywood',
  PREPARED_SPONGE = 'prepared_sponge'
}

export enum ActivityLabel {
  /* ------------------------- production_order action ------------------------ */
  ASSIGN_PRODUCTION_JOB = 'มอบหมายงานผลิต',
  START_PRODUCTION_JOB = 'เริ่มงาน',
  FINISH_PRODUCTION_JOB = 'จบงาน',
  WAIT_FOR_QC_JOB = 'สินค้ารอตรวจสอบคุณภาพ',
  DEFECT_PRODUCT = 'สินค้าชำรุด',
  CLOSE_PRODUCTION_JOB = 'ผ่าน QC',
  ASSEMBLE_PRODUCT_JOB = 'อยู่ระหว่างการประกอบสินค้า',
  FIXABLE_PRODUCTION_JOB = 'สินค้าซ่อมได้',

  /* ---------------------- transfer_in_inventory action ---------------------- */
  ASSIGN_FROM_PRODUCTION_JOB = 'แจ้งโอนสินค้า',
  IMPORT_TO_INVENTORY_JOB = 'นำเข้าคลังสินค้า',

  /* ------------------------ picking_list_order action ----------------------- */
  PREPARE_PRODUCT_JOB = 'จัดเตรียมสินค้า',
  PICK_PRODUCT_JOB = 'รับสินค้า',

  /* ----------------------- board_customer_order action ---------------------- */
  SCAN_FOR_DELIVERY_JOB = 'สแกนนำขึ้นรถ',

  /* -------------------------- made_to_order action ------------------------- */
  START_MAKING_TO_ORDER = 'มอบหมายงานผลิต',
  CLOSE_MAKING_TO_ORDER = 'ผ่านการตรวจสอบคุณภาพ'
}

export enum TypeJob {
  STOCK = 'stock',
  ONLINE = 'online',
  DEPARTMENT_STORE = 'department_store',
  FIX = 'fix'
}

export enum TypeJobLabel {
  STOCK = 'เติมสต๊อค',
  ONLINE = 'ออนไลน์',
  DEPARTMENT_STORE = 'ห้าง',
  FIX = 'ส่งซ่อม'
}

export enum TypeProduction {
  FG = 'finished_goods',
  WIP = 'work_in_process',
  RP = 'repair'
}

export enum TypeProductionLabel {
  FG = 'สินค้าสำเร็จ',
  WIP = 'วัตถุดิบ',
  RP = 'ซ่อม'
}

export enum ProductTrackingCancelReason {
  DEFECTIVE_PRODUCT = 'defective_product'
}

export enum ProductTrackingCancelReasonLabel {
  DEFECTIVE_PRODUCT = 'สินค้าชำรุด'
}

export enum StaffControlRoomMeta {
  ALL = 'all',
  HIGH_RESPONSIBLE_JOB = 'highResponsibleJob',
  LOW_RESPONSIBLE_JOB = 'lowResponsibleJob',
  NO_RESPONSIBLE_JOB = 'noResponsibleJob'
}

export enum StaffControlRoomMetaLabel {
  ALL = 'ทั้งหมด',
  HIGH_RESPONSIBLE_JOB = 'งานแน่น',
  LOW_RESPONSIBLE_JOB = 'งานน้อย',
  NO_RESPONSIBLE_JOB = 'ว่าง'
}

export enum ResponsibleJobStatus {
  HIGH_RESPONSIBLE_JOB = 'highResponsibleJob',
  LOW_RESPONSIBLE_JOB = 'lowResponsibleJob',
  NO_RESPONSIBLE_JOB = 'noResponsibleJob'
}

export enum ResponsibleJobStatusLabel {
  HIGH_RESPONSIBLE_JOB = 'งานแน่น',
  LOW_RESPONSIBLE_JOB = 'งานน้อย',
  NO_RESPONSIBLE_JOB = 'ว่าง'
}

export enum OrderBillingOption {
  NONE = 'none',
  DEFAULT = 'default',
  CUSTOM = 'custom'
}

export enum OrderBankAccTypeOption {
  NONE = 'none',
  DEFAULT = 'supplier',
  CUSTOM = 'custom'
}

export enum OrderTaxOption {
  NONE = 'none',
  EXCLUDE = 'exclude',
  INCLUDE = 'include'
}

export enum TransactionAction {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw'
}

export enum TotalExpenseType {
  COMMISSION = 'commission',
  SERVICE = 'service',
  TRANSACTION = 'transaction',
  SHIPPING = 'shipping',
  OTHER = 'others'
}

export enum BoothLocationType {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum BoothLocationTypeLabel {
  ONLINE = 'ออนไลน์',
  OFFLINE = 'ออฟไลน์'
}

export const OrderExcelSheetColumns = [
  { key: 'sourceCreatedTime', title: 'Order Created Time' },
  { key: 'sourceUpdatedTime', title: 'Order Updated Time' },
  {
    key: 'sourceRef',
    title: 'Source Ref',
    children: [
      { key: '_id', title: 'Source Ref ID' },
      { key: 'name', title: 'Source Ref Name' },
      { key: 'sourceType', title: 'Source Ref Type' }
    ]
  },
  { key: 'sourceStatus', title: 'Order Status' },
  { key: 'customerName', title: 'Customer Name' },
  { key: 'customerId', title: 'Customer ID' },

  {
    key: 'items',
    title: 'Order Items',
    children: [
      { key: 'productId', title: 'Product ID' },
      { key: 'productName', title: 'Product Name' },
      { key: 'productSku', title: 'Product SKU' },
      { key: 'productImage', title: 'Product Image' },
      { key: 'productPosition', title: 'Product Position' },
      { key: 'category.name', title: 'Product Category' },
      { key: 'quantity', title: 'Product Quantity' },
      { key: 'price', title: 'Product Price' },
      { key: 'discount', title: 'Product Discount' }
    ]
  },
  { key: 'source', title: 'Source' },
  {
    key: 'warehouse',
    title: 'Warehouse',
    children: [
      { key: 'name', title: 'Warehouse Name' },
      { key: 'description', title: 'Warehouse Description' }
    ]
  },
  {
    key: 'itemMeta',
    title: 'Item Meta',
    children: [
      { key: 'taxOption', title: 'Item Meta Tax Option' },
      { key: 'tax', title: 'Item Meta Tax' },
      { key: 'discount', title: 'Item Meta Discount' },
      { key: 'totalAmountBeforeTax', title: 'Item Meta Amount Before Tax' },
      { key: 'estShippingFeeBeforeTax', title: 'Item Meta Estimate Shipping Fee Before Tax' },
      { key: 'estShippingFeeAfterTax', title: 'Item Meta Estimate Shipping Fee After Tax' },
      { key: 'actualShippingFeeIncludeTax', title: 'Item Meta Actual Shipping Fee Include Tax' },
      { key: 'actualShippingFeeBeforeTax', title: 'Item Meta Actual Shipping Fee Before Tax' },
      { key: 'actualShippingFeeAfterTax', title: 'Item Meta Actual Shipping Fee After Tax' },
      { key: 'finalAmount', title: 'Item Meta Final Amount' },
      { key: 'note', title: 'Item Meta Note' }
    ]
  },
  {
    key: 'itemsNote',
    title: 'Item Note',
    children: [
      { key: 'parentSku', title: 'Item Note Parent SKU' },
      { key: 'productId', title: 'Item Note Product ID' },
      { key: 'productSku', title: 'Item Note Product SKU' },
      { key: 'productImage', title: 'Item Note Product Image' },
      { key: 'productName', title: 'Item Note Product Name' },
      { key: 'quantity', title: 'Item Note Quantity' },
      { key: 'status', title: 'Item Note Status' }
    ]
  },
  { key: 'deliveryDate', title: 'Delivery Date' },
  {
    key: 'deliveryCaution',
    title: 'Delivery Caution',
    children: [
      { key: 'isCaution', title: 'Delivery Caution' },
      { key: 'note', title: 'Delivery Caution Note' },
      { key: 'updatedAt', title: 'Delivery Updated Time' }
    ]
  },
  {
    key: 'recipientAddress',
    title: 'Recipient Address',
    children: [
      { key: 'name', title: 'Shipping Address Name' },
      { key: 'branch', title: 'Shipping Address Branch' },
      { key: 'email', title: 'Shipping Address Email' },
      { key: 'phone', title: 'Shipping Address Phone' },
      { key: 'address', title: 'Shipping Address' },
      { key: 'province', title: 'Shipping Address Province' },
      { key: 'district', title: 'Shipping Address District' },
      { key: 'country', title: 'Shipping Address Country' },
      { key: 'zipcode', title: 'Shipping Address Zip Code' }
    ]
  },
  {
    key: 'billingAddress',
    title: 'Billing Address',
    children: [
      { key: 'option', title: 'Billing Address Option' },
      { key: 'name', title: 'Billing Address Name' },
      { key: 'address', title: 'Billing Address' },
      { key: 'province', title: 'Billing Address Province' },
      { key: 'district', title: 'Billing Address District' },
      { key: 'country', title: 'Billing Address Country' },
      { key: 'zipcode', title: 'Billing Address Zip Code' },
      { key: 'contactId', title: 'Billing Address Contact Id' },
      { key: 'contactTitle', title: 'Billing Address Contact Name' }
    ]
  },
  { key: 'shippingLabel', title: 'Shipping Label' },
  { key: 'invoice', title: 'Invoice' },
  { key: 'invoiceNumber', title: 'Invoice Number' },
  { key: 'trackingNumber', title: 'Tracking Number' },
  { key: 'status', title: 'Order Status' },
  { key: 'prevStatus', title: 'Order Previous Status' },
  {
    key: 'statusMeta',
    title: 'Order Status Meta',
    children: [
      { key: 'prepickTime', title: 'Order Prepick Time' },
      { key: 'preparedTime', title: 'Order Prepared Time' },
      { key: 'pickedTime', title: 'Order Picked Time' },
      { key: 'printCount', title: 'Order Print Count' },
      { key: 'printedTime', title: 'Order Print Time' },
      { key: 'packedTime', title: 'Order Packed Time' },
      { key: 'readyToShipTime', title: 'Order Ready To Ship Time' },
      { key: 'waitForProductionTime', title: 'Order Wait For Production Time' },
      { key: 'boardedTime', title: 'Order Boarded Time' },
      { key: 'transportedTime', title: 'Order Transported Time' },
      { key: 'deliveredTime', title: 'Order Delivered Time' },
      { key: 'returnedTime', title: 'Order Returned Time' },
      { key: 'isPerfectReturn', title: 'Order Return Perfectly' },
      { key: 'cancelledTime', title: 'Order Cancelled Time' },
      { key: 'doubtedTime', title: 'Order Doubted Time' }
    ]
  },
  {
    key: 'createdBy',
    title: 'Order Created By',
    children: [
      { key: 'displayName', title: 'Order Created User Name' },
      { key: 'email', title: 'Order Created User Email' }
    ]
  },
  {
    key: 'workspace',
    title: 'Workspace',
    children: [
      { key: 'name', title: 'Workspace Name' },
      { key: 'email', title: 'Workspace Email' },
      { key: 'phone', title: 'Workspace Phone' },
      { key: 'fax', title: 'Workspace Fax' },
      { key: 'branchName', title: 'Workspace Branch Name' },
      { key: 'quantity', title: 'Item Note Quantity' },
      { key: 'status', title: 'Item Note Status' }
    ]
  },
  { key: 'note', title: 'Order Note' },
  { key: 'lotNo', title: 'LOT No' },
  { key: 'cancelDetail', title: 'Order Cancel Detail' },
  { key: 'cancelReason', title: 'Order Cancel Reason' },
  { key: 'isUrgentOrder', title: 'Urgent Order' },
  {
    key: 'salesLead',
    title: 'Sale Lead',
    children: [
      { key: 'salesName', title: 'Sales Name' },
      { key: 'customerName', title: 'Sales Customer Name' },
      { key: 'productSku', title: 'Item Note Product SKU' },
      { key: 'productImage', title: 'Item Note Product Image' },
      { key: 'productName', title: 'Item Note Product Name' },
      { key: 'quantity', title: 'Item Note Quantity' },
      { key: 'status', title: 'Item Note Status' }
    ]
  },
  {
    key: 'packages',
    title: 'Packages',
    children: [
      { key: 'statusMeta.pickedTime', title: 'Package Picked Time' },
      { key: 'statusMeta.printCount', title: 'Package Print Count' },
      { key: 'statusMeta.printedTime', title: 'Package Print Time' },
      { key: 'statusMeta.packedTime', title: 'Package Packed Time' },
      { key: 'statusMeta.waitForProductionTime', title: 'Package Wait For Production Time' },
      { key: 'statusMeta.boardedTime', title: 'Package Boarded Time' },
      { key: 'statusMeta.transportedTime', title: 'Package Transported Time' },
      { key: 'statusMeta.deliveredTime', title: 'Package Delivered Time' },
      { key: 'codAmount', title: 'Package COD Amount' },
      { key: 'reference', title: 'Package Reference' },
      { key: 'trackingNumber', title: 'Package Tracking Number' },
      { key: 'shippingLabel', title: 'Package Shipping Label' },
      { key: 'items.productName', title: 'Package Item Name' },
      { key: 'items.productSku', title: 'Package Item SKU' },
      { key: 'items.productImage', title: 'Package Item Image' },
      { key: 'items.quantity', title: 'Package Item Quantity' },
      { key: 'items.onePerPackage', title: 'Package Item One Per Package' },
      { key: 'items.productPrice', title: 'Package Item Price' }
    ]
  },
  //Accounting
  {
    key: 'merchantSubtotal',
    title: 'Merchant Subtotal'
  },
  {
    key: 'finalAmount',
    title: 'Final Amount'
  },
  {
    key: 'paidDate',
    title: 'Paid Date'
  },
  {
    key: 'commissionFee',
    title: 'Commission Fee'
  },
  {
    key: 'serviceFee',
    title: 'Service Fee'
  },
  {
    key: 'transactionFee',
    title: 'Payment Fee'
  },
  {
    key: 'estimatedShippingFee',
    title: 'Estimated Shipping Fee'
  },
  {
    key: 'actualShippingFee',
    title: 'Actual Shipping Fee'
  },
  {
    key: 'shippingFeeDiscounts',
    title: 'Shipping Fee Discounts'
  }
]

export enum AccountingStatisticType {
  COMMISSION_FEE = 'commissionFee',
  SERVICE_FEE = 'serviceFee',
  TRANSACTION_FEE = 'transactionFee',
  SHIPPING_FEE = 'shippingFee',
  SHIPPING_FEE_DIFFERENCE = 'shippingFeeDifference',
  PAID_AMOUNT = 'paidAmount',
  UNPAID_AMOUNT = 'unpaidAmount',
  PENDING_AMOUNT = 'pendingAmount',
  CANCELLED_AMOUNT = 'cancelledAmount',
  MERCHANT_PAID_AMOUNT = 'merchantPaidAmount',
  MERCHANT_UNPAID_AMOUNT = 'merchantUnpaidAmount',
  MERCHANT_PENDING_AMOUNT = 'merchantPendingAmount',
  MERCHANT_CANCELLED_AMOUNT = 'merchantCancelledAmount',
  TOTAL = 'total',
  COUNT = 'count',
  CANCEL_BEFORE_BOARDED_COUNT = 'cancelBeforeBoardCount',
  CANCEL_AFTER_BOARDED_COUNT = 'cancelAfterBoardCount',
  CANCEL_UNKNOWN_COUNT = 'cancelUnknownCount',
  BOARDED_FROM_FM_COUNT = 'boardedFromFMCount',
  NOT_BOARDED_FROM_FM_COUNT = 'notBoardedFromFMCount',
  RETURNED_COUNT = 'returnedCount',
  NOT_RETURNED_COUNT = 'notReturnedCount',
  CANCELLED_COUNT = 'cancelledCount',
  PAID_COUNT = 'paidCount',
  UNPAID_COUNT = 'unpaidCount',
  VERIFIED_COUNT = 'verifiedCount',
  ACTUAL_SHIPPING_FEE = 'actualShippingFee',
  PLATFORM_SHIPPING_REBATE = 'platformShippingRebate',
  SHIPPING_FEE_PAID_BY_BUYER = 'shippingFeePaidByBuyer',
  SELLER_OWN_FLEET_SHIPPING_FEE = 'sellerOwnFleetShippingFee',
  ADJUSTMENT_FEE = 'adjustmentFee',
  ADJUSTMENT_FEE_PLUS = 'adjustmentFeePlus',
  ADJUSTMENT_FEE_MINUS = 'adjustmentFeeMinus',
  MERCHANT_SUB_TOTAL = 'merchantSubtotal',
  TOTAL_FEE = 'totalFee',
  DISCOUNT_AMOUNT = 'discountAmount'
}

export enum TechnicalDataSheetAction {
  CREATE = 'create'
}

/* ===================== Production Plan (PMF) ===================== */

export enum ProductionPlanWorkType {
  ONLINE = 'ONLINE',
  PRODUCTION_PLAN = 'PRODUCTION_PLAN',
  REFILL_STOCK = 'REFILL_STOCK',
  URGENT = 'URGENT',
  EVENT = 'EVENT'
}

export enum ProductionPlanWorkTypeLabel {
  ONLINE = 'ออนไลน์',
  PRODUCTION_PLAN = 'แผนการผลิต (จะถูกรวมเข้าใน quota กลาง)',
  REFILL_STOCK = 'เติมสต็อก',
  URGENT = 'ด่วน',
  EVENT = 'เหตุการณ์อื่นๆ'
}

export enum StockProductUserRole {
  'ADMIN' = 'admin',
  'USER' = 'user'
}

// Define public paths that don't need authentication
export const publicPaths = new Set([PathMenu.ORDER_QR_CODE_PAYMENT_PREVIEW, PathMenu.ORDER_STATUS_UPDATE_V2])

// check if the path is public using regex
export const isPublicPath = (url: string) => {
  for (const path of publicPaths) {
    const regex = new RegExp(path.replace(/\[.*?\]/g, '.*'))
    if (regex.test(url)) {
      return true
    }
  }
  return false
}

export const FactoryInventoryAction = [
  // InventoryAction.TRANSFER_OUT_INVENTORY_ORDER,
  // InventoryAction.BATCH_ADD,
  // InventoryAction.BATCH_REDUCE,

  InventoryAction.STOCK_ADJUSTMENT,
  InventoryAction.STOCK_ADJUSTMENT_PICKING_LIST,
  InventoryAction.INSPECTION,
  InventoryAction.PRODUCTION_ORDER,
  InventoryAction.RAW_MATERIAL_WITHDRAW,
  InventoryAction.RAW_MATERIAL_DEPOSIT,
  InventoryAction.TRANSFER_IN_INVENTORY_ORDER,
  InventoryAction.TRANSFER_ORDER,
  InventoryAction.PRODUCT_ISSUE_REPORT,
  InventoryAction.PAID_CUSTOMER_ORDER,
  InventoryAction.UNBOARDED_CUSTOMER_ORDER,
  InventoryAction.BOARDED_CUSTOMER_ORDER,
  InventoryAction.PICKED_CUSTOMER_ORDER,
  InventoryAction.CANCELLED_CUSTOMER_ORDER,
  InventoryAction.RETURNED_CUSTOMER_ORDER,
  InventoryAction.EDITED_CUSTOMER_ORDER,
  InventoryAction.RESERVED,
  InventoryAction.PURCHASE_ORDER,
  InventoryAction.EXPIRED_RESERVATION,
  InventoryAction.CANCELLED_RESERVATION,
  InventoryAction.APPROVED_RESERVATION,
  InventoryAction.MATERIAL_CHANGE,
  InventoryAction.MATERIAL_CHANGE_ACTUAL,
  InventoryAction.MATERIAL_CHANGE_AVAILABLE,
  InventoryAction.AUTOMATIC_STOCK_CORRECTION,
  InventoryAction.BORROW_RETURN,
  InventoryAction.INSERT_WORKING_TIME,
  InventoryAction.FAKE_REFILL_STOCK
]

export enum SplitMTOOrderSelectionTab {
  ALL = 'all',
  SELECTED = 'selected'
}

export enum SplitMTOOrderSelectionTabLabel {
  ALL = 'คำสั่งซื้อทั้งหมด',
  SELECTED = 'คำสั่งซื้อที่เลือก'
}

export const BannedLabel =
  'https://storage.googleapis.com/market-futuremakers/shipping-label/unverified_shipping_label.pdf'
